
const FindTeamService = {

    getTeamLogo(region){
        const importAll = (r) => {
            //r.key() contient tous les éléments du repertoire en paramètre a l'appel (ici on garde que les jpg)
            //on ne garde que le chemin de l'image === au champion courant
            //on renvoie avec le .map(r) le chemin courant une fois les filtres passées
            return r.keys().filter(element => (element.replace('.png', '').replace('./', ''))).map(r);
        };
        let teamLogo;
        switch(region){
            case("LFL"):
                teamLogo = importAll(require.context(`../assets/img/UI/LFL_TEAM/`, true, /\.(png)$/));
                return teamLogo;
            case("LEC"):
                teamLogo = importAll(require.context(`../assets/img/UI/LEC_TEAM/`, true, /\.(png)$/));
                return teamLogo;
            case("LCS"):
                teamLogo = importAll(require.context(`../assets/img/UI/LCS_TEAM/`, true, /\.(png)$/));
                return teamLogo;
            case("LCK"):
                teamLogo = importAll(require.context(`../assets/img/UI/LCK_TEAM/`, true, /\.(png)$/));
                return teamLogo;
            case("LPL"):
                teamLogo = importAll(require.context(`../assets/img/UI/LPL_TEAM/`, true, /\.(png)$/));
                return teamLogo;
            default :
             return teamLogo;
        }      
    }
}
export default FindTeamService;