import QuestionService from "./QuestionService";
import {Question} from "../model/Question";
import SecureStorage from "react-secure-storage";

const storagePrefix = 'react_';

const storage = {
  cleanDaily() {
    const storedDateString = SecureStorage.getItem(`${storagePrefix}daily_date`);
    const storedDateTusmoString = SecureStorage.getItem(`${storagePrefix}daily_tusmo_date`);
    // @ts-ignore
    const storedDate = new Date(storedDateString);
    // @ts-ignore
    const storedTusmoDate = new Date(storedDateTusmoString);
    if ( (new Date().getDate() - storedDate.getDate()) >= 1 ) {
      SecureStorage.removeItem(`${storagePrefix}daily_questions`);
      SecureStorage.removeItem(`${storagePrefix}daily_final_responses`);
      SecureStorage.removeItem(`${storagePrefix}daily_date`);
    }
    if ( (new Date().getDate() - storedTusmoDate.getDate()) >= 1 ) {
      SecureStorage.removeItem(`${storagePrefix}daily_tusmo_date`);
      SecureStorage.removeItem(`${storagePrefix}daily_final_player`);
    }
  },
  getChamps() {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}champs`) as string);
  },
  getChampsToLowerCase() {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}champs`)?.toLocaleLowerCase() as string);
  },
  loadChamps: () => {
    const champs = QuestionService.getAllChampionsNames();
    window.localStorage.setItem(`${storagePrefix}champs`, JSON.stringify(champs));
  },
  isChampsLoaded: () => {
    return window.localStorage.getItem(`${storagePrefix}champs`) !== null;
  },
  clearChamps: () => {
    window.localStorage.removeItem(`${storagePrefix}champs`);
  },
  initDaily: (questions: Question[]) => {
    SecureStorage.setItem(`${storagePrefix}daily_questions`, JSON.stringify(questions));
    // Initialisation de la date
    SecureStorage.setItem(`${storagePrefix}daily_date`, new Date())
  },
  getDaily() {
    return JSON.parse(SecureStorage.getItem((`${storagePrefix}daily_questions`)) as string);
  },
  initDailyExists: () => {
    return SecureStorage.getItem(`${storagePrefix}daily_questions`) !== null;
  },
  setFinalResponse: (responses: any) => {
    SecureStorage.setItem(`${storagePrefix}daily_final_responses`, JSON.stringify(responses));
  },
  getFinalResponse() {
    return JSON.parse(SecureStorage.getItem(`${storagePrefix}daily_final_responses`) as string);
  },
  initDailyTusmo: () => {
    SecureStorage.setItem(`${storagePrefix}daily_tusmo_date`, new Date())
  },
  setFindPlayer: (responses: any) => {
    SecureStorage.setItem(`${storagePrefix}daily_final_player`, JSON.stringify(responses));
  },
  getFindPlayer() {
    return SecureStorage.getItem(`${storagePrefix}daily_final_player`);
  }
};

export default storage;