import React, { useState, createContext } from "react";
import ReactDOM from 'react-dom';
import {BrowserRouter, Routes, Route, Outlet} from "react-router-dom";
import './index.css';
import Home from './pages/Home';
import Daily from './pages/Daily';
import Gamemode from "./pages/Gamemode";
import storage from './service/Storage.ts';
import PannelChamp from './pages/PannelChampion/PannelChamp';
import FindPlayer from './pages/FindProPlayer/FindPlayer';
import Navbar from './navbar/Navbar';
import SecureStorage from "react-secure-storage";
import Footer from "./footer/Footer"

const ThemeContext = createContext();

function Layout() {
  const image = require('./assets/img/UI/gradienta.jpg');
  const imageBlueWall = require('./assets/img/UI/KcorpTheme/blueWall.png');

  const [state, setState] = useState({
    theme: 'light',
    themes: {
      light: {
        //Commun
        backgroundButton: 'bg-red-400',
        buttonHover:'hover:bg-red-600',
        borderColor: 'border-red-500',
        textColor: 'text-white',
        textColorTitle: 'text-white',
        background: `url(${image})`,
        background_pannel_gradiant:"from-red-500 via-indigo-900 to-transparen",
        //
        borderTop: "1px solid #f87171",
        //Footer
        button__footer__HoverbackgroundButton: 'hover:bg-[#ef4444]',
        button__footer__backgroundButton: 'bg-[#ef4444]',
        button__footer__textColor: 'text-[#ef4444]',
        //Daily
        hrBorderTopDaily: "1px solid #ffffff",
        backGroundColorRadiantQuestion: "from-[#5A0139] to-transparent",
        weight__emote: "w-5/6",
        bg__recap__question:"bg-[#260137]",
        bg__recap__stat:"bg-[#2a013d]",
        disable_selectButton:"bg-grey-600",
        hover__disable_selectButton:"bg-grey-900",
        //Pannel champion
        shadowColor: "shadow-[0_20px_20px_20px_rgba(248,113,113,0.3)]",
        background_pannel__stat__gradiant:"from-red-400 to-transparent",
        input__background_champion_pannel:"bg-[#510038]",
        input__focus__border: "focus:border-red-400",
        borderColor_champion_pannel: "border-purple-900",
        shadow__boxStats: "shadow-blue-200/50",
        hearth__color:"#F77171",
        return__backgroundButton: 'bg-red-400',
        returnToMenuOrRestart__backgroundButton: 'bg-red-400',
        hover__return__backgroundButton: 'hover:bg-red-500',
        hover__returnToMenuOrRestart__backgroundButton: 'hover:bg-red-600',
        popup__backgroundElemRules:"bg-red-400",
        background_champion_pannel:"bg-[#f87171]",
        button__pannel__backgroundButton: 'bg-red-400',
        hover__backgroundButton_pannel: 'hover:bg-red-600',
        //FindPlayer
        background_findPlayer__gradiant:"from-red-400 to-transparent",
        autocomplete__backgroung__stat__gradiant:"from-red-400 to-transparent",
        backGroundColorRadiantRecap: "from-[#5A0139] to-transparent",
        //PopUp
        backGroundColorPopUp: "bg-gradient-to-tl from-[#e15555cb] via-[#1f0739] to-[#e15555db]",
        popup__backgroundButton: 'bg-red-400',
        popup__hover__backgroundButton: 'hover:bg-red-600',
        //Navbar
        navbar_bottom_border:"#29b6f6",

      },
      kcorp: {
        //Commun
        backgroundButton: `url(${imageBlueWall})`,
        buttonHover:'hover:bg-yellow-600',
        borderColor: 'border-cyan-500',
        textColor: 'text-white',
        textColorTitle: 'text-[#01D1FF]',
        background: "linear-gradient(342deg, rgba(29,37,61,1) 0%, rgba(16,23,33,1) 50%, rgba(29,37,61,1) 100%)",
        background_pannel_gradiant:"from-[#01D1FF] via-cyan-800 to-[#1d253d]",
        //
        borderTop: "1px solid #01D1FF",
        //Footer
        button__footer__HoverbackgroundButton: 'hover:bg-[#04b7db]',
        button__footer__backgroundButton: 'bg-[#04b7db]',
        button__footer__textColor: 'text-[#04b7db]',
        //Daily
        hrBorderTopDaily: "1px solid #fffff",
        backGroundColorRadiantQuestion: "from-[#1d263d] to-transparent",
        weight__emote: "w-3/6",
        bg__recap__question:"bg-[#1e263d]",
        bg__recap__stat:"bg-[#29365c]",
        disable_selectButton:"bg-grey-600",
        hover__disable_selectButton:"bg-grey-900",
        //Pannel champion
        shadowColor: "shadow-[0_20px_20px_20px_rgba(1,209,255,0.3)]",
        background_pannel__stat__gradiant:"from-[#01D1FF] via-cyan-800 to-[#1d253d]",
        input__background_champion_pannel:"bg-[#1d253d]",
        input__focus__border: "focus:border-cyan-400",
        borderColor_champion_pannel: "border-[#1A6078]",
        shadow__boxStats: "shadow-[#01d1ff]",
        hearth__color:"#ffdf0f",
        return__backgroundButton: 'bg-cyan-400',
        hover__return__backgroundButton: 'hover:bg-cyan-500',
        returnToMenuOrRestart__backgroundButton: 'bg-[#01D1FF]',
        hover__returnToMenuOrRestart__backgroundButton: 'hover:bg-[#01D1FF]',
        popup__backgroundElemRules:"bg-[#01D1FF]",
        background_champion_pannel:"bg-[#01B7DB]",
        button__pannel__backgroundButton: 'bg-[#01D1FF]',
        hover__backgroundButton_pannel: 'hover:bg-[#04b7db]',
        //FindPlayer
        background_findPlayer__gradiant:"from-cyan-600 via-cyan-700 to-cyan-800",
        autocomplete__backgroung__stat__gradiant:"from-[#098DAE] via-cyan-800 to-[#098DAE]",
        backGroundColorRadiantRecap: "from-[#1d263d] to-transparent",
        //PopUp
        backGroundColorPopUp: "bg-gradient-to-tl from-[#1d253d] via-[#101721] to-[#1d253d]",
        popup__backgroundButton: 'bg-[#01D1FF]',
        popup__hover__backgroundButton: 'hover:bg-[#04b7db]',
        //Navbar
        navbar_bottom_border:"",
      },
    },
  });
let styleBackground;
      styleBackground = {
        "backgroundImage": state.themes[state.theme].background,
        "backgroundSize": 'cover',
        "minHeight": "100%",
        "backgroundPosition": "center center",
        "backgroundRepeat": "no-repeat",
        "min-height": "100vh"
      }
  return (
    <div style={styleBackground} className="min-h-full w-full">
      <ThemeContext.Provider value={[state, setState]}>
        <Navbar />
        <Outlet />
        <Footer className="" />
      </ThemeContext.Provider>
    </div>
  );
}

 // Chargement des champs en mémoire
 if (!storage.isChampsLoaded()) {
  storage.loadChamps();
}

storage.cleanDaily();

ReactDOM.render(
  <React.StrictMode>
    <div className="Container mx-auto">
      <div className="place-items-center">
        <BrowserRouter className=''>
            <Routes>
              <Route element={<Layout />}>
                <Route path="/" element={<Home />} />
                <Route path="daily" element={<Daily />} />
                <Route path="pannelChamp" element={<PannelChamp />} />
                <Route path="findPlayer" element={<FindPlayer />} />
                <Route path="gamemode" element={<Gamemode />} />
                <Route path="*" element={<Home />} />
              </Route>
            </Routes>
        </BrowserRouter>
      </div>
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);
export { ThemeContext };