import React, {useContext } from 'react';
import { ThemeContext } from '../index.js'
import "../css/popUp.css";

 
const Popup = props => {
const [state, setState] = useContext(ThemeContext);
  return (
    <div className="popup-box">
      <div className={`box ${state.themes[state.theme].backGroundColorPopUp}`}>
        {props.content}
        <button
            className={`button button--nina animate__animated animate__fadeIn
            w-full ${state.themes[state.theme].popup__backgroundButton} ${state.themes[state.theme].popup__hover__backgroundButton} relative block focus:outline-none text-center rounded text-base uppercase
           tracking-widest overflow-hidden text-white font-bold md:text-sm lg:text-base xl:text-xl`}
            data-text="Fermer"
            onClick={props.handleClose}
        >
                            <span className="align-middle">F</span>
                            <span className="align-middle">E</span>
                            <span className="align-middle">R</span>
                            <span className="align-middle">M</span>
                            <span className="align-middle">E</span>
                            <span className="align-middle">R</span>
        </button>
      </div>
    </div>
  );
};
 
export default Popup;