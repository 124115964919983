import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import React, {useState, useContext, useEffect} from 'react';
import { ThemeContext } from '../index.js'

export default function DragAndDropDiv(props) {
  const [state, setState] = useContext(ThemeContext);

  const [imageList, setImageList] = useState(props.question.image);
  const [number, setNumber] = useState(0);

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(imageList);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setImageList(tempData);
  };

  useEffect(() => {
    async function fetchData(){
        setImageList(props.question.image)
    }

    fetchData();
}, [number]);

  function handleClick() {
    props.onClick(imageList);
    setNumber(number=> number+1);
  }

  return (
    <div className="">
      <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable-1" direction="horizontal">
            {(provided) => (
              <div
                className="text-capitalize flex justify-center"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {imageList?.map((image, index) => (
                  <Draggable
                  key={image.id.toString()} draggableId={image.id.toString()} index={index} axis="x"
                  >
                    {(provided) => (
                      <div
                      className=""
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <img src={image.img} alt="image à drag" className="h-64"/>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
      </DragDropContext>
      <div className='grid gap-4 pt-2 md:grid-cols-4'>
                        <div className='col-span-full p-4'>
                                    <button
                                    className={`button button--nina px-5 py-0 border rounded-lg ${state.themes[state.theme].hover__backgroundButton_pannel} ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} text-white font-title relative block focus:outline-none text-sm text-center font-semibold uppercase tracking-widest overflow-hidden w-full`}
                                    data-text="Valider"
                                    onClick={handleClick}
                                >
                                    <span className="align-middle">V</span>
                                    <span className="align-middle">A</span>
                                    <span className="align-middle">L</span>
                                    <span className="align-middle">I</span>
                                    <span className="align-middle">D</span>
                                    <span className="align-middle">E</span>
                                    <span className="align-middle">R</span>
                                </button>
                            </div>
                        </div>
    </div>
  );
  
}