import ResponseUser from '../model/ReponseUser';
import React, {useState, useContext, useEffect} from 'react';
import DragAndDropDiv from './DragAndDropDiv'
import { ThemeContext } from '../index.js'
import Swal from 'sweetalert2'
import Constante from '../assets/const/fr/Constante.json'
import NERD from '../assets/img/UI/Nerd.webp'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";



//CSS Pour les options du <AutoComplete>
const useStyles = makeStyles({
    paper: {
        
      border: "1px solid white",
      "background": "linear-gradient(20deg, #0B0126, #6a0139)",
      color: "white",
      "font-size": "1.5rem",
      "line-height": "1.75rem",
  }});

const useStylesKc = makeStyles({
paper: {
    border: "1px solid white",
    "background": "linear-gradient(342deg, rgba(29,37,61,1) 0%, rgba(16,23,33,1) 50%, rgba(29,37,61,1) 100%);",
    color: "white",
    "font-size": "1.5rem",
    "line-height": "1.75rem",
}});

export default function Question(props) {

    const [state, setState] = useContext(ThemeContext);

    const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

    const [goldValue, setGoldValue] = useState(0);

    const [selectValue, setSelectValue] = useState('');

    //réponse pour le input autocompletion
    const [formAnswer, setFormAnswer] = useState();
    const classesBackground = useStyles();
    const classesBackgroundKc = useStylesKc();
    


    const styles = {    
        "borderTopLeftRadius": "6px",
        "borderTopRightRadius": "6px",
        border: "1px solid white",
        "borderColor": "white",
        "maxWidth": "100%",
    };

    const correctAnswer = () => {
        Swal.fire({
            title: Constante.BRAVO,
            text: Constante.VOUS_AVEZ_TROUVE_LA_BONNE_REPONSE,
            icon: 'success',
            timer: 1500,
        })
    };

    const wrongAnswer = () => {
        Swal.fire({
            title: Constante.DOMMAGE,
            text: Constante.VOUS_AVEZ_SELECTIONNE_LA_MAUVAISE_REPONSE,
            icon: 'error',
            timer: 1500,
        })
    };

    function handleChange(event, value){
        setFormAnswer(value);
    }

    function handleClick(valueSelected) {    
        let isCorrect;
        switch (props.question.type) {
            case "cooldown":
            case "mana":
                valueSelected === props.question.reponse ? isCorrect = true : isCorrect = false;
                break;
            case "itemFacile":
                Math.abs(Number(goldValue) - props.question.reponse) <= 500 ? isCorrect = true : isCorrect = false;
                setGoldValue(0);
                break;
            case "itemMoyen":
                Math.abs(Number(goldValue) - props.question.reponse) <= 250 ? isCorrect = true : isCorrect = false;
                setGoldValue(0);
                break;
            case "itemDifficile":
                Number(goldValue) === props.question.reponse ? isCorrect = true : isCorrect = false;
                setGoldValue(0);
                break;
            case "nomSkin":
                valueSelected.normalize() === props.question.reponse.normalize() ? isCorrect = true : isCorrect = false;
                setSelectValue('');
                break;
            case "titreChampionMoyen":
                valueSelected.normalize() === props.question.reponse.normalize() ? isCorrect = true : isCorrect = false;
                setFormAnswer('')
                break;
            case "dateSortieChampion":
                const tmpArray = []
                valueSelected.map((value)=> tmpArray.push(value.value))
                JSON.stringify(valueSelected) === JSON.stringify(props.question.reponse) ? isCorrect = true : isCorrect = false;
                break;    
            default:
                valueSelected.normalize() === props.question.reponse.normalize() ? isCorrect = true : isCorrect = false;
                break;
        }
        //Pop UP correct / fausse réponse
        //isCorrect ? correctAnswer() : wrongAnswer();

        props.setFinalResponse(oldArrayResponse=>[...oldArrayResponse, new ResponseUser(props.question.question, props.question.propositions, props.question.image, valueSelected, props.question.reponse, isCorrect, props.question.indication, props.question.type)])
        props.handleCount();
    }
    //On gère le style des balise <hr>
    const hrColor = {
        "display": "block", "height": "1px",
        "border": 0,
        "borderTop": state.themes[state.theme].hrBorderTopDaily,
        "margin": "1em 0",
    }
    // Gestion du html à afficher
    function handleRender() {
        switch (props.question.type) {
            case "itemFacile":
            case "itemMoyen":
            case "itemDifficile":
                return (
                 <div className={`grid grid-cols-3 place-self-center gap-4 rounded-lg ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} mb-4 p-2`}>
                     {
                         props.question.image.map((image, index) => {
                             return (
                                 <div key={index} className='col-span-1 '>
                                     <img className='rounded-xl ' alt="Image item" src={image} />
                                 </div>
                             )
                         })
                     }
                 </div>
                )
            case "passif-description":
            case "spell-description":
            case "runeDescription":
                return (
                    <div className='col-span-1 place-self-center mb-5 md:mb-0'>
                        <div className='flex items-center flex-col mb-4 w-full'>
                            <div className='flex mr-6 justify-center font-spegiel-bold w-9/12 sm:w-8/12 text-xs md:text-base lg:text-lg'>{renderHTML(props.question.indication)}</div>
                            <div className='flex mx-6 justify-center items-center w-32 h-auto border border-white'>
                                <img className='w-4/12' src={NERD}></img>
                            </div>
                        </div>
                    </div>
                )
            case "titreChampionFacile":
            case "titreChampionMoyen":
                return (
                    <div className='col-span-1 place-self-center mb-5 md:mb-0'>
                        <div className='flex items-center flex-col mb-4 w-full'>
                            <div className='flex justify-center font-spegiel-bold w-full text-xs md:text-base lg:text-lg'>{renderHTML(props.question.indication)}</div>
                            <div className='flex mx-6 justify-center items-center w-32 h-auto border border-white'>
                                <img className='w-4/12' src={NERD}></img>
                            </div>
                        </div>
                    </div>
                )
            case "stats":
                return (
                    <div className='col-span-1 place-self-center w-1/2 h-64 shadow-2xl overflow-y-scroll'>
                        <table className='table-fixed border-collapse w-full border border-slate-400'>
                            <thead className='bg-slate-50 dark:bg-slate-700'>
                                <tr>
                                    <td className='w-1/2 border text-sm border-slate-300 dark:border-slate-600 font-semibold p-4 text-slate-900 dark:text-slate-200 text-left sm:text-sm'>Statistique</td>
                                    <td className='w-1/2 border text-sm border-slate-300 dark:border-slate-600 font-semibold p-4 text-slate-900 dark:text-slate-200 text-left sm:text-sm'>Valeur</td>
                                </tr>
                            </thead>
                            <tbody>
                            {Object.entries(props.question.indication).map(([key, value]) => {
                                return (
                                    <tr key={key}>
                                        <td className='border text-xxs border-slate-300 dark:border-slate-700 p-4 text-white sm:text-sm'>{key}</td>
                                        <td className='border text-xs border-slate-300 dark:border-slate-700 p-4 text-white sm:text-sm'>{value}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                )
            case 'skin':
            case 'nomSkin':
                return (
                    <div className='grid col-span-1 w-3/9  rounded-lg'>
                        <img className=' justify-self-center w-4/12 object-fit-cover rounded-xl mb-4' src={props.question.image} alt="Illustration correspondante à la question." />
                    </div>
                )
            case 'dateSortieChampion':
                return(
                    <div className="h-auto w-auto">
                        <DragAndDropDiv question={props.question} onClick={handleClick}/>
                    </div>             
                )
            default:
                return (
                    <div className='place-self-center col-span-1'>
                        <img className='w-40 h-full mb-4 shadow-2xl border object-fit-cover place-self-center rounded-xl' src={props.question.image} alt="Illustration correspondante à la question." />
                    </div>
                )
        }
    }

    function handleProposition(){
        switch(props.question.type){
            case 'itemFacile':
            case 'itemMoyen':
            case 'itemDifficile':
                return (
                    <div>
                    <h2 className='grid place-items-center pb-3 uppercase font-title text-white text-sm md:text-4xl'>
                        <span className=''>Propositions</span>
                    </h2>
                    <div className='grid gap-4 pt-2 md:grid-cols-4'>
                    <div className='col-span-full' >
                                    <input className='w-full mb-4 font-texxt rounded-lg text-black text-center' placeholder="Gold total" type="number" value={goldValue} onChange={(event) => setGoldValue(event.target.value)}
                                           onKeyDown={(event) => {
                                               if (event.key === 'Enter') {
                                                   handleClick(goldValue);
                                               }
                                           }}
                                    />
                                        <button
                                        disabled={goldValue===0}
                                        className={`button button--nina px-5 py-0 border ${goldValue===0 && "hover:bg-gray-500 bg-slate-400"} ${state.themes[state.theme].hover__backgroundButton_pannel} ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} text-white font-title relative block focus:outline-none text-sm text-center font-semibold uppercase tracking-widest overflow-hidden w-full`}
                                        data-text="Valider"
                                        onClick={() => handleClick(goldValue)}
                                    >
                                        <span className="align-middle">V</span>
                                        <span className="align-middle">A</span>
                                        <span className="align-middle">L</span>
                                        <span className="align-middle">I</span>
                                        <span className="align-middle">D</span>
                                        <span className="align-middle">E</span>
                                        <span className="align-middle">R</span>
                                    </button>
                                </div>
                    </div>
                </div>
                )
            case 'titreChampionFacile':
            case 'titreChampionMoyen':
                return(
                    <div>
                    <h2 className='grid place-items-center pb-3 uppercase font-title text-white text-sm md:text-4xl'>
                        <span className=''>Propositions</span>
                    </h2>
                        {props.question.difficulty==="moyen" ?
                        <div className='grid gap-4 pt-2 md:grid-cols-4'>
                            <div className='col-span-full' >
                                <Autocomplete
                                    name="answer"
                                    id="proPlayerOptions"
                                    onChange={handleChange}
                                    getOptionLabel={(championName) => championName}
                                    options={props.championName}
                                    style={styles}
                                    value={null}
                                    className='autocomplete-input'
                                    classes={{ paper: state.theme === "kcorp" ? classesBackgroundKc.paper : classesBackground.paper}}
                                    renderInput={(params) => <TextField {...params} label="Selectionne un champion"                              
                                    />}
                                />
                                <button
                                disabled={formAnswer===""}
                                className={`button button--nina px-5 py-0 border ${formAnswer==="" && "hover:bg-gray-500 bg-slate-400"} ${state.themes[state.theme].hover__backgroundButton_pannel} ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} text-white font-title relative block focus:outline-none text-sm text-center font-semibold uppercase tracking-widest overflow-hidden w-full`}
                                data-text="Valider"
                                onClick={() => handleClick(formAnswer)}
                                >
                                    <span className="align-middle">V</span>
                                    <span className="align-middle">A</span>
                                    <span className="align-middle">L</span>
                                    <span className="align-middle">I</span>
                                    <span className="align-middle">D</span>
                                    <span className="align-middle">E</span>
                                    <span className="align-middle">R</span>
                                </button>
                            </div>
                        </div>
                        :
                            <div className='grid gap-4 pt-2 md:grid-cols-4'>
                                {
                                    props.question.propositions.map((proposition, index) => {
                                        return (
                                            <div key={index} className='col-span-1 flex w-full' >
                                                <button
                                                    className={`button px-5 py-2 w-full text-sm text-center text-white font-texxt uppercase rounded relative block  ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} ${state.themes[state.theme].hover__returnToMenuOrRestart__backgroundButton}  md:text-xs lg:text-xs xl:text-base`}
                                                    onClick={() => handleClick(proposition)}>{proposition}
                                                </button>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        }
                        
                    </div>
                )
            case 'dateSortieChampion' :
                
                break;
            case 'nomSkin':
                return (
                    <div className=''>
                        <h2 className='grid place-items-center pb-3 uppercase font-title text-white text-sm md:text-4xl'>
                            <span>Propositions</span>
                        </h2>
                        <div className='grid gap-4 pt-2 md:grid-cols-4'>
                        <div className='col-span-full ' >
                            <select className='w-full mb-4 font-texxt rounded-lg text-black text-center' value={selectValue} onChange={(event) => setSelectValue(event.target.value)}>
                                    <option value="">Choisissez un skin</option>
                                    {props.question.propositions.map(prop => (
                                        <option key={prop} value={prop}>
                                        {prop}
                                        </option>
                                    ))}
                            </select>
                            <button
                                        disabled={selectValue===''}
                                        className={`button button--nina px-5 py-0 border ${selectValue==='' && "hover:bg-gray-500 bg-slate-400"} ${state.themes[state.theme].hover__backgroundButton_pannel} ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} text-white font-title relative block focus:outline-none text-sm text-center font-semibold uppercase tracking-widest overflow-hidden w-full`}
                                        data-text="Valider"
                                        onClick={() => handleClick(selectValue)}
                                    >
                                        <span className="align-middle">V</span>
                                        <span className="align-middle">A</span>
                                        <span className="align-middle">L</span>
                                        <span className="align-middle">I</span>
                                        <span className="align-middle">D</span>
                                        <span className="align-middle">E</span>
                                        <span className="align-middle">R</span>
                            </button>
                        </div>
                        </div>
                    </div>
                )
            default :
            return(
                <div>
                    <h2 className='grid place-items-center pb-3 uppercase font-title text-white text-sm md:text-4xl'>
                        <span>Propositions</span>
                    </h2>
                    <div className='grid gap-4 pt-2 md:grid-cols-4'>
                        {
                            props.question.propositions.map((proposition, index) => {
                                return (
                                    <div key={index} className='col-span-1 flex w-full' >
                                        <button
                                            className={`button px-5 py-2 w-full text-sm text-center text-white font-texxt uppercase rounded relative block  ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} ${state.themes[state.theme].hover__returnToMenuOrRestart__backgroundButton}  md:text-xs lg:text-xs xl:text-base`}
                                            onClick={() => handleClick(proposition)}>{proposition}
                                        </button>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            )


        }
    }

    return (
        <div className='h-screen'>
            <p className={`flex justify-center text-xl font-title ${state.themes[state.theme].textColor} uppercase tracking-wider md:text-4xl lg:text-6xl xl:text-7xl`}>Question {props.count}</p>
            <div className='grid grid-cols-1 gap-4 items-start place-items-center text-white'>

                <div className={`grid w-11/12 h-auto border rounded-xl shadow-2xl shadow-blue-200/50 bg-gradient-to-r ${state.themes[state.theme].backGroundColorRadiantQuestion} background-animate`}>
                    <div className={`grid place-self-center`}>
                        <h2 className={` py-3 uppercase font-spegiel text-xs text-white sm:text-xl md:text-xl lg:text-2xl xl:text-3xl md:py-0`}>{props.question.question}</h2>
                        <div className='px-8'>
                            <hr className='mb-2' style={hrColor}></hr>
                        </div>
                    </div>
                    { handleRender() }
                </div>

                {props.question.type!=="dateSortieChampion" &&
                    <div className={`w-9/12 grid col-span-1 shadow-2xl place-self-center border border-blue-200 shadow-blue-200/50 rounded-lg bg-gradient-to-l ${state.themes[state.theme].backGroundColorRadiantQuestion} background-animate  py-4 px-6 mb-6`}>
                        { handleProposition() }
                    </div>
                }
            </div>
        <div/>
        </div>
    );

}
