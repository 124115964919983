import { PannelChampModel } from '../model/PannelChampModel';

const PannelChampService = {

    //Récuperer la liste des jpg des champions dans assets/data/img/profil
    //Créer une liste d'objet PannelChamp 
    //Renvoyer cette liste dans un useState du jsx
    //
    getAllProfileChamp(champion, id){
        const importAll = (r) => {
            //r.key() contient tous les éléments du repertoire en paramètre a l'appel (ici on garde que les jpg)
            //on ne garde que le chemin de l'image === au champion courant
            //on renvoie avec le .map(r) le chemin courant une fois les filtres passées
            return r.keys().filter(element => (element.replace('.png', '').replace('./', '')
                === champion )).map(r);
        };

        let fileJsonChamp = importAll(require.context('../assets/img/champion/championProfile/', true, /\.(png)$/));
        const pannelChamp = new PannelChampModel(id, champion, fileJsonChamp, true);
        return pannelChamp;
    },

    getAllChampionsNames() {
        let champions = [];
        const importAll = (r) => {
            return r.keys().map(r);
        };

        let championsJson = importAll(require.context('../assets/data/champion/', true, /\.(json)$/));
        for (let champ of championsJson) {
            champions.push(Object.keys(champ['data']).toString());
        }

        return champions;
    }
}
export default PannelChampService;