export default class ReponseUser {

    constructor(question, propositions, image, reponseChoisi, reponseCorrect, isCorrect, description, typeQuestion) {
        this.question = question;
        this.propositions = propositions;
        this.image= image;
        this.reponseChoisi = reponseChoisi;
        this.reponseCorrect = reponseCorrect;
        this.isCorrect = isCorrect;
        this.typeQuestion = typeQuestion;
        this.description = description;
    }
}
