import React, { useContext } from 'react';
import { ThemeContext } from '../../index.js'

export default function ProfileChampion(props){

    const [state, setState] = useContext(ThemeContext);

    let styles; 
    if (props.profileChampion.isFind) {
        styles = {
            backgroundColor: "#40AE23",
            padding: "2px",
            
        };
    }else{
        if (state.theme === "kcorp") {
            styles = {
                "backgroundImage": state.themes[state.theme].backgroundButton,
                "background-size": "contain",
                padding: "2px"
            }
        }else{
            styles = {
                "backgroundImage": "none",
                padding: "2px"
            }
        };
    };

    return(
        <div className={`border-2 ${state.themes[state.theme].borderColor_champion_pannel} ${state.themes[state.theme].background_champion_pannel} border-solid h-6 w-full md:h-8 lg:h-10`} style={styles}>
            {!props.profileChampion.isHide ? 
            <div className='w-full h-full overflow-hidden' data-bs-toggle="tooltip" data-bs-placement="top" title={props.profileChampion.championName}>
                <img alt="championImg" src={props.profileChampion.imgLink} className="w-full h-full object-cover"/>
            </div>
            :
            <></>                
            }
        </div>
    );
}