import React, { useState, useContext } from "react";
import { ThemeContext } from '../index.js'
import SEXO from "../assets/img/UI/SEXO.webp";
import Popup from '../pages/Popup';



function Footer(){

    const [state] = useContext(ThemeContext);

    const [showPopupRules, setShowPopupRules] = useState(false);

    const togglePopupRules = () => {
        setShowPopupRules(!showPopupRules);
      }

    return(
        <div className="grid grid-cols-8 border-t pt-2 pl-4 pr-4 sticky h-full">
            <div className="grid col-start-4 col-end-6  md:col-start-4 md:col-end-6">
                <h3 className={`flex h-5 justify-start justify-self-center text-xs font-title ${state.themes[state.theme].button__footer__textColor} tracking-wider md:text-sm lg:text-base xl:text-base`}>QuizzLOL</h3>
                <div className="grid content-start col-start-1 col-end-2 h-20">
                    <button
                        className={`button
                        w-full  block rounded text-center text-xs uppercase focus:outline-none
                        p-1 tracking-widest ${state.themes[state.theme].button__footer__backgroundButton} text-white font-spegiel-bold font-bold md:hidden`}
                        onClick={togglePopupRules}>
                        <span className="align-middle">Politique</span>
                    </button>
                    <p className="w-full p-1 font-spegiel text-xxs text-white hidden md:block tracking-wider md:text-xs xl:text-xs">QuizzLol a été créé en vertu de la politique juridique Riot Games intitulée « Jargon juridique » relative à l'utilisation d'actifs de Riot Games.  Riot Games ne soutient ni ne sponsorise ce projet.</p>
                </div>

            </div>
            <div className="grid col-start-7 col-end-9 md:col-start-7 md:col-end-9">
                
                <div>
                    <span className="flex w-full justify-center font-spegiel-bold text-xs text-[#cccece] uppercase tracking-wider md:text-sm lg:text-xl">Contact</span>
                    <div className="grid grid-rows-2 justify-items-center">
                        <span className="flex justify-center p-1 w-full font-spegiel text-xs text-white  tracking-wider md:text-xs lg:text-base" >quizzlol.app@gmail.com</span>
                    </div>
                </div>
            </div>
            {showPopupRules && <Popup
                                content={<>
                                    <p className='flex justify-center text-xl font-title text-white uppercase md:text-xl lg:text-2xl xl:text-4xl'>Politique Riot Games</p>
                                    <dl className="w-full divide-y divide-gray-200 dark:text-white dark:divide-gray-400">
                                        <div className="flex flex-col pt-3 pb-2">
                                            <div className='flex items-center'>
                                                <dd className="flex items-center text-white justify-between w-full text-xs font-spegiel-bold">
                                                QuizzLol a été créé en vertu de la politique juridique Riot Games intitulée « Jargon juridique » relative à l'utilisation d'actifs de Riot Games.  Riot Games ne soutient ni ne sponsorise ce projet.
                                                </dd>
                                            </div>
                                        </div>
                                        
                                    </dl>
                                </>}
                                handleClose={togglePopupRules}
                            />}
        </div>
    )
}
export default Footer;