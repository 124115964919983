import ItemData from '../assets/data/item.json';
import runeData from '../assets/data/runesReforged.json';
import { Question } from '../model/Question';
import storage from '../service/Storage.ts';
import QuestionData from '../tools/question.json';
/**
 * Classe de service pour les questions
 * Les questions sont stockées dans un fichier json
 */
const QuestionService = {

    getRandomChamp: function (question) {
        // Choix d'un champion aléatoire
        let champions = storage.getChamps();
        let randomChampion = champions[Math.floor(Math.random() * champions.length)];
        if ( question.type !== 'mana' || question.type !== 'cooldown' ) {
            question.reponse = randomChampion;
        }
        return {champions, randomChampion};
    },

    // Récupère une question de manière aléatoire en fonction de la difficulté
    async getRandomQuestionWithDifficulty(difficulty) {
        let questionJson;
        switch (difficulty) {
            case 'facile':
                questionJson = QuestionData.data.filter(question => question.difficulty === 'facile');
                break;
            case 'moyen':
                questionJson = QuestionData.data.filter(question => question.difficulty === 'moyen');
                break;
            case 'difficile':
                questionJson = QuestionData.data.filter(question => question.difficulty === 'difficile');
                break;
            default:
                questionJson = QuestionData.data.filter(question => question.difficulty === 'facile');
        }
        let randomQuestion = questionJson[Math.floor(Math.random() * questionJson.length)];
        const question = new Question(randomQuestion.id, randomQuestion.question, randomQuestion.type, randomQuestion.difficulty);
        switch(question.type){
            case 'itemFacile':
            case 'itemMoyen':
            case 'itemDifficile':
                // Si question de type item alors le fonctionnement est différent
                // Pas de récupération des champions
                // Récupération du / des fichier à récupérer pour la question
                const filenamesItem = await this.getFilenameForQuestion(question.reponse, question.type);
                question.filename = filenamesItem['data']['image'];
                // Récupération des images
                question.image = this.getFileForResponse(question.type, question.filename)
                    .map((img) => Object.values(img)[0]);
                // Définition des réponses & pas de proposition
                // La somme de la valeur des items + l'indication = libelle de l'item
                question.reponse = filenamesItem['data']['values'].reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                question.indication = filenamesItem['data']['indication'];
                // Les valeurs en gold des items
                question.propositions = filenamesItem['data']['values'];
                break;
            case 'runeImage':
                const filenamesRune = await this.getFilenameForQuestion(question.reponse, question.type);
                question.filename = filenamesRune['data']['image'];
                //On récupère directement un nombre aléatoire entre 0 et 3 qui nous permettra de récuperer la bonne image et faire le lien
                //Avec la bonne réponse (les noms des fichiers et le nom dans le json sont pas les mêmes donc aucun autre moyen de faire le lien)
                const rdmIndex = Math.floor(Math.random() * question.filename.length);
                question.image = Object.values(this.getFileForResponse(question.type, question.filename[rdmIndex])[0]);
                question.propositions = filenamesRune['data']['name']; 
                question.reponse = (filenamesRune['data']['name'])[rdmIndex];
                break;
            case 'runeDescription':
                const filenamesRuneDescription = await this.getFilenameForQuestion(question.reponse, question.type);
                question.filename = filenamesRuneDescription['data']['image'];
                const rdmIndexDescription = Math.floor(Math.random() * question.filename.length);
                question.image = Object.values(this.getFileForResponse(question.type, question.filename[rdmIndexDescription])[0]);
                question.indication = (filenamesRuneDescription['data']['shortDesc'])[rdmIndexDescription];
                question.propositions = filenamesRuneDescription['data']['name'];
                question.reponse = (filenamesRuneDescription['data']['name'])[rdmIndexDescription];
                break;
            case 'dateSortieChampion':
                //Liste des nom des champions (nom du fichier pour récup l'img associée)
                const arrayChamp = [];
                //Liste contenant les dates de sorties du champions
                const filenamesDate = [];
                for(let i=0; i<4;i++){
                    const {champions, randomChampion} = this.getRandomChamp(question); 
                    //On ne prend pas 2 fois le même champion
                    
                    if (!arrayChamp.includes(randomChampion)) {
                         //récupération des information des 4 champion (nom image + date de création)
                        const filenamesInfoTmp = await this.getFilenameForQuestion(randomChampion, question.type);    
                        if (!filenamesDate.includes(filenamesInfoTmp['data']['values'])) {

                            filenamesDate.push(filenamesInfoTmp['data']['values']);
                            arrayChamp.push(filenamesInfoTmp['data']['image']);
                        } else {
                            i--;
                        }
                    } else {
                        i--;
                    }    
                }
                question.filename = arrayChamp;
                let moment = require('moment');
                const datesInOrder = filenamesDate.map(date => {
                    let parsedDate = moment(date, "DD-MM-YYYY");
                    return parsedDate.format("DD-MM-YYYY");
                  });                  
                const arrayImage = [];
                for(let i=0; i<4;i++){
                    arrayImage.push({
                        id : i,
                        img : Object.values(this.getFileForResponse(question.type, question.filename[i])[0])[0],
                        value : datesInOrder[i]
                    });
                }
                question.image = arrayImage;

                const arrayResponse = arrayImage.slice();
                arrayResponse.sort((a, b) => {
                    let dateA = moment(a.value, "DD-MM-YYYY");
                    let dateB = moment(b.value, "DD-MM-YYYY");
                    return dateA - dateB;
                  });
                
                question.reponse = arrayResponse;

                //const filenamesDateSortieChampion = await this.getFilenameForQuestion(question.reponse, question.type);
                break;
            default:
                // Récupération du champion
                const {champions, randomChampion} = this.getRandomChamp(question);
                // Récupération du / des fichier à récupérer pour la question
                const filenames = await this.getFilenameForQuestion(question.reponse, question.type);
                // Gestion du cas où la fonction retourne un array vide
                if (filenames.length === 0) {
                    return this.getRandomQuestionWithDifficulty(difficulty);
                }
                switch (question.type) {
                    case 'spell':
                    case 'spell-description':
                        question.filename = filenames.map(element => element['data']['image']);
                        break;
                    case 'mana':
                    case 'cooldown':
                        question.filename = filenames.map(element => element['data']['image']);
                        //Pour éviter les soucis avec les cd et cout mana sur les ult on les enlèves
                        question.filename.pop();
                        break;
                    case 'stats':
                    case 'passif-description':
                    case 'titreChampionFacile':
                    case 'titreChampionMoyen':
                        question.indication = filenames['data']['values'];
                        question.filename = filenames['data']['image'];
                        break;
                    case 'nomSkin':
                        let tempFilename = filenames[Math.floor(Math.random() * filenames.length)]
                        question.filename = [tempFilename];
                        break;
                    default:
                        question.filename = filenames;
                }
                // Récupération du chemin / base64 de l'image
                const image = await this.getFileForResponse(question.type, question.filename);
                const randomIndex = Math.floor(Math.random() * Object.values(image).length);
                if ( question.type === 'mana' || question.type === 'cooldown' ) {
                    const imgTemp = "./"+question.filename[randomIndex];
                    let img = null;
                for (let i = 0; i < image.length; i++) {
                    const item = image[i];
                    if (imgTemp in item) {
                        img = item[imgTemp];
                        break;
                    }
                }    
                question.image = img
                }else{
                    question.image = Object.values(image)[0]
                }
                
                switch(question.type){
                    case 'dateSortieChampion':
                        break;
                    case 'nomSkin' :
                        let str = question.filename[0];
                        let match = str.match(/\d+/);
                        let indexOfImage = match ? match[0] : null;
                        const test = this.getRealAnswer(question.reponse, indexOfImage);
                        question.reponse = test.answer;
                        question.propositions = test.arrayOfProposition;
                        break;
                    default :
                        // Tableau temporaire pour stocker les propositions
                        const tempPropositions = [];
                        const randomArray = [];
                        let random = '';
                        // Tableau contenant de manière aléatoire 3 propositions et 1 bonne réponse
                        for (let i = 0; i < 3; i++) {
                            switch (question.type) {
                                case 'mana':
                                    // Génération d'un nombre aléatoire entre 20 et 100 et il prend seulement un chiffre divisible par 5
                                    random = Math.floor(Math.random() * 70 / 5) * 5 + 5;
                                    break;
                                case 'cooldown':
                                    // Génération d'un nombre aléatoire entre 3 et 25
                                    random = Math.floor(Math.random() * (23 - 3 + 1)) + 3;
                                    break;
                                default:
                                    random = Math.floor(Math.random() * champions.length);
                            }
                            if ( question.type === 'mana' || question.type === 'cooldown' ) {
                                if ( !randomArray.includes(random) && filenames[randomIndex]['data']['values'][0] !== random ) {
                                    randomArray.push(random);
                                    tempPropositions.push(random);
                                } else {
                                    i--;
                                }
                            } else {
                                // Si le chiffre random n'est pas déjà dans le tableau et différent de la valeur à trouver, on l'ajoute
                                if (!randomArray.includes(random) && champions[random] !== randomChampion) {
                                    randomArray.push(random);
                                    tempPropositions.push(champions[random]);
                                } else {
                                    i--;
                                }
                            }
                        }
                        // Ajout de la bonne réponse dans le tableau
                        if ( question.type === 'mana' || question.type === 'cooldown' ) {
                            // Dans ce cas on prend la première valeur du tableau ( car question demande le niveau 1 )
                            tempPropositions.push(filenames[randomIndex]['data']['values'][0]);
                            // Définition de la réponse / indication et du champion correspondant
                            question.reponse = filenames[randomIndex]['data']['values'][0];
                            question.indication = filenames[randomIndex]['data']['indication'];
                        } else {
                            // Dans le cas où ce n'est pas mana / cooldown, la bonne réponse est définit dans la méthode getRandomChamp()
                            if ( question.type === 'spell-description' ) {
                                question.indication = filenames[randomIndex]['data']['values'];
                            }
                            tempPropositions.push(randomChampion);
                        }
                        tempPropositions.sort(() => Math.random() - 0.5);
                        question.propositions = tempPropositions;
                        break;
                    }
                        break;
}    return question;
    },
    // Récupère pour le type de question et la liste des filenames à récupérer
    getFileForResponse(questionType, filenames) {
        let files;
        const importAll = (r) => {
            const results = [];
            r.keys()
            .map(item => {
                // Récupère seulement les fichiers présent dans le tableau filenames parmit tous les fichiers du répertoire /spells;
                if (filenames.includes(item.replace('./', ''))) {
                    let element = {};
                    element[item] = r(item);
                    results.push(element);
                }
            });
            return results;
        };

        //Création d'un deuxieme importAll spécialement pour les runes car l'arbo des files est différentes
        //Ici on a le dossier runes pour les img mais ensuite on a le type (Domination/Inspiration/Precision [...] ) et ensuite dans chacun de ces dossiers
        //il y a un autre dossier avec le nom du fragment de rune + l'image
        const importAllForRune = (r) => {
            const results = [];
            r.keys()
            .map(item => {
                //Récupération du type de rune + le nom du fragment pour entrer dans le bon dossier
                const typeRune = filenames.split('/')[2] + "/" + filenames.split('/')[3];
                //Récupération de l'image .png 
                const image = filenames.split('/')[4];
                //L'objectif est de faire le lien entre les img entrant (dans le fichier json des infos des runes)
                //Et ce qu'il se trouve vraiment dans nos dossiers d'images
                //Récuparation du chemin pour accéder au png depuis /runes/
                const indexToGetThirstPart = item.lastIndexOf("/");
                const pathOfImg = item.substring(0, indexToGetThirstPart);
                //Récuparation du png depuis /runes/ + le chemin qui se trouve dans l'item
                const indexToGetLastPart = item.lastIndexOf("/");
                const nameOfImage = "."+item.substring(indexToGetLastPart, item.length);
                // Récupère seulement les fichiers présent dans le tableau filenames parmit tous les fichiers du répertoire /spells;
                console.log(filenames)
                console.log(typeRune + '   ' + image + '  ' + nameOfImage)
                if(typeRune.includes(pathOfImg.replace('./', ''))){
                    if (image.includes(nameOfImage.replace('./', ''))) {
                        let element = {};
                        element[item] = r(item);
                        results.push(element);
                    }
                }
            });
            return results;
        };
        // Pour quoi un Switch ?
        // On ne peut pas passer de variable en paramètre de la fonction .context 
        // Donc le path n'est pas stocké dans le json, mais géré ici dans le switch
        switch(questionType) {
            case 'itemFacile':
            case 'itemMoyen':
            case 'itemDifficile':
                files = importAll(require.context('../assets/img/items/', true, /\.(png|jpe?g|svg|gif)$/));
                return files;
            case 'runeImage':
            case 'runeDescription':
                files = importAllForRune(require.context('../assets/img/runes', true, /\.(png|jpe?g|svg|gif)$/));
                break;
            case 'mana':
            case 'cooldown':
            case 'spell-description':
            case 'spell':
                files = importAll(require.context('../assets/img/champion/spell/', true, /\.(png|jpe?g|svg|gif)$/));
                break;
            case 'stats':
                // On veut récupérer le fichier du skin de base donc on rajoute '_0' avant l'extension
                filenames = filenames.replace('.png', '_0.jpg');
                files = importAll(require.context('../assets/img/champion/profil/', true, /\.(png|jpe?g|svg|gif)$/));
                break;
            case 'skin':
                files = importAll(require.context('../assets/img/champion/profil/', true, /\.(png|jpe?g|svg|gif)$/));
                
                break;
            case 'titreChampionFacile':
            case 'titreChampionMoyen':
                files = importAll(require.context('../assets/img/champion/profil/', true, /\.(png|jpe?g|svg|gif)$/));
                break;
            case 'nomSkin':
                files = importAll(require.context('../assets/img/champion/profil/', true, /\.(png|jpe?g|svg|gif)$/));
                break;
            case 'dateSortieChampion':
                files = importAll(require.context('../assets/img/champion/loading/', true, /\.(png|jpe?g|svg|gif)$/));
                break;
            case 'passif-description':
            case 'passif':
                files = importAll(require.context('../assets/img/champion/passif/', true, /\.(png|jpe?g|svg|gif)$/));
                break;
            default:    
                files = [];
                break;
        }
        // Choisit un fichier aléatoire
        if(files !== undefined && files !== null && questionType!=='dateSortieChampion' && questionType!=='runeImage' && questionType!=="runeDescription" && questionType!=="mana" && questionType!=="cooldown"){
            files = files[Math.floor(Math.random() * files.length)];
        }
        return files;
    },

    // Récupère tous les noms des champions de lol
    getAllChampionsNames() {
        let champions = [];
        const importAll = (r) => {
            return r.keys().map(r);
        };

        let championsJson = importAll(require.context('../assets/data/champion/', true, /\.(json)$/));
        for (let champ of championsJson) {
            champions.push(Object.keys(champ['data']).toString());
        }
        return champions;
    },

    async getFilenameForQuestion(champ, questionType) {

        // Récupération du fichier ayant le nom du champion
        const importAll = (r) => {
            return r.keys().filter(element => element.replace('.json', '').replace('./', '') === champ).map(r);
        };

        let fileJsonChamp;

        if (!questionType.includes('item')) {
            fileJsonChamp = importAll(require.context('../assets/data/champion/', true, /\.(json)$/));
        }

        switch (questionType) {
            case 'itemFacile':
            case 'itemMoyen':
            case 'itemDifficile':
                // Dans ce cas on va utiliser seulement les infos de ItemData
                // Récupération aléatoire de 3 items
                const itemArray = Object.entries(ItemData.data)
                    .filter(element => element[1].gold.total !== 0)
                    .sort(() => Math.random() - 0.5)
                    .slice(0, 3);
                return {
                    data: {
                        image: itemArray.map(item => item[1].image.full),
                        values: itemArray.map(item => item[1].gold.total),
                        indication: itemArray.map(item => item[1].name)
                    }
                };
            case 'runeImage':
            case 'runeDescription':
                const listtmp = [];
                runeData.map(slots => slots.slots.map(runes => runes.runes.map(elem => listtmp.push(elem))));
                const randomList = Object.entries(listtmp).sort(() => 0.5 - Math.random()).slice(0, 4);                    
                return{
                    data: {
                        image: randomList.map(item => item[1].icon),
                        shortDesc: randomList.map(item => item[1].shortDesc),
                        name: randomList.map(item => item[1].key)
                    }
                };
            case 'dateSortieChampion':
                 return {
                    data: {
                        image: fileJsonChamp[0]['data'][champ]['skins'].map((_, index) => `${champ}_${_.num}.jpg`).filter(element => element === `${champ}_0.jpg`)[0],
                        values: fileJsonChamp[0]['release_date']
                    }
                 } 
            case 'spell-description':
            case 'mana':
            case 'spell':
            case 'cooldown':
                // RG si c'est un sort sans cout en mana
                if ( questionType === 'mana' ) {
                    // Filtrage pour éliminer les sorts qui n'ont pas de cout en mana
                    fileJsonChamp[0]['data'][champ]['spells'] = fileJsonChamp[0]['data'][champ]['spells'].filter(spell => spell['costBurn'] !== '0');
                    if ( fileJsonChamp[0]['data'][champ]['spells'] === undefined ) {
                        return [];
                    }
                }
                // RG si c'est un sort sans cd ou cd = 0
                if ( questionType === 'cooldown' ) {
                    // Filtrage pour éliminer les sorts qui n'ont pas de cd
                    fileJsonChamp[0]['data'][champ]['spells'] = fileJsonChamp[0]['data'][champ]['spells'].filter(spell => spell['cooldownBurn'] !== '0');
                    if ( fileJsonChamp[0]['data'][champ]['spells'] === undefined ) {
                        return [];
                    }
                }
                // Retourne pour chaque sort un objet avec le nom du fichier pour l'image et en valeur soit le cooldown soit le mana
                return fileJsonChamp[0]['data'][champ]['spells'].map(spell => {
                        let values;
                        switch (questionType) {
                            case 'mana':
                                values = spell['cost'];
                                break;
                            case 'cooldown':
                                values = spell['cooldown'];
                                break;
                            case 'spell-description':
                                values = spell['description'].replaceAll(champ, '  [...]  ').replaceAll("Rek'sai", '  [...]  ').replaceAll("Dr. Mundo", '  [...]  ').replaceAll("Kai'sa", '  [...]  ').replaceAll("K'Santé", '  [...]  ')
                                .replaceAll("Kog'Maw", '  [...]  ').replaceAll("Kha'Zix", '  [...]  ').replaceAll("Le Blanc",'  [...]  ').replaceAll("Lee Sin",'')
                                .replaceAll("Miss Fortune",'  [...]  ').replaceAll("Miss Fortune",'  [...]  ').replaceAll("Tahm Kench", '  [...]  ').replaceAll("Twisted Fate", '  [...]  ')
                                .replaceAll("Xin Zhao", '  [...]  ').replace(fileJsonChamp[0]['data'][champ]['name'], '  [...]  ');
                                break;
                            default:
                                break;
                        }
                        return {
                            data: {
                                image: spell['image']['full'],
                                values: values,
                                indication: spell['id']
                            }
                        };
                    })
            case 'skin':
            case 'nomSkin':
                return fileJsonChamp[0]['data'][champ]['skins'].map((_, index) => `${champ}_${_.num}.jpg`).filter(element => element !== `${champ}_0.jpg`);
            case 'passif-description':
                return {
                    data: {
                        image: fileJsonChamp[0]['data'][champ]['passive']['image']['full'],
                        values: fileJsonChamp[0]['data'][champ]['passive']['description'].replaceAll(champ, '  [...]  ')
                        .replaceAll("Rek'sai", '  [...]  ').replaceAll("Dr. Mundo", '  [...]  ').replaceAll("Kai'sa", '  [...]  ').replaceAll("K'Santé", '  [...]  ')
                        .replaceAll("Kog'Maw", '  [...]  ').replaceAll("Kha'Zix", '  [...]  ').replaceAll("Le Blanc",'  [...]  ').replaceAll("Lee Sin",'')
                        .replaceAll("Miss Fortune",'  [...]  ').replaceAll("Miss Fortune",'  [...]  ').replaceAll("Tahm Kench", '  [...]  ').replaceAll("Twisted Fate", '  [...]  ')
                        .replaceAll("Xin Zhao", '  [...]  ').replace(fileJsonChamp[0]['data'][champ]['name'], '  [...]  '),
                    }
                }
            case 'titreChampionFacile':
            case 'titreChampionMoyen':
                return {
                    data: {
                        image: fileJsonChamp[0]['data'][champ]['skins'].map((_, index) => `${champ}_${_.num}.jpg`).filter(element => element === `${champ}_0.jpg`),
                        values: fileJsonChamp[0]['data'][champ]['title'].replaceAll(champ, '  [...]  ') .replaceAll("Rek'sai", '  [...]  ').replaceAll("Dr. Mundo", '  [...]  ').replaceAll("Kai'sa", '  [...]  ').replaceAll("K'Santé", '  [...]  ')
                        .replaceAll("Kog'Maw", '  [...]  ').replaceAll("Kha'Zix", '  [...]  ').replaceAll("Le Blanc",'  [...]  ').replaceAll("Lee Sin",'')
                        .replaceAll("Miss Fortune",'  [...]  ').replaceAll("Miss Fortune",'  [...]  ').replaceAll("Tahm Kench", '  [...]  ').replaceAll("Twisted Fate", '  [...]  ')
                        .replaceAll("Xin Zhao", '  [...]  '),
                    }
                }
            case 'stats':
                return {
                    data: {
                        image: fileJsonChamp[0]['data'][champ]['image']['full'],
                        values: fileJsonChamp[0]['data'][champ]['stats'],
                    }
                }
            case 'passif':
                return fileJsonChamp[0]['data'][champ]['passive']['image']['full'];
            default:
                return [];
        }
    },

    getRealAnswer(champ, indexOfImage){
  
    // Récupération du fichier ayant le nom du champion
        const importAll = (r) => {
            return r.keys().filter(element => element.replace('.json', '').replace('./', '') === champ).map(r);
        };

        let fileJsonChamp;

        fileJsonChamp = importAll(require.context('../assets/data/champion/', true, /\.(json)$/));

        const arrayOfProposition = [];

        //Trouver un moyen de trouver l'index
        const answer = fileJsonChamp[0]['data'][champ]['skins'].filter(item => item.num==indexOfImage)[0].name;
        fileJsonChamp[0]['data'][champ]['skins'].map(name => arrayOfProposition.push(name.name));
        arrayOfProposition.shift(0);
        return { answer, arrayOfProposition}

    }
}




export default QuestionService;