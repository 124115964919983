import React, { useState, useContext } from "react";
import { ThemeContext } from '../index.js'
import kamelFumi from '../assets/img/UI/KcorpTheme/Emotes/kamelFumi.png';
import SecureStorage from "react-secure-storage";
import {Link} from "react-router-dom";


import {
  NavbarContainer,
  LeftContainer,
  RightContainer,
  NavbarExtendedContainer,
  NavbarInnerContainer,
  NavbarLinkContainer,
  OpenLinksButton,
  NavLink,
  NavLinkKcorp,
  NavbarLinkExtended,
  NavbarToggleButton
} from "./NavbarStyle";
import './Navbar.css';

function Navbar() {
  const [extendNavbar, setExtendNavbar] = useState(false);
  const [state, setState] = useContext(ThemeContext);


  return (
    <NavbarContainer extendNavbar={extendNavbar}>
      <NavbarInnerContainer>
        <LeftContainer>
          <Link to="/"> <p className="quizz--logo--phone font-spegiel-bold-nav">QuizzLol</p></Link>
          {state.theme === "kcorp" ?
              <div className="flex">
                  <NavLinkKcorp to="/" className="logo__link flex font-spegiel-bold-nav">QuizzLol</NavLinkKcorp>
              </div>
              :
              <NavLink to="/" className="logo__link font-spegiel-bold-nav">QuizzLol</NavLink>
          }
          <span className="text-white text-xxxs sm:text-xs md:text-sm">Patch note : 14.8.1</span>
        </LeftContainer>
        <RightContainer>
        <NavbarLinkContainer>
        {state.theme === "kcorp" ?
            <div>
                <NavLinkKcorp to="/daily"  className="navbar__link font-spegiel-bold-nav">Quizz</NavLinkKcorp>
                <NavLinkKcorp to="/pannelChamp" className="navbar__link font-spegiel-bold-nav">Panel</NavLinkKcorp>
                <NavLinkKcorp to="/findPlayer"  className="navbar__link font-spegiel-bold-nav">Tusmo</NavLinkKcorp>
            </div>  
          :
            <div>
              <NavLink to="/daily"  className="navbar__link font-spegiel-bold-nav">Quizz</NavLink>
              <NavLink to="/pannelChamp" className="navbar__link font-spegiel-bold-nav">Panel</NavLink>
              <NavLink to="/findPlayer"  className="navbar__link font-spegiel-bold-nav">Tusmo</NavLink>
            </div>
      }
            <NavbarToggleButton className="justify-self-start">
              <label className="inline-flex items-center space-x-4 cursor-pointer dark:text-gray-100">
              <span className="font-texxt text-white">Défaut</span>
              <span className="relative">
                <input id="Toggle1" type="checkbox" className="hidden peer"  onClick={() => setState(state => ({
                  ...state,
                  theme: state.theme === 'kcorp' ? 'light' : 'kcorp'
                }))}/>
                <div className="w-10 h-6 rounded-full shadow-inner bg-red-400 peer-checked:bg-cyan-400"></div>
                <div className="absolute inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto bg-gray-800"></div>
              </span>
              <span className="font-texxt text-white">Kcorp</span>
            </label>
          </NavbarToggleButton>
              <OpenLinksButton
              onClick={() => {
                  setExtendNavbar((curr) => !curr);
              }}
              >
              {extendNavbar ? <>&#10005;</> : <> &#8801;</>}
              </OpenLinksButton>
              
          </NavbarLinkContainer>
        </RightContainer>
      </NavbarInnerContainer>
      {extendNavbar && (
        <NavbarExtendedContainer>
          <NavbarLinkExtended onClick={() => {setExtendNavbar((curr) => !curr)}} to="/"  className="navbar__link"> Menu</NavbarLinkExtended>
          <NavbarLinkExtended onClick={() => {setExtendNavbar((curr) => !curr)}} to="/daily"  className="navbar__link"> Quizz</NavbarLinkExtended>
          <NavbarLinkExtended onClick={() => {setExtendNavbar((curr) => !curr)}} to="/pannelChamp"  className="navbar__link" > Pannel</NavbarLinkExtended>
          <NavbarLinkExtended onClick={() => {setExtendNavbar((curr) => !curr)}} to="/findPlayer" className="navbar__link"> Tusmo Pro</NavbarLinkExtended>
        </NavbarExtendedContainer>
      )}
     
    </NavbarContainer>
  );
}

export default Navbar;