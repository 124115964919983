import React, { useState, useContext, createRef } from 'react';
import {Link} from "react-router-dom";
import { ThemeContext } from '../../index.js';
import {TwitterIcon, TwitterShareButton} from "react-share";
import HistoriquePlayer from './HistoriquePlayer';
import {FaCamera} from "react-icons/fa";
import Popup from "../Popup";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useScreenshot } from "use-react-screenshot";
import nationalityLogo from '../../assets/img/UI/la-navigation.png';
import identiteLogo from '../../assets/img/UI/identite.png';
import regionLogo from '../../assets/img/UI/lecLogo.png';
import masteriesLogo from '../../assets/img/UI/masteriesLol.png';
import laneIcon from '../../assets/img/UI/Middle_icon.png';
import trophyLogo from '../../assets/img/UI/trophyLogo.png';

export default function RecapPartieFindPlayer(props) {

    const [state, setState] = useContext(ThemeContext);

    const ref = createRef(null);
    const [image, takeScreenShot] = useScreenshot();
    const [showPopupScreen, setShowPopupScreen] = useState(false);
    const getImage = () => takeScreenShot(ref.current);

    // Affiche ou non la pop-up pour voir le screenshot
    const togglePopupScreenshot = () => {
        setShowPopupScreen(!showPopupScreen);
        getImage();
    }

    //Pour le retour arrière / rejouer 
    function restartGame(){
        window.location.reload();
    }

    return(
        <div>
            <p className={`flex justify-center text-xl h-6/12 font-title ${state.themes[state.theme].textColorTitle} uppercase tracking-wider sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl`}>Récapitulatif de la partie</p>
            <div className="grid grid-rows-1 grid-cols-2 gap-1 place-items-center w-full text-2xl my-4 font-black uppercase">
                <div className={`flex flex-col justify-center pl-2 pr-2 pt-6 pb-6 w-3/5 border-r border-b rounded-lg shadow-2xl text-white border-blue-200 ${state.themes[state.theme].shadow__boxStats}  bg-gradient-to-r ${state.themes[state.theme].backGroundColorRadiantRecap} background-animate`}>
                    {props.playerWin && <p className={`flex justify-center w-full font-spegiel ${state.themes[state.theme].textColor} text-xxs md:text-base lg:text-xl xl:text-2xl`}>Bravo, tu as trouvé :</p>}
                    {props.playerLose && <p className={`flex justify-center w-full font-spegiel ${state.themes[state.theme].textColor} text-xxs md:text-base lg:text-xl xl:text-2xl`}>Perdu, le joueur était :</p>}
                    <h2 className={`flex justify-center w-full font-texxt ${state.themes[state.theme].textColor} text-base md:text-xl lg:text-2xl xl:text-3xl`}>{props.playerToFind.name}</h2>
                </div>
                <div className={`flex flex-col justify-center pl-2 pr-2 pt-6 pb-4 w-3/5 border-r border-b rounded-lg shadow-2xl border-blue-200 ${state.themes[state.theme].shadow__boxStats} bg-gradient-to-r ${state.themes[state.theme].backGroundColorRadiantRecap} background-animate`}>
                    <p className={`flex justify-center w-full font-spegiel ${state.themes[state.theme].textColor} text-xxs md:font-black md:text-base lg:text-xl xl:text-2xl`}>Nombre de tentative(s) </p>
                    <h2 className={`flex justify-center w-full ${state.themes[state.theme].textColor} font-texxt text-base md:text-xl lg:text-2xl xl:text-3xl `}>{props.countTry}</h2>
                </div>
            </div>
            <div className='flex justify-center'>
                <img alt="imgPlayerFind" src={props.imgPlayerToFind} className='w-2/12 border rounded-xl mb-4'></img>
            </div>

            <div className='flex justify-center mb-10'>
                <div  className={`grid bg-gradient-to-r rounded-lg p-6 ${state.themes[state.theme].shadow__boxStats} border-b shadow-2xl text-white border-blue-200 ${state.themes[state.theme].bg__recap__stat}   ${state.themes[state.theme].backGroundColorRadiantRecap} background-animate`}>
                    <h2 className={`place-self-center mb-4 font-texxt ${state.themes[state.theme].textColor} text-base md:text-xl lg:text-2xl xl:text-3xl`}>Récapitulatif</h2>
                    <div ref={ref} className=''>
                                    <div className='grid grid-rows-1 grid-cols-6 w-full'>
                                        <div className={`grid border border-solid border-black bg-gradient-to-r ${state.themes[state.theme].background_findPlayer__gradiant} background-animate`}>
                                            <div className='grid border-b-2 w-full'>
                                                <p className='text-white text-xs place-self-center p-1 font-texxt md:text-base lg:text-xl 2xl:text-2xl'>Nom</p>
                                            </div>
                                            <LazyLoadImage className='place-self-center p-1 w-5/12' src={identiteLogo}></LazyLoadImage>
                                        </div>
                                        <div className={`grid border border-solid border-black bg-gradient-to-r ${state.themes[state.theme].background_findPlayer__gradiant} background-animate`}>
                                            <div className='grid border-b-2'>
                                                <p className='text-white text-xs place-self-center p-1 font-texxt md:text-base lg:text-xl 2xl:text-2xl'>Rôle</p>
                                            </div>
                                            <LazyLoadImage className='place-self-center p-1 w-5/12' src={laneIcon}></LazyLoadImage>
                                        </div>
                                        <div className={`grid border border-solid border-black bg-gradient-to-r ${state.themes[state.theme].background_findPlayer__gradiant} background-animate`}>
                                            <div className='grid border-b-2'>
                                                <p className='text-white text-xs place-self-center p-1 font-texxt md:text-base lg:text-xl 2xl:text-2xl'>Région</p>
                                            </div>    
                                            <LazyLoadImage className='place-self-center p-1 w-5/12' src={regionLogo}></LazyLoadImage>
                                        </div>
                                        <div className={`grid border border-solid border-black bg-gradient-to-r ${state.themes[state.theme].background_findPlayer__gradiant} background-animate`}>
                                            <div className='grid border-b-2'>
                                                <p className='text-white text-xs place-self-center p-1 font-texxt md:text-base lg:text-xl 2xl:text-2xl'>Nationalité</p>
                                            </div>
                                            <LazyLoadImage className='place-self-center p-1 w-5/12' src={nationalityLogo}></LazyLoadImage>
                                        </div>
                                        <div className={`grid border border-solid border-black bg-gradient-to-r ${state.themes[state.theme].background_findPlayer__gradiant} background-animate`}>
                                            <div className='grid border-b-2 w-full'>
                                                <p className='text-white text-xs place-self-center p-1 font-texxt sm:hidden md:text-base lg:text-xl 2xl:text-2xl'>Trophé</p>
                                                <p className='text-white text-xs place-self-center p-1 font-texxt hidden sm:block md:text-base lg:text-xl 2xl:text-2xl'>Trophé majeur</p>
                                            </div>
                                            <LazyLoadImage className='place-self-center p-1 w-5/12' src={trophyLogo}></LazyLoadImage>
                                        </div>
                                        <div className={`grid border border-solid border-black bg-gradient-to-r ${state.themes[state.theme].background_findPlayer__gradiant} background-animate`}>
                                            <div className='grid border-b-2 w-full'>
                                                <p className='text-white text-xs place-self-center p-1 font-texxt md:text-base lg:text-xl 2xl:text-2xl'>Main</p>
                                            </div>
                                            <LazyLoadImage className='place-self-center p-1 w-5/12' src={masteriesLogo}></LazyLoadImage>
                                        </div>
                                    </div>
                                        {props.histAnswer.map((hist, key)=>{
                                                return <HistoriquePlayer key={key} histPlayer={hist} isGameEnd={props.isGameEnd} />
                                            })}      
                                </div>    
                </div>
            </div>
            {props.isDailyMode && 
            <div>
                <p className={`flex justify-center text-base h-6/12 font-title text-white uppercase tracking-wider sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl`}>Tu peux partager ton résultat sur Twitter !</p>
                <div className='flex justify-center'>
                <div className='grid grid-rows-1 gap-4 grid-cols-2 mt-5'>
                    {props.playerWin ? 
                    <TwitterShareButton
                            className='place-self-end w-full grid justify-between'
                                url={'https://quizzlol.fr'}
                                title={`Je viens de trouver le joueur du jour !\nJ'ai trouvé en : ${props.countTry} coups ! 
                                `}
                                hashtags={['quizz', 'quizzlol', 'lol', 'leagueOfLegends']}
                            >
                                <button
                                    className={`flex items-center button button--nina px-5 py-0 mb-4 place-self-center font-spegiel-bold ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} ${state.themes[state.theme].hover__backgroundButton_pannel}  text-white relative block focus:outline-none rounded text-xxs text-center font-spegiel-bold font-bold uppercase tracking-widest overflow-hidden w-full md:text-sm lg:text-base xl:text-lg`}
                                 >Twitter
                                <TwitterIcon size={45} round />
                                </button>
                    </TwitterShareButton>
                    :
                    <TwitterShareButton
                    className='place-self-end w-full grid justify-between'
                    url={'https://quizzlol.fr'}
                                title={`Je n'ai pas réussi à trouver le joueur du jour !\n
                                `}
                                hashtags={['quizz', 'quizzlol', 'lol', 'leagueOfLegends']}
                            >
                            <button
                                className={`flex items-center button button--nina px-5 py-0 mb-4 place-self-center font-spegiel-bold ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} ${state.themes[state.theme].hover__backgroundButton_pannel}  text-white relative block focus:outline-none rounded text-xxs text-center font-spegiel-bold font-bold uppercase tracking-widest overflow-hidden w-full md:text-sm lg:text-base xl:text-lg`}
                                >Twitter
                            <TwitterIcon size={45} round />
                            </button>                    
                    </TwitterShareButton>
                    }
                     <button
                            className={`flex items-center button button--nina px-5 py-0 mb-4 place-self-center font-spegiel-bold ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} ${state.themes[state.theme].hover__backgroundButton_pannel}  text-white relative block focus:outline-none rounded text-xxs text-center font-spegiel-bold font-bold uppercase tracking-widest overflow-hidden w-full md:text-sm lg:text-base xl:text-lg`}
                        >
                            Screen
                    <FaCamera size={45} onClick={togglePopupScreenshot}
                                className='cursor-pointer'
                            />
                    </button>
                    {
                        showPopupScreen && <Popup
                            content={
                                <div className=''>
                                    <div>
                                        <p className={`mb-3 md:mb-5 font-title flex justify-center text-white ${state.themes[state.theme].textColorTitle}`}>
                                            Copie l'image et partage-la avec tes amis !
                                        </p>
                                    </div>
                                    <div className={'mb-3 md:mb-5 flex justify-center'}>
                                        <img className={`border rounded-lg w-10/12`} src={image} alt={"ScreenShot"} />
                                    </div>
                                </div>
                            }
                            handleClose={togglePopupScreenshot}
                        />
                    }
                    </div>
                </div>
            </div>
            
    }
            <div className='grid grid-rows-1 grid-cols-2 mt-5'>
                <button
                            className={`button button--nina px-5 py-0 mb-4 place-self-center font-spegiel-bold ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} ${state.themes[state.theme].hover__backgroundButton_pannel}  text-white relative block focus:outline-none rounded text-xxs text-center font-spegiel-bold font-bold uppercase tracking-widest overflow-hidden w-3/5 md:text-sm lg:text-base xl:text-lg`}
                            data-text="rejouer"
                            onClick={restartGame}
                        >
                        <span className="align-middle">R</span>
                        <span className="align-middle">E</span>
                        <span className="align-middle">J</span>
                        <span className="align-middle">O</span>
                        <span className="align-middle">U</span>
                        <span className="align-middle">E</span>
                        <span className="align-middle">R</span>        
                </button>
                <Link to="/" className='grid place-self-center w-full'>
                    <button
                            className={`button button--nina px-5 py-0 mb-4 place-self-center ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} ${state.themes[state.theme].hover__backgroundButton_pannel} text-white relative block focus:outline-none rounded text-xxs text-center font-spegiel-bold font-bold uppercase tracking-widest overflow-hidden w-3/5 md:text-sm lg:text-base xl:text-lg`}
                            data-text="Retour au Menu"
                    >
                        <span className="align-middle">R</span>
                        <span className="align-middle">E</span>
                        <span className="align-middle">T</span>
                        <span className="align-middle">O</span>
                        <span className="align-middle">U</span>
                        <span className="align-middle">R</span>
                        <span className="align-middle">&nbsp;</span>    
                        <span className="align-middle">A</span>    
                        <span className="align-middle">U</span>    
                        <span className="align-middle">&nbsp;</span>    
                        <span className="align-middle">M</span>    
                        <span className="align-middle">E</span>    
                        <span className="align-middle">N</span>    
                        <span className="align-middle">U</span>    
                    </button>
                </Link>
            </div>
        </div>
    )
}