import React, { useEffect, useState, useContext } from 'react';
import { ThemeContext } from '../../index.js'
import Storage from "../../service/Storage";
import HistoriquePlayer from './HistoriquePlayer';
import { ProPlayerModel } from '../../model/ProPlayerModel';
import Swal from 'sweetalert2';
import Popup from '../Popup';
import reglage from '../../assets/img/UI/reglage.png';
import zedLoading from '../../assets/img/champion/loading/Zed_2.jpg';
import leeLoading from '../../assets/img/champion/loading/LeeSin_6.jpg';
import caboKc from '../../assets/img/UI/KcorpTheme/caboKc.jpg';
import jinxKc from '../../assets/img/UI/KcorpTheme/jinxKC.jpg'
import nationalityLogo from '../../assets/img/UI/la-navigation.png';
import identiteLogo from '../../assets/img/UI/identite.png';
import regionLogo from '../../assets/img/UI/lecLogo.png';
import masteriesLogo from '../../assets/img/UI/masteriesLol.png';
import laneIcon from '../../assets/img/UI/Middle_icon.png';
import trophyLogo from '../../assets/img/UI/trophyLogo.png';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FindTeamService from '../../service/FindTeamService';
import { makeStyles } from "@material-ui/core/styles";
import "../../css/findProPlayerCss.css";
import bookRules from '../../assets/img/runes/Inspiration/UnsealedSpellbook/UnsealedSpellbook.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import RecapPartieFindPlayer from './RecapPartieFindPlayer.jsx';

//CSS Pour les options du <AutoComplete>
const useStyles = makeStyles({
    paper: {
        
      border: "1px solid white",
      "background": "linear-gradient(20deg, #0B0126, #6a0139)",
      color: "white",
      "font-size": "1.5rem",
      "line-height": "1.75rem",
  }});

const useStylesKc = makeStyles({
paper: {
    border: "1px solid white",
    "background": "linear-gradient(342deg, rgba(29,37,61,1) 0%, rgba(16,23,33,1) 50%, rgba(29,37,61,1) 100%);",
    color: "white",
    "font-size": "1.5rem",
    "line-height": "1.75rem",
}});

export default function FindPlayer() {

    const [state] = useContext(ThemeContext);

    const [isDisabled, setIsDisabled] = useState(false);
    const [isDailyMode, setIsDailyMode] = useState(false);

    const classesBackground = useStyles();
    const classesBackgroundKc = useStylesKc();

    //Liste de tous les joueurs venant du backend
    const [proPlayer, setProPlayer] = useState([]);

    const [imgPlayerToFind, setImgPlayerToFind] = useState();
    const [squareList, setSquareList] = useState([]);

    //Si le joueur souhaite filtrer les regions, on les gardes dans une liste pour les réintégrer si besoin
    const [proPlayerToReintegrete, setProPlayerToReintegrete] = useState([]);
    //Permet de gérer le filtrage des données choisies par le joueur
    const [filter, setFilter] = useState({
        lflFilter: true,
        lecFilter: true,
        lcsFilter: true,
        lckFilter: true,
        lplFilter: true,
    });
    //Permet d'empêcher l'utilisateur de filtrer sur toutes les régions
    const [filterCount, setFilterCount] = useState(0);

    const [proPlayerName, setProPlayerName] = useState([]);
    //Au lancement de la partie, Récupération d'un joueur au hasard parmis la liste proPlayer
    const [proPlayerToFind, setProPlayerToFind] = useState();
    //Permet d'afficher l'historique des réponses du joueur
    const [histAnswer, setHistAnswer] = useState([]);

    const [countTry, setCountTry] = useState(0);
    //La réponse du joueur dans le formulaire
    const [formAnswer, setFormAnswer] = useState();
    //Récupération des logo des teams
    const [teamLogo, setTeamLogo] = useState({
        lflLogo: [],
        lecLogo: [],
        lcsLogo: [],
        lckLogo: [],
        lplLogo: [],
    });
    //Affichage ou non de la pop-up de réglage
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupRules, setShowPopupRules] = useState(false);

    const [isGameStarted, setIsGameStarted] = useState(false);
    const [playerLose, setPlayerLose] = useState(false);
    const [playerWin, setPlayerWin] = useState(false);
    const [isGameEnd, setIsGameEnd] = useState(false);

    useEffect(() => {
        const disabledButton = () => {
            if (Storage.getFindPlayer() !== null) {
                setIsDisabled(true);
            }
        }
        disabledButton();
    }, []);

    //Affiche ou non la pop-up pour les réglages
    const togglePopup = () => {
        setShowPopup(!showPopup);
      }

      //Affiche ou non la pop-up pour les réglages
    const togglePopupRules = () => {
        setShowPopupRules(!showPopupRules);
      }
    //const cheminImage = `../../assets/img/player/${proPlayerToFind.profile}`
    //Récupèration du json depuis le backend
   async function fetchData(){
    await fetch('https://back.quizzlol.fr/api/listPlayer')
    .then((res) => res.json())
    .then((data) => {
        setProPlayer(data);

    })
    .catch((err) => {
       //console.log(err.message);
    });
}

//Récupèration du json depuis le backend
async function fetchDataRandom(){
    await fetch('https://back.quizzlol.fr/api/randomPlayer')
    .then((res) => res.json())
    .then((data) => {
        setProPlayerToFind(data);
    })
    .catch((err) => {
       //(err.message);
    });
}

    const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
    })

    const blockFilter = () => {
        Toast.fire({
            icon: 'error',
            title: 'Vous ne pouvez pas filtrer sur toutes les régions !'
            })
    }

     //Récupération des logos des équipes pour l'ihm 
     useEffect(() => {
        fetchData(); 
        setTeamLogo(listLogo => {
            return {...listLogo, lflLogo: FindTeamService.getTeamLogo("LFL")}
        });
        setTeamLogo(listLogo => {
            return {...listLogo, lecLogo: FindTeamService.getTeamLogo("LEC")}
        });        
        setTeamLogo(listLogo => {
            return {...listLogo, lcsLogo: FindTeamService.getTeamLogo("LCS")}
        });
        setTeamLogo(listLogo => {
            return {...listLogo, lckLogo: FindTeamService.getTeamLogo("LCK")}
        });
        setTeamLogo(listLogo => {
            return {...listLogo, lplLogo: FindTeamService.getTeamLogo("LPL")}
        });        
      }, []);

   //On récupère une valeur parmis la liste de tous les proPlayer a trouver   
   function launchGamePlayer(){
        window.scrollTo(0, 0);
        
        setIsGameStarted(true);
        const randomPlayer = proPlayer[Math.floor(Math.random() * proPlayer.length)];
        setProPlayerToFind(randomPlayer);
        
        proPlayer.map((pro, key) => {
            return setProPlayerName(proPlayerName => [...proPlayerName, pro.name]);
        })
   }

   function launchGameRandomPlayer(){
    fetchDataRandom(); 
    Storage.setFindPlayer(proPlayer);
    Storage.initDailyTusmo();
    window.scrollTo(0, 0);
    setIsDailyMode(true);
    setIsGameStarted(true);
    //setImagePath(proPlayerToFind);
    proPlayer.map((pro, key) => {
        return setProPlayerName(proPlayerName => [...proPlayerName, pro.name]);
    })
   }


   useEffect(() => {
    if(proPlayerToFind != null){
        const importAll = (r) => {
            return r.keys().filter(element => element.replace('./', '') === proPlayerToFind.profile).map(r);
        };
        let fileJsonChamp = importAll(require.context('../../assets/img/UI/player/', true, /\.(webp)$/));
        setImgPlayerToFind(fileJsonChamp)
    }      
  }, [proPlayerToFind]);

function handleFilter(filtre) {
    if(filterCount<4){
        setFilterCount(count => count + 1);
    }
    if(filterCount<4){
        switch(filtre){
            case("lflFilter"):
                setFilter(filter => {
                    return {...filter, lflFilter: false}
                });
                filterListRemove("LFL")
                break;
            case("lecFilter"):
                setFilter(filter => {
                    return {...filter, lecFilter: false}
                });
                filterListRemove("LEC")
                break;
            case("lcsFilter"):
                setFilter(filter => {
                    return {...filter, lcsFilter: false}
                });
                filterListRemove("LCS")
                break;
            case("lckFilter"):
                setFilter(filter => {
                    return {...filter, lckFilter: false}
                });
                filterListRemove("LCK")
                break;
            case("lplFilter"):
                setFilter(filter => {
                    return {...filter, lplFilter: false}
                });
                filterListRemove("LPL")
                break;
            default:
                break;
        }
    }else{
        blockFilter();
    }
    
  }
 
  function filterListRemove(region){
    //On récupère dans une liste temporaire les joueurs supprimés lors des filtres
    const proPlayerDelete =  proPlayer.filter(player => player.region === region);
    //On enlève de la liste principal les joueurs de de la region concernée
    setProPlayer(pro => {return pro.filter(player => player.region !== region)});
    //On insère dans la liste des regions supprimé les joueurs concérnés
    setProPlayerToReintegrete([...proPlayerToReintegrete, ...proPlayerDelete]);
  }

  function handleRestoreFilter(filtre) {
    setFilterCount(count=> count - 1);
    switch(filtre){
        case("lflFilter"):
            setFilter(filter => {
                return {...filter, lflFilter: true}
            });
            filterListAdd("LFL")
            break;
        case("lecFilter"):
            setFilter(filter => {
                return {...filter, lecFilter: true}
            });
            filterListAdd("LEC")
            break;
        case("lcsFilter"):
            setFilter(filter => {
                return {...filter, lcsFilter: true}
            });
            filterListAdd("LCS")
            break;
        case("lckFilter"):
            setFilter(filter => {
                return {...filter, lckFilter: true}
            });
            filterListAdd("LCK")
            break;
        case("lplFilter"):
            setFilter(filter => {
                return {...filter, lplFilter: true}
            });
            filterListAdd("LPL")
            break;
        default:
            break;
    }
  }

  function filterListAdd(region){
    const proPlayerAdd =  proPlayerToReintegrete.filter(player => player.region === region);
    setProPlayer([...proPlayer, ...proPlayerAdd]);
  }

const Square = ({ color, text }) => {
    return (
      <div style={{
        width: "max",
        height: "38px",
        backgroundColor: color,
        border: "1px solid white",
        display: "inline-block",
        textAlign: "center",
        color:"white",
      }}>{text}</div>
    );
  };



    //Fonction appelée lors de l'envoi du formulaire
    function handleSubmit(event){
        event.preventDefault();
        const listItem = []
        if(formAnswer!== undefined){
            //On récupère l'object proPlayer suivant se qui est entrée dans le formulaire (grace a la liste des proPlayer)
            const tmp = proPlayer.filter(pro => { return pro.name===formAnswer })[0];
            setFormAnswer();
            //Création du model proPlayer pour la liste historique
            const importAll = (r) => {
                return r.keys().filter(element => element.replace('./', '') === tmp.profile).map(r);
            };
            let fileJsonChamp = importAll(require.context('../../assets/img/UI/player/', true, /\.(webp)$/));
            
            const proPlayerModel = new ProPlayerModel(tmp.id, tmp.name, fileJsonChamp, tmp.role, tmp.region, tmp.nationality, tmp.mainTrophy, tmp.mainChampion);
            if(proPlayerModel.name===proPlayerToFind.name){
                listItem.push(<Square color="green"/>)
                proPlayerModel.statusName = true;
                //if(isDailyMode) {Storage.setFindPlayer(proPlayer);}
                setPlayerWin(true);
                setIsGameEnd(true)
            }else{
                listItem.push(<Square color="red"/>)
            }
            if(proPlayerModel.role===proPlayerToFind.role){
                listItem.push(<Square color="green"/>)
                proPlayerModel.statusRole = true;
            }else{
                listItem.push(<Square color="red"/>)
            }
            if(proPlayerModel.region===proPlayerToFind.region){
                listItem.push(<Square color="green"/>)
                proPlayerModel.statusRegion = true;
            }else{
                listItem.push(<Square color="red"/>)
            }
            if(proPlayerModel.nationality===proPlayerToFind.nationality){
                listItem.push(<Square color="green"/>)
                proPlayerModel.statusNationality= true;
            }else{
                listItem.push(<Square color="red"/>)
            }
            if(proPlayerModel.mainTrophy===proPlayerToFind.mainTrophy){
                listItem.push(<Square color="green"/>)
                proPlayerModel.statusMainTrophy = true;
            }else{
                listItem.push(<Square color="red"/>)
            }
            if(proPlayerModel.mainChampion===proPlayerToFind.mainChampion){
                listItem.push(<Square color="green"/>)
                proPlayerModel.statusMainChampion = true;
            }else{
                listItem.push(<Square color="red"/>)
            }
            const tmpArray2 = histAnswer.slice();
            tmpArray2[countTry] = {proPlayerModel, isInsert: true};
            setSquareList(tmpArray=> [...tmpArray, listItem]);
            // setTempAnswer(preTempAnswer => proPlayerToFind.name === tempAnswer.name ?  !preTempAnswer.statusName : preTempAnswer.statusName);
            setHistAnswer(tmpArray2);
            setCountTry(preV=> preV + 1);
            if(countTry===6){
                if(isDailyMode) {Storage.setFindPlayer();}
                setPlayerLose(true);
                setIsGameEnd(true)
            }
        }
    }

    function handleChange(event, value){
        setFormAnswer(value);
    }

    const styles = {    
        "borderTopLeftRadius": "6px",
        "borderTopRightRadius": "6px",
        border: "1px solid white",
        "borderColor": "white",
        "maxWidth": "100%",
    };

    const hrColor = {
        "display": "block", "height": "1px",
        "border": 0,
        "borderTop": state.themes[state.theme].borderTop,
        "margin": "1em 0",
    }

    //Pour la couleur des bouttons
    let styleBackground;
    if (state.theme === "kcorp") {
        styleBackground = {
            "backgroundImage": state.themes[state.theme].backgroundButton,
            "backgroundSize": "contain"
        }
    }else{
        styleBackground = {
            "backgroundImage": "none"
        }
    };

    useEffect(()=>{
        const listItem = []
        for(let i = 0; i<7; i++){
            listItem.push({
                html: (<div className='grid grid-rows-1 w-full grid-cols-6 content-center border-black font-semibold text-2xl place-self-center'>
                            <Square color={"rgba(0, 0, 0, 0)"} text="-"/>
                            <Square color={"rgba(0, 0, 0, 0)"} text="-"/>
                            <Square color={"rgba(0, 0, 0, 0)"} text="-"/>
                            <Square color={"rgba(0, 0, 0, 0)"} text="-"/>
                            <Square color={"rgba(0, 0, 0, 0)"} text="-"/>
                            <Square color={"rgba(0, 0, 0, 0)"} text="-"/>
                        </div>
                ),
                           isInsert: false,
                           proPlayerModel: {
                           statusName: false,
                           stylesRegion:false,
                           stylesNationality:false,
                           stylesMainTrophy:false,
                           stylesMainChampion:false,  
                           }          
            })
        }
        //listItem.splice(0, histAnswer.length, ...histAnswer);
        setHistAnswer(listItem);
    }, [])

    return(
        <div className='py-8'>
            {
                !isGameStarted ?
                <div className=''>
                <p className={`flex justify-center mb-2 text-xl font-title ${state.themes[state.theme].textColorTitle} uppercase tracking-wider md:text-4xl`}>Trouve le joueur caché</p>
                <div className='px-16'>
                    <hr className='mb-2' style={hrColor}></hr>
                </div>  
                <div className='grid grid-rows-1 gap-20 md:grid-cols-9'>
                    <div className='grid place-self-center w-3/5 h-full transform transition duration-500 hover:scale-105 md:col-start-2 md:col-end-5'>
                        <div className="flex flex-col justify-around justify-items-center items-center rounded-xl bg-black absolute inset-0 opacity-0 hover:opacity-100 bg-opacity-90 duration-300">
                            <div className='flex flex-col items-center w-full px-4'>
                                <LazyLoadImage className='w-3/5' src={bookRules}></LazyLoadImage>
                                <div className='flex w-full rounded bg-red-400' style={styleBackground}>
                                    <button
                                        className={`button button--nina animate__animated animate__fadeIn
                                        py-0 px-2 w-full relative block rounded text-center text-xl uppercase focus:outline-none
                                        tracking-widest overflow-hidden ${state.themes[state.theme].hover__return__backgroundButton} text-white font-spegiel-bold md:text-xs lg:text-base xl:text-xl`}
                                        data-text="Règles du jeu"
                                        onClick={togglePopupRules}>
                                            
                                        <span className="align-middle">R</span>
                                        <span className="align-middle">E</span>
                                        <span className="align-middle">G</span>
                                        <span className="align-middle">L</span>
                                        <span className="align-middle">E</span>
                                        <span className="align-middle">S</span>
                                        <span className="align-middle">&nbsp;</span>
                                        <span className="align-middle">D</span>
                                        <span className="align-middle">U</span>
                                        <span className="align-middle">&nbsp;</span>
                                        <span className="align-middle">J</span>
                                        <span className="align-middle">E</span>
                                        <span className="align-middle">U</span>
                                    </button>
                                </div>
                            </div>
                            { isDisabled ?
                                                <p className='place-self-center font-spegiel text-base pb-2 text-white sm:text-xs md:text-xs lg:text-base xl:text-lg'>
                                                    Déjà réalisé pour aujourd'hui
                                                </p>
                                                :
                                                null
                                            }
                            { !isDisabled &&
                                <div className='w-full px-2'>
                                    <div className='flex w-full rounded bg-red-400' style={styleBackground}>
                                    <button
                                        className={`button px-5 py-2 w-full text-2xl text-center font-bold font-spegiel-bold text-white uppercase ${isDisabled ? '' : 'hover:text-white'} rounded relative block ${state.themes[state.theme].hover__return__backgroundButton} ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''} md:text-sm lg:text-base xl:text-xl`}
                                        data-text="Commencer"
                                        value='findDaily' disabled={isDisabled} onClick={() => launchGameRandomPlayer()}
                                        >Commencer
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='grid-rows-3 w-full'>
                            <div className='flex flex-col w-full'>
                                <h2 className={`flex justify-center text-lg font-card-title ${state.themes[state.theme].textColor} uppercase tracking-wider md:text-xs lg:text-sm xl:text-base 2xl:text-xl`}>Joueur du jour</h2>
                            </div>
                            <div className='px-6'>
                                <hr className='' style={hrColor}></hr>
                            </div>
                        </div>
                        <LazyLoadImage className={`place-self-center h-full w-full rounded-2xl object-contain ${state.themes[state.theme].shadowColor}`} src={state.theme === "kcorp" ? caboKc : zedLoading }></LazyLoadImage>
                    </div>

                    <div className='grid place-self-center h-full w-3/5 text-white font-text border-red-400 transform transition duration-500 hover:scale-105 md:col-start-6 md:col-end-9'>
                        <div className="flex flex-col justify-around justify-items-center items-center rounded-xl bg-black opacity-0 hover:opacity-100 bg-opacity-90 duration-300 absolute inset-0">
                            <div className='flex flex-col items-center w-full px-4 mb-10'>
                                <LazyLoadImage className='w-3/5' src={bookRules}></LazyLoadImage>
                                <div className='flex w-full rounded bg-red-400' style={styleBackground}>
                                    <button
                                        className={`button button--nina animate__animated animate__fadeIn
                                        py-0 w-full relative block rounded text-center text-xl uppercase focus:outline-none
                                        tracking-widest overflow-hidden ${state.themes[state.theme].hover__return__backgroundButton} text-white font-spegiel-bold font-bold md:text-xs lg:text-base xl:text-xl`}
                                        data-text="Règles du jeu"
                                        onClick={togglePopupRules}>
                                            
                                        <span className="align-middle">R</span>
                                        <span className="align-middle">E</span>
                                        <span className="align-middle">G</span>
                                        <span className="align-middle">L</span>
                                        <span className="align-middle">E</span>
                                        <span className="align-middle">S</span>
                                        <span className="align-middle">&nbsp;</span>
                                        <span className="align-middle">D</span>
                                        <span className="align-middle">U</span>
                                        <span className="align-middle">&nbsp;</span>
                                        <span className="align-middle">J</span>
                                        <span className="align-middle">E</span>
                                        <span className="align-middle">U</span>
                                    </button>
                                </div>
                            </div>
                            <div className='w-full px-2'>
                                <div className='flex w-full rounded bg-red-400' style={styleBackground}>
                                    <button
                                    className={`button py-0 w-11/12 rounded text-3xl text-center font-spegiel-bold text-white ${state.themes[state.theme].hover__return__backgroundButton} uppercase relative block md:py-2 md:text-sm lg:text-base xl:text-xl`}
                                    data-text="Commencer"
                                    value='findAllTime' onClick={(e) => launchGamePlayer(e.target.value)}
                                    >Commencer
                                    </button>
                                    <button className={`border-l border-black ${state.themes[state.theme].hover__return__backgroundButton} hover:rounded-r w-1/12`} onClick={togglePopup}><LazyLoadImage className='w-full' src={reglage}/></button> 
                                </div>
                            </div>     
                        </div>
                        <div className='grid-rows-3 w-full'>
                            <div className='flex flex-col w-full'>
                                <h2 className={`flex justify-center text-lg font-card-title ${state.themes[state.theme].textColor} uppercase tracking-wider md:text-xs lg:text-sm xl:text-base 2xl:text-xl`}>Trouve un joueur</h2>
                            </div>
                            <div className='px-6'>
                                <hr className='' style={hrColor}></hr>
                            </div>
                        </div>
                        <LazyLoadImage className={`place-self-center w-full rounded-2xl h-full ${state.themes[state.theme].shadowColor}`}src={state.theme === "kcorp" ? jinxKc : leeLoading}></LazyLoadImage>
                    </div>     
                </div>
                    {showPopupRules && <Popup
                                content={<>
                                    <p className='flex justify-center text-xl font-title text-white uppercase md:text-xl lg:text-2xl xl:text-4xl'>Règles du jeu</p>
                                    <dl className="w-full divide-y divide-gray-200 dark:text-white dark:divide-gray-400">
                                        <div className="flex flex-col pt-3 pb-2">
                                            <dt className="mb-1 text-base font-card-title text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl">Règles générales</dt>
                                            <div className='flex items-center'>
                                                <dd className="flex items-center text-white justify-between w-full text-sm font-spegiel-bold md:text-base lg:text-xl xl:text-2xl">
                                                 Tu vas devoir trouver le joueur professionnel caché.
                                                 Pour le trouver, tu peux t'aider de 5 informations primordiales !
                                                 Partage ensuite ton tableau de résultat avec tes amis !
                                                 Ci-dessous, les 5 caractéristiques qui pourront t'aider dans ta recherche.
                                                </dd>
                                            </div>
                                        </div>
                                        <div className="flex flex-col pt-3 pb-2">
                                            <dt className="mb-1 text-base font-card-title text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl">Nom du joueur</dt>
                                            <div className='flex items-center'>
                                                <dd className="flex items-center text-white justify-between w-full text-sm font-spegiel-bold md:text-base lg:text-xl xl:text-2xl">Le pseudo du joueur (si trouvé, tu gagnes)<LazyLoadImage className='w-1/12' src={identiteLogo}></LazyLoadImage></dd>
                                            </div>
                                        </div>
                                        <div className="flex flex-col pt-3 pb-2">
                                            <dt className="mb-1 text-base font-card-title  text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl">Le rôle</dt>
                                            <div className='flex items-center'>
                                                <dd className="flex items-center text-white justify-between w-full text-sm font-spegiel-bold md:text-base lg:text-xl xl:text-2xl">Son rôle sur la faille de l'invocateur<LazyLoadImage className='w-1/12' src={laneIcon}></LazyLoadImage></dd>
                                        </div>
                                        </div>
                                        <div className="flex flex-col pt-3 pb-2">
                                            <dt className="mb-1 text-base font-card-title text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl">Nationalité</dt>
                                            <div className='flex items-center'>
                                                <dd className="flex items-center text-white justify-between w-full text-sm font-spegiel-bold md:text-base lg:text-xl xl:text-2xl">Sa nationalité de naissance<LazyLoadImage className='w-1/12' src={nationalityLogo}></LazyLoadImage></dd>
                                            </div>        
                                        </div>
                                        <div className="flex flex-col pt-3 pb-2">
                                            <dt className="mb-1 text-base font-card-title text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl">Le trophée majeur</dt>
                                            <div className='flex items-center'>
                                                <dd className="flex items-center text-white justify-between font-spegiel-bold w-full text-sm md:text-base lg:text-xl xl:text-2xl">Le titre le plus préstigieux remporté dans sa carrière<LazyLoadImage className='justify-center w-1/12' src={trophyLogo}></LazyLoadImage> </dd>
                                            </div>
                                        </div>
                                        <div className="flex flex-col pt-3 pb-2">
                                            <dt className="mb-1 text-base font-card-title text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl">Le main</dt>
                                            <div className='flex items-center'>
                                                <dd className="flex items-center text-white justify-between font-spegiel-bold w-full text-sm md:text-base lg:text-xl xl:text-2xl">Son champion le plus joué en carrière profesionnelle<LazyLoadImage className='w-1/12' src={masteriesLogo}/></dd>
                                            </div>
                                        </div>
                                    </dl>
                                </>}
                                handleClose={togglePopupRules}
                            />}
                    {showPopup && <Popup
                        content={<>
                            <div className='grid grid-rows-2 grid-cols-1'>
                                <h1 className='flex justify-center font-title text-white text-base md:text-2xl lg:text-3xl xl:text-4xl'>Réglage de la partie</h1>
                                <hr class="solid"/>                                        
                                <h2 className='text-white font-card-title text-base mb-4 md:text-base lg:text-xl xl:text-2xl'>Filtrer sur les régions</h2>
                                <p className='text-white font-spegiel-bold text-sm mb-4 md:text-base lg:text-base xl:text-xl'>En cliquant sur les régions ci-dessous, tu peux choisir de les enlever de la partie</p>
                                <div className='grid grid-rows-1 grid-cols-5'>
                                    {filter.lflFilter && <button className='border border-blue-200 gap-1 bg-slate-400 hover:bg-red-400 font-texxt' value='lflFilter' onClick={(e) => handleFilter(e.target.value)}>LFL</button>}
                                    {filter.lecFilter && <button className='border border-blue-200 bg-slate-400 hover:bg-red-400 font-texxt' value='lecFilter' onClick={(e) => handleFilter(e.target.value)}>LEC</button>}
                                    {filter.lcsFilter && <button className='border border-blue-200 bg-slate-400 hover:bg-red-400 font-texxt' value='lcsFilter' onClick={(e) => handleFilter(e.target.value)}>LCS</button>}
                                    {filter.lckFilter && <button className='border border-blue-200 gap-1 bg-slate-400 hover:bg-red-400 font-texxt' value='lckFilter' onClick={(e) => handleFilter(e.target.value)}>LCK</button>}
                                    {filter.lplFilter && <button className='border border-blue-200 bg-slate-400 hover:bg-red-400 font-texxt' value='lplFilter' onClick={(e) => handleFilter(e.target.value)}>LPL</button>}
                                </div>
                                <br></br>
                                {!filter.lflFilter || !filter.lecFilter  || !filter.lcsFilter || !filter.lckFilter || !filter.lplFilter ? <div>
                                <hr class="solid"/>
                                <p className='text-white font-spegiel-bold text-sm mb-4 md:text-base lg:text-base xl:text-xl'>Clique sur la region que tu souhaites afin de la remettre en jeu !</p></div> : <></>}
                                <div className='grid grid-rows-1 grid-cols-5 pb-4'>
                                    {!filter.lflFilter && <button className='border border-blue-200 gap-1 bg-slate-400 hover:bg-green-400 font-texxt' value='lflFilter' onClick={(e) => handleRestoreFilter(e.target.value)}>LFL</button>}
                                    {!filter.lecFilter && <button className='border border-blue-200 gap-1 bg-slate-400 hover:bg-green-400 font-texxt' value='lecFilter' onClick={(e) => handleRestoreFilter(e.target.value)}>LEC</button>}
                                    {!filter.lcsFilter && <button className='border border-blue-200 gap-1 bg-slate-400 hover:bg-green-400 font-texxt' value='lcsFilter' onClick={(e) => handleRestoreFilter(e.target.value)}>LCS</button>}
                                    {!filter.lckFilter && <button className='border border-blue-200 gap-1 bg-slate-400 hover:bg-green-400 font-texxt' value='lckFilter' onClick={(e) => handleRestoreFilter(e.target.value)}>LCK</button>}
                                    {!filter.lplFilter && <button className='border border-blue-200 gap-1 bg-slate-400 hover:bg-green-400 font-texxt' value='lplFilter' onClick={(e) => handleRestoreFilter(e.target.value)}>LPL</button>}
                                </div>
                            </div>
                        </>}
                        handleClose={togglePopup}
                    />}
                    </div>
                    :
                        !playerWin && !playerLose?
                                <div className=''>
                                <div className='grid grid-rows-4 grid-cols-1'>  
                                    <p className={`flex justify-center text-xl h-6/12 font-title ${state.themes[state.theme].textColorTitle} uppercase tracking-wider sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl`}>Recherche le joueur caché</p>
                                    <div className='px-16 w-full'>
                                        <hr className='mb-2' style={hrColor}></hr>
                                    </div>
                                    <form onSubmit={handleSubmit} className={`text-white row-span-3 justify-center place-self-center mb-5 w-6/12 flex flex-col bg-gradient-to-r ${state.themes[state.theme].autocomplete__backgroung__stat__gradiant} background-animate`}>
                                            <Autocomplete
                                                name="answer"
                                                id="proPlayerOptions"
                                                onChange={handleChange}
                                                getOptionLabel={(proPlayerName) => proPlayerName}
                                                options={proPlayerName}
                                                style={styles}
                                                value={null}
                                                className='autocomplete-input'
                                                classes={{ paper: state.theme === "kcorp" ? classesBackgroundKc.paper : classesBackground.paper}}
                                                renderInput={(params) => <TextField {...params} label="Selectionne un joueur"                              
                                                />}
                                            />
                                        <button
                                            className={`button button--nina px-5 py-0 border ${state.themes[state.theme].hover__returnToMenuOrRestart__backgroundButton} ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} text-white relative block focus:outline-none text-sm text-center font-semibold uppercase tracking-widest overflow-hidden w-full`}
                                            type='submit'
                                            data-text="Soumettre"
                                        >
                                            <span className="align-middle">S</span>
                                            <span className="align-middle">O</span>
                                            <span className="align-middle">U</span>
                                            <span className="align-middle">M</span>
                                            <span className="align-middle">E</span>
                                            <span className="align-middle">T</span>
                                            <span className="align-middle">T</span>
                                            <span className="align-middle">R</span>
                                            <span className="align-middle">E</span>
                                        </button>    
                                    </form>
                                </div>
                                <div className=''>
                                    <div className='grid grid-rows-1 grid-cols-6 w-full'>
                                        <div className={`grid border border-solid border-black bg-gradient-to-r ${state.themes[state.theme].background_findPlayer__gradiant} background-animate`}>
                                            <div className='grid border-b-2 w-full'>
                                                <p className='text-white text-xs place-self-center font-texxt md:text-base lg:text-xl 2xl:text-2xl'>Nom</p>
                                            </div>
                                            <LazyLoadImage className='place-self-center w-5/12' src={identiteLogo}></LazyLoadImage>
                                        </div>
                                        <div className={`grid border border-solid border-black bg-gradient-to-r ${state.themes[state.theme].background_findPlayer__gradiant} background-animate`}>
                                            <div className='grid border-b-2'>
                                                <p className='text-white text-xs place-self-center font-texxt md:text-base lg:text-xl 2xl:text-2xl'>Rôle</p>
                                            </div>
                                            <LazyLoadImage className='place-self-center w-5/12' src={laneIcon}></LazyLoadImage>
                                        </div>
                                        <div className={`grid border border-solid border-black bg-gradient-to-r ${state.themes[state.theme].background_findPlayer__gradiant} background-animate`}>
                                            <div className='grid border-b-2'>
                                                <p className='text-white text-xs place-self-center font-texxt md:text-base lg:text-xl 2xl:text-2xl'>Région</p>
                                            </div>    
                                            <LazyLoadImage className='place-self-center w-5/12' src={regionLogo}></LazyLoadImage>
                                        </div>
                                        <div className={`grid border border-solid border-black bg-gradient-to-r ${state.themes[state.theme].background_findPlayer__gradiant} background-animate`}>
                                            <div className='grid border-b-2'>
                                                <p className='text-white text-xs place-self-center font-texxt md:text-base lg:text-xl 2xl:text-2xl'>Nationalité</p>
                                            </div>
                                            <LazyLoadImage className='place-self-center w-5/12' src={nationalityLogo}></LazyLoadImage>
                                        </div>
                                        <div className={`grid border border-solid border-black bg-gradient-to-r ${state.themes[state.theme].background_findPlayer__gradiant} background-animate`}>
                                            <div className='grid border-b-2 w-full'>
                                                <p className='text-white text-xs place-self-center font-texxt md:text-base lg:text-xl 2xl:text-2xl'>Trophé majeur</p>
                                            </div>
                                            <LazyLoadImage className='place-self-center w-5/12' src={trophyLogo}></LazyLoadImage>
                                        </div>
                                        <div className={`grid border border-solid border-black bg-gradient-to-r ${state.themes[state.theme].background_findPlayer__gradiant} background-animate`}>
                                            <div className='grid border-b-2 w-full'>
                                                <p className='text-white text-xs place-self-center font-texxt md:text-base lg:text-xl 2xl:text-2xl'>Main</p>
                                            </div>
                                            <LazyLoadImage className='place-self-center w-5/12' src={masteriesLogo}></LazyLoadImage>
                                        </div>
                                    </div>
                                        {histAnswer.map((hist, key)=>{
                                                return <HistoriquePlayer key={key} histPlayer={hist} isGameEnd={isGameEnd} />
                                            })}  
                                    <div className='grid grid-rows-1 grid-cols-1 place-items-center mt-4 mb-4'>
                                        <div className={`shadow-2xl border-b border-blue-200 shadow-blue-200/50 rounded-lg text-white px-2 py-4 bg-gradient-to-r ${state.themes[state.theme].background_pannel__stat__gradiant} background-animate`}>
                                            <p className='text-base font-spegiel md:text-xl lg:text-2xl xl:text-3xl'>Nombre de tentative(s)</p>
                                            <h2 className='flex justify-center font-texxt text-2xl font-black font-number md:text-2xl lg:text-3xl xl:text-4xl'>{countTry}</h2>
                                        </div>
                                    </div>     
                                </div>
                            </div> 
                        :
                            <RecapPartieFindPlayer squareList={squareList} playerToFind={proPlayerToFind} countTry={countTry} histAnswer={histAnswer} playerWin={playerWin} playerLose={playerLose} isGameEnd={isGameEnd} imgPlayerToFind={imgPlayerToFind} isDailyMode={isDailyMode}/>            
                       }
            <div className='px-16 mt-12'>
                    <hr className='mb-2' style={hrColor}></hr>
            </div> 
            <p className={`font-test grid place-items-center my-4 text-sm font-spegiel-bold ${state.themes[state.theme].textColorTitle} md:text-base lg:text-xl xl:text-2xl`}>Les joueurs de ces équipes sont renseignés : Données du Spring 2023</p>
            <div className='grid grid-rows-4 grid-cols-1  sm:px-40'>
                    <div className='grid grid-rows-1 grid-cols-10 w-full h-full place-items-center text-white'>
                        <div className={`border w-full h-full flex items-center place-content-center shadow-xl shadow-blue-200/50 col-start-4 bg-gradient-to-r ${state.themes[state.theme].background_pannel_gradiant} background-animate `}>
                            <p className={`font-text text-base sm:text-base md:text-xl lg:text-2xl xl:text-3xl`}>LCS</p>
                        </div>                            
                        {teamLogo.lcsLogo.map((logo, key)=>{
                                return <div key={key} className={`flex items-center place-content-center border w-full h-full shadow-xl shadow-blue-200/50 bg-gradient-to-r ${state.themes[state.theme].background_pannel_gradiant} background-animate`}><LazyLoadImage src={logo} key={key} className='w-9/12 sm:w-9/12'></LazyLoadImage></div>
                            })}
                    </div> 
                    <div className='grid w-full h-full grid-rows-1 grid-cols-11 place-items-center border-blue-200 text-white'>
                        <div className={`border w-full h-full flex items-center place-content-center shadow-xl shadow-blue-200/50 bg-gradient-to-r ${state.themes[state.theme].background_pannel_gradiant} background-animate`}>
                            <p className={`font-text text-base sm:text-base md:text-xl lg:text-2xl xl:text-3xl`}>LFL</p>
                        </div>
                        {teamLogo.lflLogo.map((logo, key)=>{
                            return <div key={key} className={`flex items-center place-content-center border w-full h-full shadow-xl shadow-blue-200/50 bg-gradient-to-r ${state.themes[state.theme].background_pannel_gradiant} background-animate`}> <LazyLoadImage src={logo} key={key} className='w-9/12 sm:w-9/12'></LazyLoadImage></div>
                        })}
                    </div>
                    <div className='grid w-full h-full place-items-center grid-rows-1 grid-cols-11 text-white'>
                        <div className={`border w-full h-full flex items-center place-content-center shadow-xl shadow-blue-200/50 bg-gradient-to-r ${state.themes[state.theme].background_pannel_gradiant} background-animate`}>
                            <p className={`font-text text-base sm:text-base md:text-xl lg:text-2xl xl:text-3xl`}>LEC</p>
                        </div>                        
                        {teamLogo.lecLogo.map((logo, key)=>{
                            return <div key={key} className={`flex items-center place-content-center border w-full h-full shadow-xl shadow-blue-200/50 bg-gradient-to-r ${state.themes[state.theme].background_pannel_gradiant} background-animate`}><LazyLoadImage src={logo} key={key} className='w-9/12 sm:w-9/12'></LazyLoadImage></div>
                        })}
                    </div>
                    <div className='grid grid-cols-2'>
                        <div className='grid grid-row-1 grid-cols-6 w-full h-full place-items-center text-white'>                          
                            <div className={`border w-full h-full flex items-center place-content-center shadow-xl shadow-blue-200/50 col-start-2 bg-gradient-to-r ${state.themes[state.theme].background_pannel_gradiant} background-animate`}>
                                <p className={`font-text text-base sm:text-base md:text-xl lg:text-2xl xl:text-3xl`}>LCK</p>
                            </div> 
                            {teamLogo.lckLogo.map((logo, key)=>{
                                    return <div key={key} className={`flex items-center place-content-center border w-full h-full shadow-xl shadow-blue-200/50 bg-gradient-to-r ${state.themes[state.theme].background_pannel_gradiant} background-animate`}><LazyLoadImage src={logo} key={key} className='w-9/12 sm:w-9/12'></LazyLoadImage></div>
                                })}
                        </div>  
                        <div className='grid grid-row-1 grid-cols-6 w-full h-full place-items-center text-white'>                          
                            {teamLogo.lplLogo.map((logo, key)=>{
                                    return <div key={key} className={`flex items-center place-content-center border w-full h-full shadow-xl shadow-blue-200/50 bg-gradient-to-r ${state.themes[state.theme].background_pannel_gradiant} background-animate`}><LazyLoadImage src={logo} key={key} className='w-9/12 sm:w-9/12'></LazyLoadImage></div>
                                })}
                            <div className={`border w-full h-full flex items-center place-content-center shadow-xl shadow-blue-200/50 bg-gradient-to-r ${state.themes[state.theme].background_pannel_gradiant} background-animate`}>
                                <p className='font-text text-base sm:text-base md:text-xl lg:text-2xl xl:text-3xl'>LPL</p>
                            </div> 
                        </div> 
                    </div>
                    
                </div>    
        </div>
    )
}