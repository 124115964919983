import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from '../index.js'
import { Link } from 'react-router-dom';
import kamelKc from '../assets/img/UI/KcorpTheme/Emotes/kamelKc.png';
import twistedFate from '../assets/img/champion/loading/TwistedFate_7.jpg';
import twistedFateKC from '../assets/img/champion/loading/TwistedFate_23.jpg';
import ryze from '../assets/img/champion/loading/Ryze_5.jpg';
import ryzeKC from '../assets/img/champion/loading/Ryze_10.jpg';
import ekko from '../assets/img/champion/loading/Ekko_19.jpg';
import ekkoKC from '../assets/img/champion/loading/Ekko_28.jpg';
import yone from '../assets/img/champion/loading/Yone_10.jpg';
import yoneKC from '../assets/img/champion/loading/Yone_1.jpg';
import teemo from '../assets/img/UI/teemo.webp';
import kcxHome from '../assets/img/UI/KcorpTheme/kcxHome.jpeg';
import emoteOkay from '../assets/img/emotes/Okay_Emote.png';
import kamelPot from '../assets/img/UI/KcorpTheme/Emotes/kamelPot.png';
import emoteRammus from '../assets/img/emotes/ramus_emote.png';
import kamelFlag from '../assets/img/UI/KcorpTheme/Emotes/kamelFlag.png';
import emoteSylas from '../assets/img/emotes/sylas.webp';
import kamelUltra from '../assets/img/UI/KcorpTheme/Emotes/kamelUltra.png';
import emoteYasuo from '../assets/img/emotes/yasuo.webp';
import kamelFumi from '../assets/img/UI/KcorpTheme/Emotes/kamelFumi.png';
import 'animate.css';






// Classe de menu
export default function Home() {

    const [state, setState] = useContext(ThemeContext);
    const [randomRoute, setRandomRoute] = useState('/pannelChamp');
    const routes = ['pannelChamp', '/findPlayer', '/daily'];


    let styleBackground;
    if (state.theme === "kcorp") {
        styleBackground = {
            "backgroundSize": "contain",
            "backgroundImage": state.themes[state.theme].backgroundButton,
        }
    }else{
        styleBackground = {
            "backgroundImage": "none"
        }
    };

      useEffect(() => {
        setRandomRoute(routes[Math.floor(Math.random() * routes.length)]);
      }, []);

    return (
        <div>
            {state.theme === "kcorp" ?
            <div className="grid grid-cols-2 gap-8 w-full border-b border-[#01d1ff] sm:mt-2" >
                <p className="text-white text-xs md:text-base place-self-center font-title justify-self-end">Vidéo cadeau pour les ultras</p>
          
                <div className={`grid rounded self-center  sm:w-4/12 w-8/12 sm:mb-2 ${state.themes[state.theme].hover__return__backgroundButton}`} style={styleBackground}>
                    <a className={` font-card-title text-sm sm:text-base text-white place-self-center `} href="https://www.youtube.com/watch?v=u4AuYxNCNpk" target="_blank"> Lien ici</a>
                </div>
            </div>
            :
            null
            }
            <div className='grid grid-rows-3 grid-cols-1 md:grid-rows-1 md:grid-cols-7 text-white md:pb-4'>
                <div className='row-span-1 md:col-span-4 md:pb-24 md:pl-5 md:place-self-center'>
                    <div className={`mx-auto max-w-xs pt-6 md:max-w-none`}>
                        <div className="flex">
                            <h1 className='text-xl font-card-title font-bold place-self-center animate__animated animate__fadeIn md:text-xl lg:text-2xl xl:text-4xl'>QuizzLol</h1>
                            {state.theme === "kcorp" && <img alt="KcLogo" className="place-self-center w-2/12" src={kamelKc} />}
                        </div>
                        <p className={`font-spegiel text-base tracking-wide ${state.themes[state.theme].textColor} mt-6 animate__animated animate__fadeIn md:text-lg lg:text-xl`}>Test tes compétences sur League of Legends, le jeu de RiotGames !</p>
                        <div className='mt-6 w-full md:w-3/4'>
                            <div className='flex rounded w-4/5 bg-red-400' style={styleBackground}>
                                <Link to={randomRoute} className='grid place-self-center w-full'>
                                    <button type="button"
                                    className={`button button--nina animate__animated animate__fadeIn
                                    w-full relative block focus:outline-none font-spegiel-bold text-center rounded text-xs uppercase
                                    tracking-widest overflow-hidden ${state.themes[state.theme].hover__return__backgroundButton} text-white font-bold py-0 md:text-md lg:text-xl`}
                                            data-text="Jouer dès maintenant">
                                        <span className="align-middle">J</span>
                                        <span className="align-middle">O</span>
                                        <span className="align-middle">U</span>
                                        <span className="align-middle">E</span>
                                        <span className="align-middle">R</span>
                                        <span className="align-middle">&nbsp;</span>
                                        <span className="align-middle">D</span>
                                        <span className="align-middle">È</span>
                                        <span className="align-middle">S</span>
                                        <span className="align-middle">&nbsp;</span>
                                        <span className="align-middle">M</span>
                                        <span className="align-middle">A</span>
                                        <span className="align-middle">I</span>
                                        <span className="align-middle">N</span>
                                        <span className="align-middle">T</span>
                                        <span className="align-middle">E</span>
                                        <span className="align-middle">N</span>
                                        <span className="align-middle">A</span>
                                        <span className="align-middle">N</span>
                                        <span className="align-middle">T</span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className='mt-12'>
                            <img alt="imgMenu" className={`animate__animated animate__fadeInLeft w-full border-l border-t ${state.themes[state.theme].borderColor} rounded`} src={state.theme === "kcorp" ? kcxHome : teemo}></img>
                        </div>
                    </div>
                </div>
                <div className='md:col-span-3 px-5 pt-5 row-span-2'>
                    <div className='grid grid-cols-6 gap-2 md:gap-8 md:w-9/12 mx-auto max-w-7xl'>
                        <p className={`col-span-6 text-sm font-spegiel-bold  ${state.themes[state.theme].textColor} text-bold sm:text-lg lg:text-xl`}>SELECT GAMES</p>
                        <div className={`animate__animated animate__fadeInTopLeft col-span-3 border-r-2 border-b-2 ${state.themes[state.theme].borderColor} rounded-xl relative pb-4 mb-4`}>
                            <div className="absolute px-1 inset-0 z-10 bg-black rounded-xl flex flex-col items-center justify-between opacity-0 hover:opacity-100 bg-opacity-90 duration-300 pb-20">
                                <img alt='Emote Blitz' className={`${state.themes[state.theme].weight__emote}`} src={state.theme === "kcorp" ? kamelPot : emoteOkay} />
                                <div className='flex rounded w-10/12 bg-red-400' style={styleBackground}>
                                    <Link to="/daily" className='grid place-self-center w-full'>
                                        <button
                                            className={`button button--nina py-0 ${state.themes[state.theme].hover__return__backgroundButton} text-white relative block focus:outline-none rounded text-xs font-spegiel-bold  text-center
                                            uppercase tracking-widest overflow-hidden w-full md:text-sm lg:text-base`}
                                            data-text="Jouer"
                                        >
                                            <span className="align-middle">J</span>
                                            <span className="align-middle">O</span>
                                            <span className="align-middle">U</span>
                                            <span className="align-middle">E</span>
                                            <span className="align-middle">R</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <img alt="skinTf" className='rounded-2xl w-full place-items-center hover:opacity-50' src={state.theme === "kcorp" ? twistedFateKC : twistedFate}></img>
                            <h3 className={`text-xs font-card-title ${state.themes[state.theme].textColor} font-bold py-4 md:text-sm lg:text-base xl:text-xl`}>Quizz</h3>
                            <p className='text-xs font-spegiel-bold md:text-sm lg:text-base'>Entraine tes connaissances sur les champions et les items du jeu</p>
                        </div>
                        <div className={`animate__animated animate__fadeInTopRight col-span-3 border-r-2 border-b-2 ${state.themes[state.theme].borderColor} rounded-xl relative pb-6 mt-4`}>
                            <div className="absolute px-1 inset-0 z-10 bg-black rounded-xl flex flex-col items-center justify-between opacity-0 hover:opacity-100 bg-opacity-90 duration-300 pb-20">
                                <img alt='Emote2' className={`${state.themes[state.theme].weight__emote}`} src={state.theme === "kcorp" ? kamelFlag : emoteRammus} />
                                <div className='flex rounded w-10/12 bg-red-400' style={styleBackground}>
                                    <Link to="/findPlayer" className='grid place-self-center w-full'>
                                        <button
                                            className={`button button--nina py-0 ${state.themes[state.theme].hover__return__backgroundButton} text-white relative block focus:outline-none rounded text-xs font-spegiel-bold  text-center
                                            uppercase tracking-widest overflow-hidden w-full md:text-sm lg:text-base`}
                                            data-text="Jouer"
                                        >
                                            <span className="align-middle">J</span>
                                            <span className="align-middle">O</span>
                                            <span className="align-middle">U</span>
                                            <span className="align-middle">E</span>
                                            <span className="align-middle">R</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <img alt="skinRyze" className='rounded-2xl w-full' src={state.theme === "kcorp" ? ryzeKC : ryze}></img>
                            <h3 className={`text-xs ${state.themes[state.theme].textColor} font-bold font-card-title py-4 md:text-sm lg:text-base xl:text-xl`}>Tusmo Pro</h3>
                            <p className='text-xs font-spegiel-bold md:text-sm lg:text-base'>Trouve le joueur professionnel caché</p>
                        </div>
                        <div className={`animate__animated animate__fadeInBottomLeft col-span-3 border-r-2 border-b-2 ${state.themes[state.theme].borderColor} rounded-xl relative mb-5`}>
                            <div className="absolute px-1 inset-0 z-10 bg-black rounded-xl flex flex-col items-center justify-between opacity-0 hover:opacity-100 bg-opacity-90 duration-300 pb-20">
                                <img alt='Emote3' className={`${state.themes[state.theme].weight__emote}`} src={state.theme === "kcorp" ? kamelUltra : emoteYasuo} />
                                <div className='flex rounded w-10/12 bg-red-400' style={styleBackground}>
                                    <Link to="/pannelChamp" className='grid place-self-center w-full'>
                                        <button
                                            className={`button button--nina py-0 ${state.themes[state.theme].hover__return__backgroundButton} text-white relative block focus:outline-none
                                            rounded text-xs text-center font-spegiel-bold uppercase tracking-widest overflow-hidden w-full md:text-sm lg:text-base xl:text-xl`}
                                            data-text="Jouer"
                                        >
                                            <span className="align-middle">J</span>
                                            <span className="align-middle">O</span>
                                            <span className="align-middle">U</span>
                                            <span className="align-middle">E</span>
                                            <span className="align-middle">R</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <img alt="skinYone" className='rounded-2xl w-full' src={state.theme === "kcorp" ? yoneKC : yone}></img>
                            <h3 className={`text-xs ${state.themes[state.theme].textColor} font-bold font-card-title py-4 md:text-sm lg:text-base xl:text-xl`}>Panel Champion</h3>
                            <p className='text-xs font-spegiel-bold md:text-sm lg:text-base'>Trouve un maximum de champions</p>
                        </div>
                        <div className={`animate__animated animate__fadeInBottomRight col-span-3 border-r-2 border-b-2 ${state.themes[state.theme].borderColor} rounded-xl relative pb-6 mt-4`}>
                            <div className="absolute px-1 inset-0 z-10 bg-black rounded-xl flex flex-col items-center justify-between opacity-0 hover:opacity-100 bg-opacity-90 duration-300 pb-20">
                                <img alt='Emote4' className={`${state.themes[state.theme].weight__emote}`} src={state.theme === "kcorp" ? kamelFumi : emoteSylas} />
                                <div className='flex rounded w-10/12 bg-red-400' style={styleBackground}>
                                    <Link to="/" className='grid place-self-center w-full'>
                                        <button
                                            className={`button button--nina py-0 ${state.themes[state.theme].hover__return__backgroundButton} text-white relative block focus:outline-none rounded
                                            text-xs font-spegiel-bold text-center uppercase tracking-widest overflow-hidden w-full opacity-50 cursor-not-allowed md:text-sm lg:text-base xl:text-xl`}
                                            data-text="Jouer" disabled={true}
                                        >
                                            <span className="align-middle">J</span>
                                            <span className="align-middle">O</span>
                                            <span className="align-middle">U</span>
                                            <span className="align-middle">E</span>
                                            <span className="align-middle">R</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <img alt="skinEkko" className='rounded-2xl w-full' src={state.theme === "kcorp" ? ekkoKC : ekko}></img>
                            <h3 className={`text-xs ${state.themes[state.theme].textColor} font-card-title font-bold py-4 md:text-sm lg:text-base xl:text-xl`}>Mode multijoueur</h3>
                            <p className='text-xs font-spegiel-bold md:text-sm lg:text-base'>Arrive bientôt</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}