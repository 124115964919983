export class Question {

    constructor(id, question, type, difficulty) {
        this.id = id;
        this.question = question;
        this.type = type;
        this.difficulty = difficulty;
        this.indication = "";
        this.filename = null;
        this.reponse = null;
        this.image = null;
        this.propositions = [];
    }
}