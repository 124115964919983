export class ProPlayerModel {
    constructor(id, name, img, role, region, nationality, mainTrophy, mainChampion, urlClip, statusName, statusRole, statusRegion, statusNationality, statusMainTrophy, statusMainChampion) {
        this.id = id;
        this.name = name;
        this.img = img
        this.role = role;
        this.region = region;
        this.nationality = nationality;
        this.mainTrophy = mainTrophy;
        this.mainChampion = mainChampion;
        this.urlClip = urlClip;
        this.statusName = false;
        this.statusRole= false;
        this.statusRegion= false;
        this.statusNationality= false;
        this.statusMainTrophy= false;
        this.statusMainChampion= false;
    }
}