import React, { useState, createRef, useContext } from 'react';
import {Link} from "react-router-dom";
import { ThemeContext } from '../index.js'
import { CircularProgressbar , buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Constante from '../assets/const/fr/Constante.json'
import Swal from 'sweetalert2';
import ReactDOMServer from 'react-dom/server';
import {TwitterIcon, TwitterShareButton} from "react-share";
import { useScreenshot } from "use-react-screenshot";
import {FaCamera} from "react-icons/fa";
import Popup from "./Popup";

export default function RecapPartie(props) {

    const [state, setState] = useContext(ThemeContext);

    const renderHTML = (escapedHTML) => React.createElement("h3", { dangerouslySetInnerHTML: { __html: escapedHTML } });

    const colorCircleArray = [
    buildStyles({
        textColor: "#FFB6C1",
        pathColor: "#FFB6C1",
        trailColor: "#6D809B"
    }),
    buildStyles({
        textColor: "#ADD8E6",
        pathColor: "#ADD8E6",
        trailColor: "#6D809B"
    }),
    buildStyles({
        textColor: "#F0E68C",
        pathColor: "#F0E68C",
        trailColor: "#6D809B"
    }),
    buildStyles({
        textColor: "#E0FFFF",
        pathColor: "#E0FFFF",
        trailColor: "#6D809B"
    }),
    buildStyles({
        textColor: "#87CEEB",
        pathColor: "#87CEEB",
        trailColor: "#6D809B"
    }),
    buildStyles({
        textColor: "#00BFFF",
        pathColor: "#00BFFF",
        trailColor: "#6D809B"
    }),
    buildStyles({
    textColor: "#00FFFF",
    pathColor: "#00FFFF",
    trailColor: "#6D809B"
    }),
    buildStyles({
        textColor: "#7FFFD4",
        pathColor: "#7FFFD4",
        trailColor: "#6D809B"
    }),
    buildStyles({
        textColor: "#66CDAA",
        pathColor: "#66CDAA",
        trailColor: "#6D809B"
    }),
    buildStyles({
        textColor: "#00FA9A",
        pathColor: "#00FA9A",
        trailColor: "#6D809B"
    }),
    buildStyles({
        textColor: "#20B2AA",
        pathColor: "#20B2AA",
        trailColor: "#6D809B"
    }),
    buildStyles({
        textColor: "#98FB98",
        pathColor: "#98FB98",
        trailColor: "#6D809B"
    }),
    buildStyles({
        textColor: "#90EE90",
        pathColor: "#90EE90",
        trailColor: "#6D809B"
    }),
    buildStyles({
        textColor: "#00FF7F",
        pathColor: "#00FF7F",
        trailColor: "#6D809B"
    }),
    buildStyles({
        textColor: "#3CB371",
        pathColor: "#3CB371",
        trailColor: "#6D809B"
    })
    ];

    const ref = createRef(null);
    const [image, takeScreenShot] = useScreenshot();
    const [showPopupScreen, setShowPopupScreen] = useState(false);
    const getImage = () => takeScreenShot(ref.current);

    // Affiche ou non la pop-up pour voir le screenshot
    const togglePopupScreenshot = () => {
        setShowPopupScreen(!showPopupScreen);
        getImage();
    }

    const showStats = (d) => {
        let table = (
            <div className='col-span-1 w-full h-64 shadow-2xl overflow-y-scroll'>
                <table className='table-fixed border-collapse w-full border border-slate-400'>
                    <thead className='bg-slate-50 dark:bg-slate-700'>
                        <tr>
                            <td className='w-1/2 border border-slate-300 dark:border-slate-600 font-semibold p-4 text-slate-900 dark:text-slate-200 text-left'>Statistique</td>
                            <td className='w-1/2 border border-slate-300 dark:border-slate-600 font-semibold p-4 text-slate-900 dark:text-slate-200 text-left'>Valeur</td>
                        </tr>
                    </thead>
                    <tbody>
                    {Object.entries(d.description).map(([key, value]) => {
                        return (

                            <tr key={key} className='bg-slate-800 text-white'>
                                <td className='border border-slate-300 dark:border-slate-700 p-4'>{key}</td>
                                <td className='border border-slate-300 dark:border-slate-700 p-4'>{value}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );

        table = ReactDOMServer.renderToStaticMarkup(table);  

        Swal.fire({
            title: d.reponseCorrect,
            html : table,
            timer: null,
            customClass: {
                container: 'bg-slate-800',
                popup: 'bg-slate-800'
            },
            showCloseButton: true,
            showConfirmButton: false
        })
    };

    const showDAte = (d) => {
        let table = (
            <div className='col-span-1 w-full h-64 shadow-2xl overflow-y-scroll'>
                <table className='table-fixed border-collapse w-full border border-slate-400'>
                    <thead className='bg-slate-50 dark:bg-slate-700'>
                        <tr>
                            <td className='w-1/2 border border-slate-300 dark:border-slate-600 font-semibold p-4 text-slate-900 dark:text-slate-200 text-left'>Champion</td>
                            <td className='w-1/2 border border-slate-300 dark:border-slate-600 font-semibold p-4 text-slate-900 dark:text-slate-200 text-left'>Date de sortie</td>
                        </tr>
                    </thead>
                    <tbody>
                    {Object.entries(d.reponseCorrect).map(([key, item]) => {
                        return (
                            <tr key={key} className='bg-slate-800 text-white'>
                                <td className='grid place-items-center border border-slate-300 dark:border-slate-700 p-4'><img alt="image question" className='w-2/6' src={item.img}></img></td>
                                <td className='border border-slate-300 dark:border-slate-700 p-4 font-spegiel-bold'>{item.value}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
        table = ReactDOMServer.renderToStaticMarkup(table);  

        Swal.fire({
            title: "Date de sortie des champions",
            html : table,
            timer: null,
            customClass: {
                container: 'bg-slate-800',
                popup: 'bg-slate-800'
            },
            showCloseButton: true,
            showConfirmButton: false
        })
    };

    //Pour le retour arrière / rejouer 
    function restartGame(){
        window.location.reload();
    }

    return (
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <h2 className={`col-start-1 col-end-3 place-self-center uppercase font-title ${state.themes[state.theme].textColorTitle} sm:text-sm md:text-4xl lg:text-4xl py-3`}>{Constante.RECAPITULATIF_DE_LA_PARTIE}</h2>
            <div className='grid grid-rows-1 grid-cols-2 mt-5 col-start-1 col-end-3'>
                <button
                            className={`button button--nina px-5 py-0 mb-4 place-self-center font-spegiel-bold ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} ${state.themes[state.theme].hover__backgroundButton_pannel}  text-white relative block focus:outline-none rounded text-xxs text-center font-spegiel-bold font-bold uppercase tracking-widest overflow-hidden w-3/5 md:text-sm lg:text-base xl:text-lg`}
                            data-text="rejouer"
                            onClick={restartGame}
                        >
                        <span className="align-middle">R</span>
                        <span className="align-middle">E</span>
                        <span className="align-middle">J</span>
                        <span className="align-middle">O</span>
                        <span className="align-middle">U</span>
                        <span className="align-middle">E</span>
                        <span className="align-middle">R</span>        
                </button>
                <Link to="/" className='grid place-self-center w-full'>
                    <button
                            className={`button button--nina px-5 py-0 mb-4 place-self-center ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} ${state.themes[state.theme].hover__backgroundButton_pannel} text-white relative block focus:outline-none rounded text-xxs text-center font-spegiel-bold font-bold uppercase tracking-widest overflow-hidden w-3/5 md:text-sm lg:text-base xl:text-lg`}
                            data-text="Retour au Menu"
                    >
                        <span className="align-middle">R</span>
                        <span className="align-middle">E</span>
                        <span className="align-middle">T</span>
                        <span className="align-middle">O</span>
                        <span className="align-middle">U</span>
                        <span className="align-middle">R</span>
                        <span className="align-middle">&nbsp;</span>    
                        <span className="align-middle">A</span>    
                        <span className="align-middle">U</span>    
                        <span className="align-middle">&nbsp;</span>    
                        <span className="align-middle">M</span>    
                        <span className="align-middle">E</span>    
                        <span className="align-middle">N</span>    
                        <span className="align-middle">U</span>    
                    </button>
                </Link>
            </div>
            {
                props.finalResponse.map((d, key) => {
                    return (
                        <div key={key}
                             className={`grid col-span-full gap-y-4 ml-4 py-6 px-6 rounded-lg border border-blue-200 shadow-blue-200/50 ${state.themes[state.theme].bg__recap__question} text-white shadow-md md:col-span-1 md:gap-y-0 md:mx-2`}>
                         <div className='col-span-1'>
                                <h2 className='font-bold font-spegiel'>Question N°{key + 1}</h2>
                                <h3 className='pt-2 font-spegiel-bold'>{d.question}</h3>

                                {
                                    // Si vide ou pas de description, on affiche rien car ce n'est pas une question sur un descriptif
                                    d.description === "" || d.description === undefined ? null :
                                        d.typeQuestion === 'stats' ?
                                                <>
                                                    <button
                                                        onClick={() => showStats(d) }
                                                        className='border border-black hover:bg-black hover:text-white font-bold py-2 px-2 md:py-2 md:px-4 mt-2 rounded'>
                                                        Voir les statistiques
                                                    </button>
                                                </>
                                            :
                                                <div className='my-2 italic'>
                                                    {
                                                        d.typeQuestion.includes('item') ?
                                                            renderHTML(d.description.map((ele, idx) => ele + ` (${d.propositions[idx]} G) `))
                                                            :
                                                            renderHTML(d.description)
                                                    }
                                                </div>

                                }
                            </div>
                            <div className={`grid ${d.typeQuestion.includes('item') ? 'grid-cols-3' : ''} md:text-right`}>
                                {
                                    d.typeQuestion.includes('item') ?
                                        d.image.map((image, index) => {
                                            return (
                                                <div key={index} className='col-span-1'>
                                                    <img
                                                        className='w-16 ml-6 mb-4 rounded shadow-lg'
                                                        src={image}
                                                        alt="Illustration correspondante à la question."/>
                                                </div>
                                            )
                                        })
                                    :        
                                        d.typeQuestion.includes('dateSortieChampion') ?
                                            <button
                                            onClick={() => showDAte(d) }
                                            className='place-self-center border border-black hover:bg-black hover:text-white font-bold  rounded w-full'>
                                            Voir les dates de sorties
                                            </button>
                                        :
                                            <img
                                            className='col-span-2 place-self-center rounded shadow-lg md:place-self-end md:w-32'
                                            src={d.image}
                                            alt="Illustration correspondante à la question."/>
                                                                                
                                }
                            </div>
                            <div className='md:col-start-1 md:col-end-3'>

                                {
                                    d.typeQuestion.includes('item') &&
                                        <div className='grid grid-cols-2 gap-x-2'>
                                            <div className='col-span-1 font-spegiel-bold'>
                                                <p> Somme saisie </p>
                                                <p>${d.reponseChoisi}</p>
                                            </div>
                                            <div className='col-span-1 font-spegiel-bold'>
                                                <p> Somme exacte </p>
                                                <p>${d.reponseCorrect}</p>
                                            </div>
                                        </div>
                                }

                                {
                                    d.isCorrect && !d.typeQuestion.includes('item') && !d.typeQuestion.includes('titreChampionMoyen') &&
                                    <div
                                        className="grid grid-cols-1 gap-y-2 pt-2 pointer-events-none md:grid-cols-4 md:gap-x-4 md:gap-y-0">{d.propositions.map((e, index) => {
                                        return e === d.reponseChoisi ?
                                            <button key={index}
                                                className='px-3 py-1 rounded border border-solid border-black bg-green-700 font-bold text-xs md:text-md lg:text-lg'>{e}</button> :
                                            <button key={index}
                                                className='px-3 py-1 rounded border border-black font-bold text-xs md:text-md lg:text-lg'>{e}</button>
                                    })}
                                    </div>
                                }

                                {
                                    d.typeQuestion.includes('dateSortieChampion') &&
                                    <div className='grid grid-cols-6'>
                                        <div className='grid col-start-2 col-end-5 grid-cols-4 gap-y-2 pt-2 pointer-events-none md:grid-cols-4 md:gap-x-4 md:gap-y-0'>
                                        {d.reponseCorrect.map((image, index) => {
                                            return(
                                                    <img
                                                        key={index}
                                                        alt="image du champion"
                                                        className={`w-full h-full ml-6 mb-4 rounded shadow-lg p-1 ${image.value===d.reponseChoisi[index].value ? "bg-green-300" : "bg-red-600"}`}
                                                        src={image.img}
                                                        /> )
                                                                                            
                                        })}
                                        </div>
                                    </div>
                                }

                                {
                                    d.typeQuestion.includes('titreChampionMoyen') &&
                                        <div
                                            className='grid col-start-2 place-content-center gap-y-2 pt-2 pointer-events-none'>
                                                    <p>Votre réponse : </p>
                                                    <button className={`px-3 py-1 rounded border border-solid border-black ${d.reponseChoisi===d.reponseCorrect ? "bg-green-700" : "bg-red-700"}  font-bold text-xs md:text-md lg:text-lg`}>{d.reponseChoisi}</button>
                                                    <p>La réponse est : </p>
                                                    <button className={`px-3 py-1 rounded border border-solid border-black bg-green-700 font-bold text-xs md:text-md lg:text-lg`}>{d.reponseCorrect}</button>
                                        </div>
                                    
                                }

                                {
                                    !d.isCorrect && !d.typeQuestion.includes('item') && !d.typeQuestion.includes('titreChampionMoyen') &&
                                    <div
                                        className='grid grid-cols-1 gap-y-2 pt-2 pointer-events-none md:grid-cols-4 md:gap-x-4 md:gap-y-0'>{d.propositions.map((e, index) => {
                                        if (e === d.reponseChoisi) {
                                            return (<button key={index}
                                                className='px-3 py-1 rounded border border-solid border-black bg-red-700 font-bold text-xs md:text-md lg:text-lg'>{e}</button>)
                                        } else if (e === d.reponseCorrect) {
                                            return (<button key={index}
                                                className='px-3 py-1 rounded border border-solid border-black bg-green-700 font-bold text-xs md:text-md lg:text-lg'>{e}</button>)
                                        } else {
                                            return (<button key={index}
                                                className='border border-black hover:text-white font-bold px-3 py-1 w-full rounded text-xs md:text-md lg:text-lg'>{e}</button>)
                                        }
                                    })}
                                    </div>
                                }

                            </div>
                        </div>
                        )})
            }

            <div className={`grid gap-3 col-start-1 col-end-3 py-6 px-6 mx-3 mb-3 shadow-md border border-blue-200 shadow-blue-200/50 rounded-lg ${state.themes[state.theme].bg__recap__question} text-white`}>
                <div className="col-start-1 col-end-3 pb-3 place-self-center text-white text-xl md:text-4xl md:col-end-4">
                    <div className="grid grid-cols-2 gap-4">
                        <TwitterShareButton
                        className='place-self-center'
                            url={'https://quizzlol.fr'}
                            title={`Je viens de finir mon quizz quotidien !\nJ'ai obtenu le score de ${props.nbCorrectAnswer} / ${props.finalResponse.length} sur `}
                            hashtags={['quizz', 'quizzlol', 'lol', 'leagueOfLegends']}
                        >
                            <button
                                className={`flex items-center button button--nina px-5 py-0 mb-4 place-self-center font-spegiel-bold ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} ${state.themes[state.theme].hover__backgroundButton_pannel}  text-white relative block focus:outline-none rounded text-xxs text-center font-spegiel-bold font-bold uppercase tracking-widest overflow-hidden w-full md:text-sm lg:text-base xl:text-lg`}
                            >
                                Twitter
                                <TwitterIcon size={45} round />
                            </button>                        
                        </TwitterShareButton>
                                <button
                                    className={`flex items-center button button--nina px-5 py-0 mb-4 place-self-center font-spegiel-bold ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} ${state.themes[state.theme].hover__backgroundButton_pannel}  text-white relative block focus:outline-none rounded text-xxs text-center font-spegiel-bold font-bold uppercase tracking-widest overflow-hidden w-full md:text-sm lg:text-base xl:text-lg`}
                                >
                                    Screen
                                    <FaCamera size={45} onClick={togglePopupScreenshot}
                                        className='cursor-pointer'
                                    />
                                </button>
                    </div>
                </div>
                <div ref={ref} className={`grid gap-3 col-start-1 col-end-3 ${state.themes[state.theme].bg__recap__stat} md:col-end-4`}>
                    <h2 className='col-start-1 col-end-3 place-self-center py-3 uppercase font-spegiel-bold text-white text-xl md:text-4xl md:col-end-4'>Score final : {props.nbCorrectAnswer} / {props.finalResponse.length}</h2>
                    <p className='col-start-1 col-end-3 place-self-center uppercase font-spegiel-bold text-white text-sm md:text-md md:col-end-4 lg:text-lg'>
                        Pourcentage de réussite en fonction des questions
                    </p>
                    {
                        props.getStatistiques.percentValues.map((element, index) => {
                            return (
                                <div className={`col-span-1 place-self-center ${state.themes[state.theme].bg__recap__stat}`} key={index}>
                                    <h2 className='mb-3 font-spegiel'> {element.question} </h2>
                                    <CircularProgressbar value={element.value}
                                                         text={`${element.value}%`}
                                                         className="h-32"
                                                         styles={colorCircleArray[index]}/>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    showPopupScreen && <Popup
                        content={
                            <>
                                <div>
                                    <p className={'mb-3 md:mb-5'}>
                                        Copie l'image et partage-la avec tes amis !
                                    </p>
                                </div>
                                <div className={'mb-3 md:mb-5'}>
                                    <img className="border" src={image} alt={"ScreenShot"} />
                                </div>
                            </>
                        }
                        handleClose={togglePopupScreenshot}
                    />
                }
            </div>
        </div>
    )
}
