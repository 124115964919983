import React, { useEffect, useState, useContext } from 'react';
import { ThemeContext } from '../../index.js'
import {Link} from "react-router-dom";
import {TwitterIcon, TwitterShareButton} from "react-share";
import Constante from "../../assets/const/fr/Constante.json"
import PannelChampService from "../../service/PannelChampService";
import QuestionService from "../../service/QuestionService";
import ProfileChampion from './ProfileChampion';
import Popup from '../Popup';
import HistoriqueChampion from './HistoriqueChampion';
import storage from '../../service/Storage';
import RecapPartiePannel from './RecapPartiePannel';
import Swal from 'sweetalert2';
import { FaHeart, FaRegHeart } from "react-icons/fa";
import reglage from '../../assets/img/UI/reglage.png';
import chronometre from '../../assets/img/items/3157.png';
import plus from '../../assets/img/UI/plus.png';
import less from '../../assets/img/UI/minus.png';
import teemo from '../../assets/img/emotes/teemo.png';
import incorrectLogo from '../../assets/img/UI/incorrect.png'
import { HistoriqueChampModel } from '../../model/HistoriqueChampModel';
import sorakaSpellR from '../../assets/img/champion/spell/SorakaR.png';
import sorakaLoadingKc from '../../assets/img/champion/loading/Soraka_15.jpg';
import sorakaLoading from '../../assets/img/champion/loading/Soraka_16.jpg';
import ekkoSpellR from '../../assets/img/champion/spell/EkkoR.png';
import ekkoLoadingKc from '../../assets/img/champion/loading/Ekko_46.jpg';
import ekkoLoading from '../../assets/img/champion/loading/Ekko_45.jpg';
import zileanSpellW from '../../assets/img/champion/spell/ZileanW.png';
import zileanLoadingKc from '../../assets/img/champion/loading/Zilean_14.jpg';
import zileanLoading from '../../assets/img/champion/loading/Zilean_5.jpg';
import bookRules from '../../assets/img/runes/Inspiration/UnsealedSpellbook/UnsealedSpellbook.png';
import velkozEmote from '../../assets/img/emotes/velkoz.webp';
import "../../css/pannelChamp.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from "react-router-dom";






export default function PannelChamp() {

    //Gérer le theme
    const [state, setState] = useContext(ThemeContext);

    const navigate = useNavigate();


    const [allChamp, setAllChamp] = useState([]);
    const [champAlreadyFound, setChampAlreadyFound] = useState([]);
    const [histAnswer, setHistAnswer] = useState([]);

    //Niveau lifeMode
    const [countGoodAnswer, setCountGoodAnswer] = useState(0);
    const [countLifeLeft, setCountLifeLeft] = useState(3);
    const [countAnswerInRow, setCountAnswerInRow] = useState(0);

    //Niveau timeBomb
    const [time, setTime] = useState(20);
    const [useEffectTimer, setUseEffectTimer] = useState();

    //Niveau Difficile (course contre la montre)
    const [timeTrial, setTimeTrial] = useState(0);
    const [timeTrialMin, setTimeTrialMin] = useState(0);
    const [useEffectTimeTrial, setUseEffectTimeTrial] = useState();

    //Affichage ou non de la pop-up de réglage
    const [showPopup, setShowPopup] = useState(false);

    const [showPopupRulesSoraka, setShowPopupRulesSoraka] = useState(false);
    const [showPopupRulesZilean, setShowPopupRulesZilean] = useState(false);
    const [showPopupRulesEkko, setShowPopupRulesEkko] = useState(false);


    const [difficulty, setDifficulty] = useState();
    const [isGameStarted, setIsGameStarted] = useState(false);
    const [isGameEnd, setIsGameEnd] = useState(false);
    const [isGameLose, setIsGameLose] = useState(false);
    const [formAnswer, setFormAnswer] = useState({
        answer : ""
    })

    //Récupération des données :
    useEffect(() => {
        async function fetchData(){
            let championsPicture = [];
            let nbChampions = QuestionService.getAllChampionsNames();
            for(let i=0; i<nbChampions.length; i++){
                championsPicture = [...championsPicture, await PannelChampService.getAllProfileChamp(nbChampions[i], i)]
            }
            //Utiliser le state allChamp pour remplir le remplir d'object précédemment créés dans le service pannelChamp
            setAllChamp(championsPicture);
        }

        fetchData();
    }, []);

    useEffect(() => {
        if(difficulty==="timeBomb"){
            setTime(time - 0.1);
            useEffectTimer >= 0 && setTimeout(() => setUseEffectTimer(useEffectTimer + 1), 100);
            if(time<0){
                setIsGameEnd(true);
                setIsGameLose(true);
                getStatistiquesPannel();
            }  
        }    
      }, [useEffectTimer]);

      useEffect(() => {
        if(difficulty==="trialTimer"){
                if(!isGameEnd && !isGameLose){
                    setTimeTrial(timeTrial + 0.1);
                    useEffectTimeTrial >= 0 && setTimeout(() => setUseEffectTimeTrial(useEffectTimeTrial + 1), 100);
                    //9.85 pour pas que ça se voit sur l'ihm le changement de minutes
                    if(timeTrial>=59.85){
                        setTimeTrial(0);
                        setTimeTrialMin(timeTrialMin + 1);
                    }
                    if(countGoodAnswer===allChamp.length){
                        setIsGameEnd(true);
                        setIsGameLose(true);
                        getStatistiquesPannel();
                    }
                }
        }    
      }, [useEffectTimeTrial]);

    

    //Affiche ou non la pop-up pour les réglages
    const togglePopup = () => {
        setShowPopup(!showPopup);
      }

      //Affiche ou non la pop-up pour les règles du mode zilean
    const togglePopupRulesSoraka = () => {
        setShowPopupRulesSoraka(!showPopupRulesSoraka);
      }
    
    //Affiche ou non la pop-up pour les règles du mode zilean
    const togglePopupRulesZilean = () => {
        setShowPopupRulesZilean(!showPopupRulesZilean);
      }
    
    //Affiche ou non la pop-up pour les règles du mode zilean
    const togglePopupRulesEkko = () => {
        setShowPopupRulesEkko(!showPopupRulesEkko);
      }

      const errorPopup = () => {
        Swal.fire({
            title: 'Erreur lors du lancement du jeu',
            text: Constante.ERROR_DAILY,
            icon: 'error',
            timer: null,
            showCloseButton: true
        })
    }

      //Au démarrage de la game 
    async function launchGame(difficulty){
        //On remonte l'utilisateur en haut de la page
        window.scrollTo(0, 0);
        setIsGameStarted(true);
        setDifficulty(difficulty);

        setUseEffectTimer(0);
        if(difficulty='trialTimer'){
            setUseEffectTimeTrial(0);
        }
    }

    //Permet de gérer la réponse de l'utilisateur dans l'input
    function handleChange(event){
        const{name, value} = event.target
        setFormAnswer(prevFormAnswer => {
            return {
                ...prevFormAnswer,
                [name] : value
            }
        })
    }
    
     //Permet de gérer le nombre de seconde choisi par le joueur
     function handleTimerChange(event){
        setTime(parseInt(event.target.value));
    };


    function handleSubmit(event){
        event.preventDefault();
        try{
            let champsName = storage.getChampsToLowerCase();
            let answerOfPlayer = formAnswer.answer.replaceAll(`'`,'').replaceAll(` `,'').replaceAll('-','').toLowerCase();
            if(answerOfPlayer==="renataglasc"){
                answerOfPlayer="renata";
            }else if(answerOfPlayer==="nunu&willump"){
                answerOfPlayer="nunu";
            }
            switch(difficulty){
                case "lifeMode":{
                    //Le cas ou la valeur rentrée par l'utilisateur n'est pas connu d'un des champions
                        if(!champsName.includes(answerOfPlayer)){
                            //Si le joueur se trompe, il perd une vie
                            setCountLifeLeft(countLifeLeft=> countLifeLeft - 1);
                            //On enregistre la réponse de l'utilisateur dans un object pour ensuite l'ajouter a la liste
                            const historiqueChampModel = new HistoriqueChampModel(formAnswer.answer, incorrectLogo, false);
                            if(histAnswer.length >= 4){
                                const useEffectTimerList = histAnswer;
                                useEffectTimerList.shift();
                                setHistAnswer(useEffectTimerList);
                            }
                            setHistAnswer(preHist => [...preHist,  historiqueChampModel]);
                            //Si la réponse est incorrect on remet le conteur de bonne réponse a zéro
                            setCountAnswerInRow(0);
                            //Si l'utilisateur n'a plus de vie on termine la partie + on affiche le recap
                            if(countLifeLeft===1){
                                //Appel d'une fonction pour réaliser le récap
                                setIsGameEnd(true);
                                setIsGameLose(true);
                                getStatistiquesPannel();
                            }
                            playerMistake();
                        }else{
                            //On vérifie si notre state qui contient le tableau avec les champions trouvé possède la réponse
                            //S'il possède deja la valeur, on ne fait aucun traitement concernant les vies restantes du joueur
                            if(!champAlreadyFound.includes(answerOfPlayer)){
                                //Si le champion trouvé n'est pas dans le tableau du state on l'ajoute
                                setChampAlreadyFound(champAlreadyFound => [...champAlreadyFound, answerOfPlayer]);
                                //On ajoute +1 au nombre de réponse donnée d'affilée
                                setCountAnswerInRow(countAnswerInRow + 1);
                                //Réalisation d'un traitement suivant le niveau choisi par l'utilisateur au démarrage du jeu
                                //Si le niveau est lifeMode on regarde si le nb de bonnes réponses d'affilées = 3 
                                //+ vérification si le nombre vie est inférieur a 3 (impossible d'avoir + de 3 vies)
                                if(countAnswerInRow===9){
                                    if(countLifeLeft < 4){
                                        setCountLifeLeft(countLifeLeft + 1)
                                        retrieveLife();
                                    }                    
                                }
                            }
                            if(countGoodAnswer===allChamp.length){
                                setIsGameEnd(true);
                                setIsGameLose(true);
                                getStatistiquesPannel();
                            }
                        }
                    }
                    break;
                    case "timeBomb" :
                        if(!champsName.includes(answerOfPlayer)){
                            if(time-10 < 1){
                                    setIsGameEnd(true);
                                    setIsGameLose(true);
                                    getStatistiquesPannel();
                            }
                            const historiqueChampModel = new HistoriqueChampModel(formAnswer.answer, incorrectLogo, false);
                            if(histAnswer.length === 4){
                                const useEffectTimerList = histAnswer;
                                useEffectTimerList.shift();
                                setHistAnswer(useEffectTimerList);
                            }
                            setHistAnswer(preHist => [...preHist,  historiqueChampModel]);
                            setTime(time - 10);
                            playerMistake();
                        }else{
                            //On vérifie si notre state qui contient le tableau avec les champions trouvé possède la réponse
                            //S'il possède deja la valeur, on ne fait aucun traitement concernant les vies restantes du joueur
                            if(!champAlreadyFound.includes(answerOfPlayer)){
                                //Si le champion trouvé n'est pas dans le tableau du state on l'ajoute
                                setChampAlreadyFound(champAlreadyFound => [...champAlreadyFound, answerOfPlayer]);
                                setTime(time + 10);
                            }
                            if(countGoodAnswer===allChamp.length){
                                setIsGameEnd(true);
                                setIsGameLose(true);
                                getStatistiquesPannel();
                            }
                        }
                        break;
                    case "trialTimer" :
                        if(!champsName.includes(answerOfPlayer)){
                            //POP UP HOO RATE !
                            playerMistake();
                            const historiqueChampModel = new HistoriqueChampModel(formAnswer.answer, incorrectLogo, false);
                            //On ne garde que les 4 dernieres entrées du joueur
                            if(histAnswer.length === 4){
                                const useEffectTimerList = histAnswer;
                                useEffectTimerList.shift();
                                setHistAnswer(useEffectTimerList);
                            }
                            //Ajout de la nouvelle entrée
                            setHistAnswer(preHist => [...preHist,  historiqueChampModel]);
                        }else{
                            //On vérifie si notre state qui contient le tableau avec les champions trouvé possède la réponse
                            //S'il possède deja la valeur, on ne fait aucun traitement concernant les vies restantes du joueur
                            if(!champAlreadyFound.includes(answerOfPlayer)){
                                //Si le champion trouvé n'est pas dans le tableau du state on l'ajoute
                                setChampAlreadyFound(champAlreadyFound => [...champAlreadyFound, answerOfPlayer]);
                            }
                        }
                        break;
                    default:
                        break;
                }            
        
            //On modifie l'object champion si et seulement si il existe dans le useState contenant l'ensemble des objects champion
            //isHide est a false (on affiche la photo) et isFind passe a true (permet de modifier le background en vert dans ProfileChampion)
            setAllChamp(prevAllChamp=>{
                return prevAllChamp.map((item)=>{
                    return item.championName.toLowerCase() === answerOfPlayer ? {...item, isHide: false, isFind: true} : item
                })
            })

            //Si l'utilisateur trouve une bonne réponse on ajoute +1 au nombre de bonne réponse
            allChamp.map(item=>{
                if(item.championName.toLowerCase() === answerOfPlayer && item.isHide===true) {
                    if(histAnswer.length === 4){
                        const useEffectTimerList = histAnswer;
                        useEffectTimerList.shift();
                        setHistAnswer(useEffectTimerList);
                    }
                    const historiqueChampModel = new HistoriqueChampModel(formAnswer.answer, item.imgLink ,true);
                    setHistAnswer(preHist => [...preHist,  historiqueChampModel]);
                    return setCountGoodAnswer(countGoodAnswer + 1);
                }
            })
            //On enregistre l'élément entré par l'utilisateur dans un tableau
            setFormAnswer({...formAnswer, answer:""});
        }catch (error) {
            console.error('Erreur dans le code : ', error);
            errorPopup();
            navigate('/');
        }
    }

    //Permet d'afficher tous les champions quand le jeu est terminé
    async function getStatistiquesPannel(){
        let nameChampLeft = []
        //On souhaite dans le recap afficher tout le pannel donc on met tous les isHide a false
        allChamp.map((item)=>{
        if(item.isHide === true){
            nameChampLeft.push(item.championName);
        }})
        setAllChamp(prevAllChamp=>{
            return prevAllChamp.map((item)=>{
                return  {...item, isHide: false};
            })
        })
    }

    //Pour le retour arrière / rejouer 
    function restartGame(){
        window.location.reload();
    }

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

    
    //Pop up informative
    const retrieveLife = () => {
        Toast.fire({
            icon: 'info',
            title: 'Vous avez gagné une vie !'
          })
    }

    //Pop up informative
    const playerMistake = () => {
        Toast.fire({
            icon: 'error',
            title: 'Champion inconnu !'
          })
    }

    //Affichage des coeurs pour le mode avec les vies
    const displayHearth = () => {
        switch(countLifeLeft){
            case 1:
                return (<div className='flex h-8 w-full justify-center'>
                    <FaHeart size={36} color={`${state.themes[state.theme].hearth__color}`}/>
                    <FaRegHeart size={36} color={`${state.themes[state.theme].hearth__color}`}/>
                    <FaRegHeart size={36} color={`${state.themes[state.theme].hearth__color}`}/>
                    </div>)
                break;
            case 2:
                return (<div className='flex h-8 w-full justify-center'>
                    <FaHeart size={36} color={`${state.themes[state.theme].hearth__color}`}/>
                    <FaHeart size={36} color={`${state.themes[state.theme].hearth__color}`}/>
                    <FaRegHeart size={36} color={`${state.themes[state.theme].hearth__color}`}/>
                    </div>)
                break;
            default :
                return (<div className='flex h-10 w-full justify-center'>
                    <FaHeart size={36} color={`${state.themes[state.theme].hearth__color}`}/>
                    <FaHeart size={36} color={`${state.themes[state.theme].hearth__color}`}/>
                    <FaHeart size={36} color={`${state.themes[state.theme].hearth__color}`}/>
                    </div>)
                break;
        }
    }

    //On passe la couleur du texte en rouge si < 10 secondes
    let styleTimer;
    if(time<=10){
        styleTimer = {
            "color": "#b30000",
        };
    }

    //On gère le style des balise <hr>
    const hrColor = {
        "display": "block", "height": "1px",
        "border": 0,
        "borderTop": state.themes[state.theme].borderTop,
        "margin": "1em 0",
    }

    //Pour la couleur des bouttons (thème)
    let styleBackground;
    if (state.theme === "kcorp") {
        styleBackground = {
            "backgroundImage": state.themes[state.theme].backgroundButton,
            "background-size": "contain"
        }
    }else{
        styleBackground = {
            "backgroundImage": "none"
        }
    };

    return (
        <div className='pb-8'>
            {
            !isGameStarted ?
            <div className=''>
                <p className={`flex justify-center mb-2 text-xl font-title ${state.themes[state.theme].textColorTitle} uppercase tracking-wider md:text-4xl`}>Le panel des champions</p>
                <div className='px-16'>
                    <hr className='mb-2' style={hrColor}></hr>
                </div>  
                <div className='grid grid-rows-1 gap-9 md:grid-rows-1 md:grid-cols-3 md:mb-28 lg:mb-10 xl:mb-0'>
                    <div className='grid place-self-center h-full w-3/5 transform transition duration-500 hover:scale-105'>
                        <div className="flex flex-col justify-around justify-items-center items-center rounded-xl bg-black absolute inset-0 opacity-0 hover:opacity-100 bg-opacity-90 duration-300">
                            <div className='flex flex-col items-center w-full px-4'>
                                <LazyLoadImage className='w-3/5' src={bookRules}></LazyLoadImage>
                                <div className='flex w-full rounded bg-red-400' style={styleBackground}>
                                    <button
                                        className={`button button--nina animate__animated animate__fadeIn
                                        py-0 px-2 w-full relative block rounded text-center text-sm lg:text-base uppercase focus:outline-none
                                        tracking-widest overflow-hidden ${state.themes[state.theme].hover__backgroundButton_pannel} text-white font-bold font-spegiel-bold md:text-sm lg:text-base xl:text-xl`}
                                        data-text="Règles du jeu"
                                        onClick={togglePopupRulesSoraka}>
                                            
                                        <span className="align-middle">R</span>
                                        <span className="align-middle">E</span>
                                        <span className="align-middle">G</span>
                                        <span className="align-middle">L</span>
                                        <span className="align-middle">E</span>
                                        <span className="align-middle">S</span>
                                        <span className="align-middle">&nbsp;</span>
                                        <span className="align-middle">D</span>
                                        <span className="align-middle">U</span>
                                        <span className="align-middle">&nbsp;</span>
                                        <span className="align-middle">J</span>
                                        <span className="align-middle">E</span>
                                        <span className="align-middle">U</span>
                                    </button>
                                </div>
                            </div>
                            <div className='w-full px-2'>
                                <div className='flex w-full rounded bg-red-400' style={styleBackground}>
                                <button
                                    className={`button px-5 py-2 w-full text-2xl text-center text-white font-bold font-spegiel-bold font-test uppercase rounded relative block ${state.themes[state.theme].hover__backgroundButton_pannel} md:text-sm lg:text-base xl:text-xl`}
                                    data-text="Commencer"
                                    value='lifeMode' onClick={(e) => launchGame(e.target.value)}
                                    >Commencer
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='grid-rows-3 w-full'>
                            <div className='flex flex-col w-full'>
                                <h2 className={`flex justify-center text-lg font-card-title ${state.themes[state.theme].textColor} uppercase tracking-wider md:text-xs lg:text-sm xl:text-base`}>Souhait</h2>
                                <div className='w-full flex justify-center'>
                                    <LazyLoadImage className='w-2/12 rounded-xl' src={sorakaSpellR}></LazyLoadImage>
                                </div>
                            </div>
                            <div className='px-6'>
                                <hr className='' style={hrColor}></hr>
                            </div>
                        </div>
                        <LazyLoadImage className={`place-self-center h-full w-full rounded-2xl ${state.themes[state.theme].shadowColor}`} src={state.theme === "kcorp" ? sorakaLoadingKc : sorakaLoading }></LazyLoadImage>
                        <p className='flex justify-center mt-2 px-1 w-full font-spegiel text-base text-white  tracking-wider md:text-xs lg:text-sm xl:text-base'>Chaque vie est sacrée</p>           
                    </div>

                    <div className='grid place-self-center h-full w-3/5 text-white font-text border-red-400 transform transition duration-500 hover:scale-105'>
                        <div className="flex flex-col justify-around justify-items-center items-center rounded-xl bg-black opacity-0 hover:opacity-100 bg-opacity-90 duration-300 absolute inset-0">
                            <div className='flex flex-col items-center w-full px-4 mb-10'>
                                <LazyLoadImage className='w-3/5' src={bookRules}></LazyLoadImage>
                                <div className='flex w-full rounded bg-red-400' style={styleBackground}>
                                    <button
                                        className={`button button--nina animate__animated animate__fadeIn
                                        py-0 w-full relative block rounded text-center text-sm uppercase focus:outline-none
                                        tracking-widest overflow-hidden ${state.themes[state.theme].hover__backgroundButton_pannel} text-white font-spegiel-bold md:text-sm lg:text-base xl:text-xl`}
                                        data-text="Règles du jeu"
                                        onClick={togglePopupRulesZilean}>
                                            
                                        <span className="align-middle">R</span>
                                        <span className="align-middle">E</span>
                                        <span className="align-middle">G</span>
                                        <span className="align-middle">L</span>
                                        <span className="align-middle">E</span>
                                        <span className="align-middle">S</span>
                                        <span className="align-middle">&nbsp;</span>
                                        <span className="align-middle">D</span>
                                        <span className="align-middle">U</span>
                                        <span className="align-middle">&nbsp;</span>
                                        <span className="align-middle">J</span>
                                        <span className="align-middle">E</span>
                                        <span className="align-middle">U</span>
                                    </button>
                                </div>
                            </div>
                            <div className='w-full px-2'>
                                <div className='flex w-full rounded bg-red-400' style={styleBackground}>
                                    <button
                                    className={`button py-0 w-11/12 rounded text-3xl text-center font-spegiel-bold text-white ${state.themes[state.theme].hover__backgroundButton_pannel} uppercase relative block md:py-2 md:text-sm lg:text-base xl:text-xl`}
                                    data-text="Commencer"
                                    value='timeBomb' onClick={(e) => launchGame(e.target.value)}
                                    >Commencer
                                    </button>
                                    <button className={`border-l border-black ${state.themes[state.theme].hover__backgroundButton_pannel} hover:rounded-r w-1/12`} onClick={togglePopup}><LazyLoadImage className='w-full' src={reglage}/></button>
                                </div>
                            </div>     
                        </div>
                        <div className='grid-rows-3 w-full'>
                            <div className='flex flex-col w-full'>
                                <h2 className={`flex justify-center text-lg font-card-title ${state.themes[state.theme].textColor} uppercase tracking-wider md:text-xs lg:text-sm xl:text-base`}>Bombe à retardement</h2>
                                <div className='w-full flex justify-center'>
                                    <LazyLoadImage className='w-2/12 rounded-xl' src={zileanSpellW}></LazyLoadImage>
                                </div>
                            </div>
                            <div className='px-6'>
                                <hr className='' style={hrColor}></hr>
                            </div>
                        </div>
                        <LazyLoadImage className={`place-self-center w-full h-full rounded-2xl ${state.themes[state.theme].shadowColor}`} src={state.theme === "kcorp" ? zileanLoadingKc : zileanLoading}></LazyLoadImage>
                        <p className='flex justify-center px-2 font-spegiel text-base text-white tracking-wider md:text-xs lg:text-sm xl:text-base'>Ne perdons pas de temps</p>  
                    </div>     
                                            
                    <div className={`grid place-self-center h-full w-3/5 text-white font-text border-red-400 rounded-xl transform transition duration-500 hover:scale-105`}>
                        <div className="flex flex-col justify-around justify-items-center items-center rounded-xl bg-black opacity-0 hover:opacity-100 bg-opacity-90 duration-300 absolute inset-0 ">
                                <div className='flex flex-col items-center w-full px-4'>
                                    <LazyLoadImage className='w-3/5' src={bookRules}></LazyLoadImage>
                                    <div className='flex w-full rounded bg-red-400' style={styleBackground}>
                                        <button
                                           className={`button button--nina animate__animated animate__fadeIn
                                           py-0 w-full relative block rounded text-center text-sm uppercase focus:outline-none
                                           tracking-widest overflow-hidden ${state.themes[state.theme].hover__backgroundButton_pannel} text-white font-spegiel-bold md:text-sm lg:text-base xl:text-xl`}
                                           data-text="Règles du jeu"
                                            onClick={togglePopupRulesEkko}>    
                                            <span className="align-middle">R</span>
                                            <span className="align-middle">E</span>
                                            <span className="align-middle">G</span>
                                            <span className="align-middle">L</span>
                                            <span className="align-middle">E</span>
                                            <span className="align-middle">S</span>
                                            <span className="align-middle">&nbsp;</span>
                                            <span className="align-middle">D</span>
                                            <span className="align-middle">U</span>
                                            <span className="align-middle">&nbsp;</span>
                                            <span className="align-middle">J</span>
                                            <span className="align-middle">E</span>
                                            <span className="align-middle">U</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='w-full px-2'>
                                    <div className='flex w-full rounded bg-red-400' style={styleBackground}>
                                        <button
                                        className={`button py-2 w-full text-2xl text-center font-bold font-spegiel-bold ${state.themes[state.theme].hover__backgroundButton_pannel} text-white uppercase rounded relative block md:py-2 md:text-sm lg:text-base xl:text-xl`}
                                        data-text="Commencer"
                                        value='trialTimer' onClick={(e) => launchGame(e.target.value)}
                                        >Commencer
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='grid-rows-3 w-full'>
                                <div className='flex flex-col w-full'>
                                    <h2 className={`flex justify-center text-lg font-card-title ${state.themes[state.theme].textColor} uppercase tracking-wider md:text-xs lg:text-sm xl:text-base`}>CHRONOFRACTURE</h2>
                                    <div className='w-full flex justify-center'>
                                        <LazyLoadImage className={`w-2/12 rounded-xl `} src={ekkoSpellR}></LazyLoadImage>
                                    </div>
                                </div>
                                <div className='px-6'>
                                    <hr className='' style={hrColor}></hr>
                                </div>
                            </div>
                            <LazyLoadImage className={`place-self-center w-full h-full rounded-2xl ${state.themes[state.theme].shadowColor}`} src={state.theme === "kcorp" ? ekkoLoadingKc : ekkoLoading}></LazyLoadImage>
                            <p className={`flex justify-center font-spegiel text-base text-white tracking-wider md:text-xs lg:text-sm xl:text-base`}>Le temps n'est pas ton allié</p>  
                        </div>
                </div>
                {showPopupRulesSoraka && <Popup
                                        content={<>
                                                <p className={`flex justify-center text-xl font-title text-white uppercase md:text-xl lg:text-2xl xl:text-4xl`}>Règles du jeu</p>
                                                <dl className={`w-full divide-y divide-gray-200 dark:text-white dark:divide-gray-400`}>
                                                    <div className="flex flex-col pb-3">
                                                        <dt className={`mb-1 text-base font-card-title text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl t`}>Les vies</dt>
                                                        <div className='flex items-center'>
                                                            <dd className={`jusitfy-center text-white mr-6 font-spegiel-bold text-xl font-semibold md:text-base lg:text-xl xl:text-2xl`}>Tu commences la partie avec 3 vies</dd>
                                                            <p className={`flex justify-center text-white p-2 w-2/12 rounded-xl ${state.themes[state.theme].popup__backgroundElemRules}`}>
                                                                <FaHeart size={36}/>
                                                                <FaHeart size={36}/>
                                                                <FaHeart size={36}/>
                                                            </p>
                                                        
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col py-3">
                                                        <dt className={`mb-1 text-base font-card-title md:text-lg text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl`}>Perte d'une vie</dt>
                                                        <div className='flex items-center'>
                                                            <dd className={`jusitfy-center text-white mr-6 font-spegiel-bold text-xl font-semibold md:text-base lg:text-xl xl:text-2xl`}>Chaque erreur t'enlève une vie</dd>
                                                            <p className={`flex justify-center text-white p-2 w-2/12 rounded-xl ${state.themes[state.theme].popup__backgroundElemRules}`}>
                                                                <FaHeart size={36}/>
                                                                <FaHeart size={36}/>
                                                                <FaRegHeart size={36}/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col pt-3">
                                                        <dt className={`mb-1 text-base font-card-title md:text-lg text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl`}>Orthographe</dt>
                                                        <div className='flex items-center'>
                                                            <dd className={`jusitfy-center text-white font-spegiel-bold text-xl md:text-base lg:text-xl xl:text-2xl`}>Ne sont pas comptés : espaces / appostrophes (Velk'oz peut s'écrire Velkoz)</dd>
                                                            <LazyLoadImage className='w-1/12' src={velkozEmote} alt="" />
                                                        </div>        
                                                    </div>
                                                    <div className="flex flex-col pt-3 pb-5">
                                                        <dt className={`mb-1 text-base font-card-title text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl`}>Regain de vie</dt>
                                                        <div className='flex items-center'>
                                                            <dd className={`text-xl text-white font-spegiel-bold mr-2 md:text-base lg:text-xl xl:text-2xl`}>10 bonnes réponses d'affilées, gain d'une vie !</dd>
                                                            <p className={`flex text-white items-center justify-center p-2 w-2/12 rounded-xl text-sm font-bold ${state.themes[state.theme].popup__backgroundElemRules} md:text-base lg:text-xl xl:text-2xl`}>+ 1&nbsp; <FaHeart size={36}/></p>
                                                        </div>
                                                    </div>
                                                </dl> 
                                        </>}
                                        handleClose={togglePopupRulesSoraka}
                                    />}
                {showPopupRulesZilean && <Popup
                                        content={<>
                                                <p className='flex justify-center text-xl font-title text-white uppercase md:text-xl lg:text-2xl xl:text-4xl'>Règles du jeu</p>
                                                <dl className="w-full divide-y divide-gray-200 dark:text-white dark:divide-gray-400">
                                                    <div className="flex flex-col py-3">
                                                        <dt className="mb-1 text-base font-card-title text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl">Gagner du temps</dt>
                                                        <div className='flex items-center'>
                                                            <dd className="jusitfy-center text-white font-spegiel-bold mr-6 text-base md:text-base lg:text-xl xl:text-2xl">Chaque bonne réponse rajoute 10 secondes à ton chronomètre</dd>
                                                            <LazyLoadImage className='w-1/12'src={plus} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col pt-3 pb-5">
                                                        <dt className="mb-1 text-base font-card-title text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl">Perte de temps</dt>
                                                        <div className='flex items-center'>
                                                            <dd className="jusitfy-center text-white font-spegiel-bold mr-6 text-base md:text-base lg:text-xl xl:text-2xl">En cas de mauvaises réponses, ton chronomètre perd 10 secondes</dd>
                                                            <LazyLoadImage className='w-1/12'src={less} alt="" />
                                                        </div>        
                                                    </div>
                                                    <div className="flex flex-col pt-3">
                                                        <dt className="mb-1 font-card-title text-base text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl">Orthographe</dt>
                                                        <div className='flex items-center'>
                                                            <dd className="jusitfy-center text-white font-spegiel-bold mr-6 text-base  md:text-base lg:text-xl xl:text-2xl">Ne sont pas comptés : espaces / appostrophes (Velk'oz peut s'écrire Velkoz)</dd>
                                                            <LazyLoadImage className='w-1/12' src={velkozEmote} alt="" />
                                                        </div>        
                                                    </div>
                                                    <div className="flex flex-col pt-3 pb-5">
                                                        <dt className="mb-1 font-card-title text-base text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl">Conclusion de partie</dt>
                                                        <div className='flex items-center'>
                                                            <dd className="jusitfy-center text-white font-spegiel-bold text-base md:text-base lg:text-xl xl:text-2xl">Pour gagner, il faut trouver l'ensemble du pannel avant que ton chronomètre atteigne 0</dd>
                                                            <LazyLoadImage className='w-1/12 rounded-xl' src={chronometre}></LazyLoadImage>
                                                        </div>
                                                    </div>
                                                </dl> 
                                        </>}
                                        handleClose={togglePopupRulesZilean}
                                    />}
                {showPopup && <Popup
                    content={<>
                        <div className='grid grid-rows-2 grid-cols-1 pb-6 font-text'>
                            <h1 className='text-xl place-self-center font-title text-white md:text-xl lg:text-2xl xl:text-4xl'>Réglage de la partie</h1>
                            <hr className="solid"/>                                        
                            <h2 className='mb-4 w-full text-xs font-spegiel-bold text-white md:text-base lg:text-xl xl:text-2xl'>Régler sur le nombre de seconde au démarrage de la partie</h2>
                            <p className='items-center text-base font-texxt font-text text-teal-50 md:text-sm lg:text-base xl:text-xl'>{time} Secondes</p>
                            <div className='flex h-1/12'>
                                <input type="range" step="5" min="10" max="60" value={time} onChange={handleTimerChange} className="justify-center slider" id="myRange"/>
                            </div>
                            
                        </div>
                    </>}
                    handleClose={togglePopup}
                />}
                {showPopupRulesEkko && <Popup
                        content={<>
                                <p className='flex justify-center text-xl font-title text-white uppercase md:text-xl lg:text-2xl xl:text-4xl'>Règles du jeu</p>
                                <dl className="w-full divide-y divide-gray-200 dark:text-white dark:divide-gray-400">
                                    <div className="flex flex-col pb-3">
                                        <dt className="mb-1 text-gray-300 text-base font-card-title dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl">Le temps</dt>
                                        <div className='flex items-center'>
                                            <dd className="jusitfy-center mr-6 text-white font-spegiel-bold text-base md:text-base lg:text-xl xl:text-2xl">Un chronomètre démarre au début de la partie</dd>
                                            <LazyLoadImage className='w-1/12 rounded-xl'src={chronometre} alt="" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col pt-3">
                                        <dt className="mb-1 text-gray-300 text-base font-card-title dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl">Orthographe</dt>
                                        <div className='flex items-center'>
                                            <dd className="jusitfy-center mr-6 text-white text-base font-spegiel-bold md:text-base lg:text-xl xl:text-2xl">Ne sont pas comptés : espaces / appostrophes (Velk'oz peut s'écrire Velkoz)</dd>
                                            <LazyLoadImage className='w-1/12' src={velkozEmote} alt="" />
                                        </div>        
                                    </div>
                                    <div className="flex flex-col pt-3 pb-5">
                                        <dt className="mb-1 text-gray-300 text-base font-card-title dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl">Conclusion de partie</dt>
                                        <div className='flex items-center'>
                                            <dd className="jusitfy-center text-base text-white font-spegiel-bold md:text-base lg:text-xl xl:text-2xl">Le but est simple, réalise le meilleur temps possible</dd>
                                            <LazyLoadImage className='w-2/12' src={teemo}></LazyLoadImage>
                                        </div>
                                    </div>
                                </dl> 
                        </>}
                        handleClose={togglePopupRulesEkko}
                    />}
                </div>
                :
                !isGameEnd ? 
                <div className=''>
                    <div className='min-w-0'>
                        <div className='w-3/12 md:w-2/12 lg:w-1/12'>
                            <button
                                        className={`button button--nina animate__animated animate__fadeIn
                                        py-0 px-5 w-full  relative block ${state.themes[state.theme].return__backgroundButton} ${state.themes[state.theme].hover__backgroundButton_pannel} text-center text-xs uppercase focus:outline-none
                                        tracking-widest overflow-hidden  text-white font-bold`}
                                        data-text="Retour"
                                        onClick={restartGame}
                                    >
                                        <span className="align-middle">R</span>
                                        <span className="align-middle">E</span>
                                        <span className="align-middle">T</span>
                                        <span className="align-middle">O</span>
                                        <span className="align-middle">U</span>
                                        <span className="align-middle">R</span>
                            </button>
                        </div>
                        <section className='flex flex-col w-full'>
                            <h2 className={`flex justify-center mb-2 w-full text-xl font-title ${state.themes[state.theme].textColorTitle} uppercase tracking-wider md:text-xl lg:text-2xl xl:text-3xl`}>Statistiques</h2>
                            <div className='px-16 w-full'>
                                <hr className='mb-2' style={hrColor}></hr>
                            </div> 
                            <div className="grid grid-rows-1 grid-cols-3 gap-1 place-items-center  w-full text-2xl font-black uppercase">
                                <div className={`flex flex-col justify-center pl-2 pr-2 pt-6 pb-6 w-3/5 border-r border-b rounded-lg shadow-2xl text-white border-blue-200 ${state.themes[state.theme].shadow__boxStats}  bg-gradient-to-r ${state.themes[state.theme].background_pannel__stat__gradiant} background-animate`}>
                                    <p className={`flex justify-center w-full font-spegiel ${state.themes[state.theme].textColor} text-xxs md:text-sm lg:text-xl xl:text-xl`}>Champions trouvés</p>
                                    <h2 className={`flex justify-center w-full font-texxt ${state.themes[state.theme].textColor} text-base md:text-sm lg:text-base xl:text-xl`}>{countGoodAnswer}/{allChamp.length}</h2>
                                </div>
                                <div className='flex justify-center mt-2 w-full'>
                        {difficulty==="lifeMode" &&
                            <div className={`p-4 md:p-8 w-auto border-r border-b rounded-lg shadow-2xl border-blue-200 ${state.themes[state.theme].shadow__boxStats} bg-gradient-to-l ${state.themes[state.theme].background_pannel__stat__gradiant} background-animate`}>
                                <p className={`flex justify-items-center pb-4 text-sm font-spegiel ${state.themes[state.theme].textColor} md:font-black uppercase`}>Vie(s) restante(s)</p>
                                {displayHearth()}
                            </div>
                        }
                        {difficulty==="timeBomb" && 
                            <div className={`p-8 w-auto border-r border-b rounded-lg shadow-2xl border-blue-200 ${state.themes[state.theme].shadow__boxStats} font-extrabold bg-gradient-to-l ${state.themes[state.theme].background_pannel__stat__gradiant} background-animate`}>
                                <p className={`flex justify-items-center pb-4 text-xl font-spegiel ${state.themes[state.theme].textColor} uppercase`}>Temps restant</p>
                                <p className={`flex justify-center w-full text-2xl ${state.themes[state.theme].textColor} font-texxt`} style={styleTimer}>{time | 0} :  {time.toFixed(1).split(".")[1]}</p>    
                            </div>
                        }
                        {difficulty==="trialTimer" && 
                            <div className={`p-8 w-auto border-r border-b rounded-lg shadow-2xl border-blue-200 ${state.themes[state.theme].shadow__boxStats} font-extrabold bg-gradient-to-l ${state.themes[state.theme].background_pannel__stat__gradiant} background-animate`}>
                                <p className={`flex justify-items-center pb-4 text-xl font-spegiel ${state.themes[state.theme].textColor} uppercase`}>Temps écoulé</p>
                                <p className={`flex justify-center w-full text-2xl ${state.themes[state.theme].textColor} font-texxt`}>{timeTrialMin > 0 && <span>{timeTrialMin}m</span>}{timeTrial.toFixed(1)}</p>    
                            </div>
                        }
                    </div>

                                <div className={`flex flex-col justify-center pl-2 pr-2 pt-6 pb-6 w-3/5 border-r border-b rounded-lg shadow-2xl border-blue-200 ${state.themes[state.theme].shadow__boxStats} bg-gradient-to-r ${state.themes[state.theme].background_pannel__stat__gradiant} background-animate`}>
                                    <p className={`flex justify-center w-full font-spegiel ${state.themes[state.theme].textColor} text-xxs md:font-black md:text-sm lg:text-xl xl:text-xl`}>Taux de réponses</p>
                                    <h2 className={`flex justify-center w-full ${state.themes[state.theme].textColor} font-texxt text-base md:text-sm lg:text-base xl:text-xl`}>{Math.round((countGoodAnswer/allChamp.length*100) * 10) / 10} %</h2>
                                </div>
                            </div>
                        </section>
                    </div>
                    
                    <div className="grid mt-1 w-full h-full">      
                        <form onSubmit={handleSubmit} className={`text-white row-span-3 justify-center place-self-center mb-5 w-6/12 flex flex-col `}>
                        <input
                                            className={`font-bold h-16 w-full shadow-2xl font-texxt text-xl placeholder-white bg-transparent ${state.themes[state.theme].shadow__boxStats} text-white border border-blue-200 ${state.themes[state.theme].input__focus__border} outline-0   ${state.themes[state.theme].input__background_champion_pannel} md:text-xl lg:text-xl xl:text-2xl`}
                                            type="text"
                                            autoComplete="off"
                                            value={formAnswer.answer}
                                            placeholder="Nom du Champion"
                                            onChange={handleChange}
                                            name="answer"
                                            />
                                <button
                                    className={`button button--nina px-5 py-0 border ${state.themes[state.theme].hover__backgroundButton_pannel} ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} text-white relative block focus:outline-none text-sm text-center font-semibold uppercase tracking-widest overflow-hidden w-full`}
                                    type='submit'
                                    data-text="Soumettre"
                                >
                                    <span className="align-middle">S</span>
                                    <span className="align-middle">O</span>
                                    <span className="align-middle">U</span>
                                    <span className="align-middle">M</span>
                                    <span className="align-middle">E</span>
                                    <span className="align-middle">T</span>
                                    <span className="align-middle">T</span>
                                    <span className="align-middle">R</span>
                                    <span className="align-middle">E</span>
                                </button>    
                            </form>
                    </div>
                        <div className="grid content-center md:px-10 pt-2 w-full">
                            <div className={`grid gap-1 h-full grid-cols-pannel`}>
                                {allChamp.map(champ=>{
                                        return <ProfileChampion key={champ.id} profileChampion={champ} gameIsLose={isGameLose}/>
                                    })}
                            </div>
                        </div>
                        <div className='px-1 grid-span-1'>
                            <div className='flex justify-items-center justify-center p-1'>
                                <p className='text-white text-sm tracking-wider font-text border p-1 rounded-xl uppercase  md:font-bold md:text-xl lg:text-2xl xl:text-3xl'>Historique </p>
                            </div>
                            <div className='flex flex-col-reverse justify-center items-center h-auto'>
                            {histAnswer.map((hist, index)=>{
                                            return <HistoriqueChampion key={index} histChamp={hist}/>
                                        })} 
                            </div>
                        </div>  
                </div>
                :
                <div className=''>
                    <div className='min-w-0'>
                        <section className='flex flex-col'>
                            <p className={`flex justify-center mb-2 text-xl font-title ${state.themes[state.theme].textColor} uppercase tracking-wider md:text-4xl lg:text-6xl xl:text-7xl`}>Résultat de la partie</p>
                            <div className='px-16 w-full'>
                                <hr className='mb-2' style={hrColor}></hr>
                            </div>
                            <div className="grid grid-rows-1 grid-cols-2 gap-1 uppercase place-items-center">
                                <div className={`w-3/5 flex flex-col justify-center shadow-2xl border-t border-l border-blue-200 ${state.themes[state.theme].shadow__boxStats} rounded-lg text-white px-6 bg-gradient-to-r ${state.themes[state.theme].background_pannel__stat__gradiant} background-animate`}>
                                    <p className={`flex justify-center w-full text-xxs font-spegiel ${state.themes[state.theme].textColor} md:font-black md:text-base lg:text-xl xl:text-2xl`}>Ton score</p>
                                    <h2 className={`flex justify-center w-full text-base font-texxt ${state.themes[state.theme].textColor} font-bold md:font-black md:text-xl lg:text-2xl xl:text-3xl`}>{countGoodAnswer}/{allChamp.length}</h2>
                                </div>
                                <div className={`w-3/5 flex flex-col shadow-2xl border-r border-b border-blue-200 ${state.themes[state.theme].shadow__boxStats} rounded-lg text-white px-6 bg-gradient-to-r ${state.themes[state.theme].background_pannel__stat__gradiant} background-animate`}>
                                    <p className={`flex justify-center w-full text-xxs font-spegiel ${state.themes[state.theme].textColor} md:font-black md:text-base lg:text-xl xl:text-2xl`}>Taux de réponses</p>
                                    <h2 className={`flex justify-center w-full text-base font-texxt ${state.themes[state.theme].textColor} font-bold md:font-black md:text-xl lg:text-2xl xl:text-3xl`}>{Math.round((countGoodAnswer/allChamp.length*100) * 10) / 10} %</h2>
                                </div>
                            </div>
                        </section>
                    </div>
                    {difficulty==="trialTimer" && 
                        <div className='mt-6 flex justify-center'>
                            <div className={`w-2/5 font-extrabold p-2 shadow-2xl border-b border-blue-200 ${state.themes[state.theme].shadow__boxStats} rounded-lg bg-gradient-to-l ${state.themes[state.theme].background_pannel__stat__gradiant} background-animate`}>
                                <p className={`flex justify-center pb-1 text-sm font-spegiel ${state.themes[state.theme].textColor} uppercase md:font-black md:text-base lg:text-xl xl:text-2xl`}>Ton temps</p>
                                <p className={`flex justify-center w-full text-sm font-texxt ${state.themes[state.theme].textColor} md:font-black md:text-base lg:text-xl xl:text-2xl`}>{timeTrialMin > 0 && <span>{timeTrialMin}m</span>} {timeTrial.toFixed(1)}</p>    
                            </div>
                        </div>
                    }
                    <div className='grid grid-rows-1 grid-cols-2 mt-5'>
                        <button
                                    className={`button button--nina px-5 py-0 mb-4 place-self-center font-spegiel-bold ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} ${state.themes[state.theme].hover__backgroundButton_pannel}  text-white relative block focus:outline-none rounded text-xxs text-center font-spegiel-bold font-bold uppercase tracking-widest overflow-hidden w-3/5 md:text-sm lg:text-base xl:text-lg`}
                                    data-text="rejouer"
                                    onClick={restartGame}
                                >
                                <span className="align-middle">R</span>
                                <span className="align-middle">E</span>
                                <span className="align-middle">J</span>
                                <span className="align-middle">O</span>
                                <span className="align-middle">U</span>
                                <span className="align-middle">E</span>
                                <span className="align-middle">R</span>        
                        </button>
                        <Link to="/" className='grid place-self-center w-full'>
                            <button
                                    className={`button button--nina px-5 py-0 mb-4 place-self-center ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} ${state.themes[state.theme].hover__backgroundButton_pannel} text-white relative block focus:outline-none rounded text-xxs text-center font-spegiel-bold font-bold uppercase tracking-widest overflow-hidden w-3/5 md:text-sm lg:text-base xl:text-lg`}
                                    data-text="Retour au Menu"
                            >
                                <span className="align-middle">R</span>
                                <span className="align-middle">E</span>
                                <span className="align-middle">T</span>
                                <span className="align-middle">O</span>
                                <span className="align-middle">U</span>
                                <span className="align-middle">R</span>
                                <span className="align-middle">&nbsp;</span>    
                                <span className="align-middle">A</span>    
                                <span className="align-middle">U</span>    
                                <span className="align-middle">&nbsp;</span>    
                                <span className="align-middle">M</span>    
                                <span className="align-middle">E</span>    
                                <span className="align-middle">N</span>    
                                <span className="align-middle">U</span>    
                            </button>
                        </Link>
                    </div>
                {difficulty==="trialTimer" && 
                    <div>
                        <p className={`flex justify-center text-xl h-6/12 font-title ${state.themes[state.theme].textColorTitle} uppercase tracking-wider sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl`}>Tu peux partager ton résultat sur Twitter !</p>
                        <div className='flex justify-center'>
                            <TwitterShareButton
                                    className='place-self-center'
                                        url={'https://quizzlol.fr'}
                                        title={`Je viens de trouver tous les champions avec un temps de ${timeTrialMin > 0 && <span>{timeTrialMin}m</span>} ${timeTrial.toFixed(1)}} !
                                        `}
                                        hashtags={['quizz', 'quizzlol', 'lol', 'leagueOfLegends']}
                                    >
                                <button
                                    className={`flex items-center button button--nina px-5 py-0 mb-4 place-self-center font-spegiel-bold ${state.themes[state.theme].returnToMenuOrRestart__backgroundButton} ${state.themes[state.theme].hover__backgroundButton_pannel}  text-white relative block focus:outline-none rounded text-xxs text-center font-spegiel-bold font-bold uppercase tracking-widest overflow-hidden w-full md:text-sm lg:text-base xl:text-lg`}
                                 >Twitter
                                <TwitterIcon size={45} round />
                                </button>                            
                            </TwitterShareButton>
                        </div>
                    </div>
                }
                <div className='grid'>
                    <div className={`container px-2 py-2 place-self-center w-full md:w-11/12 lg:w-10/12 xl:w-9/12 h-full bg-gradient-to-r ${state.themes[state.theme].background_pannel_gradiant} background-animate`}>   
                        <RecapPartiePannel allChamp={allChamp} countGoodAnswer={countGoodAnswer} isGameLose={isGameLose} restartGame={()=>restartGame()}/>
                    </div>
                </div>
            </div>
            }
        </div>
    );
}