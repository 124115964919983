import React from 'react';
import ProfileChampion from './ProfileChampion';

export default function RecapPartiePannel(props) {

    return(
        <div className='grid grid-cols-recap'>
                        {props.allChamp.map(champ=>{
                            return <ProfileChampion key={champ.id} profileChampion={champ} gameIsLose={props.isGameLose}/>
                        })}
        </div>
    )
}