import React, {useEffect, useState, useContext} from 'react';
import { ThemeContext } from '../index.js'
import Popup from './Popup';
import Question from './Question.jsx';
import RecapPartie from './RecapPartie.jsx';
import Swal from "sweetalert2";
import Constante from "../assets/const/fr/Constante.json"
import QuestionService from "../service/QuestionService";
import tfSpellZ from "../assets/img/champion/spell/PickACard.png";
import settSpellQ from "../assets/img/champion/spell/SettQ.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Storage from "../service/Storage";
import jhinLoading from '../assets/img/champion/profil/Jhin_5.jpg';
import jhinLoadingKc from '../assets/img/champion/profil/Jhin_23.jpg';
import aatroxLoadingKc from '../assets/img/champion/profil/Aatrox_1.jpg';
import aatroxLoading from '../assets/img/champion/profil/Aatrox_11.jpg';
import bookRules from '../assets/img/runes/Inspiration/UnsealedSpellbook/UnsealedSpellbook.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from "react-router-dom";



export default function Daily() {

    //Gérer le theme
    const [state, setState] = useContext(ThemeContext);

    const navigate = useNavigate();

    const QUOTIDIEN = 'quotidien';

    const [counter, setCounter] = useState(1);
    const [gameMode, setGameMode] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [finalResponse, setFinalResponse] = useState([]);
    const [questionArray, setQuestionArray] = useState([]);
    const [isGameEnd, setIsGameEnd] = useState(false);
    const [isGameStarted, setIsGameStarted] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [showPopupRulesDaily, setShowPopupRulessDaily] = useState(false);
    const [showPopupRulesMode, setShowPopupRulesMode] = useState(false);

    const [championName, setChampionName] = useState([]);

    useEffect(() => {
        if (Storage.getFinalResponse() !== null) {
            if (Storage.getFinalResponse().length === 15) {
                setIsDisabled(true);
            }
        }
        return () => {
            // code à exécuter au moment où le composant est démonté (c'est-à-dire lorsqu'il est retiré du DOM)
            if (Storage.getFinalResponse() === null) {
                if (Storage.initDailyExists() && (finalResponse.length === 0 && !isDisabled)) {
                    Storage.setFinalResponse(finalResponse);
                }
            }
        };
    }, []);

    useEffect(() => {
        setChampionName(Storage.getChamps());
       }, []);

    //Affiche ou non la pop-up pour les règles du mode zilean
    const togglePopupRulesDaily = () => {
        setShowPopupRulessDaily(!showPopupRulesDaily);
      }

    //Affiche ou non la pop-up pour les règles du mode zilean
    const togglePopupRulesMode = () => {
        setShowPopupRulesMode(!showPopupRulesMode);
      }

    const helpDifficult = () => {
        Swal.fire({
            title: '',
            text: Constante.INFORMATION_DIFFICULTE,
            icon: 'info',
            timer: null,
            showCloseButton: true
        })
    };

    const errorPopup = () => {
        Swal.fire({
            title: 'Erreur lors du lancement du jeu',
            text: Constante.ERROR_DAILY,
            icon: 'error',
            timer: null,
            showCloseButton: true
        })
    }

    const gameFinished = () => {
        Swal.fire({
            title: Constante.PARTIE_TERMINEE,
            text: Constante.VOUS_ALLEZ_ETRE_REDIRIGE_VERS_LE_RECAP,
            icon: 'info',
            timer: 1500,
        })
    }
   
    function handleCount() {
        setCounter(counter + 1);
        if (gameMode === QUOTIDIEN) {
            Storage.setFinalResponse(finalResponse);
        }
        // Permet de mettre fin à la partie
        if (gameMode === QUOTIDIEN && counter === 15) {
            gameFinished();
            setIsGameEnd(true);
        }else if (gameMode != QUOTIDIEN && counter === 10) {
            gameFinished();
            setIsGameEnd(true);
        }
    }

    async function launchGame(difficulty) {
        try {
            setIsFetching(true);
            // Récupération des questions en fonction de la difficulté choisi
            let questions = [];
            const selectedDifficulty = difficulty;
            const iteration = selectedDifficulty === QUOTIDIEN ? 15 : 10;
            let itemQuestionPresent = false;
            for (let i = 0; i < iteration; i++) {
                switch (selectedDifficulty) {
                    case "facile":
                        // Dans le cas de la difficulté facile, on récupère les questions de la catégorie "facile" et 2 questions de la catégorie "moyen"
                        if (i > 7) {
                            difficulty = "moyen";
                        }
                        break;
                    case "moyen":
                        // Dans le cas de la difficulté moyen, on récupère les questions de la catégorie "moyen" et 2 questions de la catégorie "difficile"
                        if (i > 7) {
                            difficulty = "difficile";
                        }
                        break;
                    case "difficile":
                        break;
                    case QUOTIDIEN:
                        setGameMode('quotidien');
                        difficulty = "facile";
                        if (i > 9) {
                            difficulty = "difficile";
                        } else if (i > 5 && i < 9) {
                            difficulty = "moyen"
                        }
                        break;
                    default:
                        difficulty = "facile";
                        break;
                }
                const question = await QuestionService.getRandomQuestionWithDifficulty(difficulty);
                if(question.question.includes("Combien de gold vaut la somme de ces items ?") && !itemQuestionPresent){
                    questions = [...questions, question];
                    itemQuestionPresent = true;
                }else if(question.question.includes("Combien de gold vaut la somme de ces items ?") && itemQuestionPresent){
                    i--;
                }else{
                    questions = [...questions, question];
                }
            }
            // On shuffle les questions
            questions.sort(() => Math.random() - 0.5)

            if (selectedDifficulty === QUOTIDIEN && !Storage.initDailyExists()) {
                // Si c'est le quotidien alors on doit initialiser le stockage en localStorage
                Storage.initDaily(questions);
                setQuestionArray(questions);
            } else if (selectedDifficulty === QUOTIDIEN) {
                // Attribution de l'existant en localStorage
                questions = Storage.getDaily();
                const finalResponseFromStorage = Storage.getFinalResponse();
                setFinalResponse(finalResponseFromStorage);
                // On modifie le counter pour qu'il démarre à l'endroit où la dernière réponse a été donnée
                const filteredQuestions = [];
                const skipedQuestions = [];
                let counterSkipedQuestions = 1;
                questions.forEach(question => {
                    let questionAlreadyAsked = false;
                    // Vérifiez si tous les éléments de question.propositions existent dans finalResponse.propositions
                    finalResponseFromStorage.forEach(response => {
                        if (question.propositions.every(element => response.propositions.includes(element))) {
                            questionAlreadyAsked = true;
                        }
                    });
                    if (questionAlreadyAsked) {
                        skipedQuestions.push(question);
                        counterSkipedQuestions++;
                    } else {
                        filteredQuestions.push(question);
                    }
                });
                const mergedArrayQuestions = skipedQuestions.concat(filteredQuestions);
                setQuestionArray(mergedArrayQuestions);
                if (filteredQuestions.length > 0) {
                    setCounter(counterSkipedQuestions);
                }
            } else {
                setQuestionArray(questions);
            }
        } catch (error) {
            console.error(error);
            errorPopup();
            navigate('/');
        } finally {
            setIsGameStarted(true);
            setIsFetching(false);
        }
    }

    function getStatistiques() {

        const nbCorrectAnswer = finalResponse
            .filter(element => element.isCorrect)
            .map(_ => _).length;

        // Groupement du type de question
        const percentResult = finalResponse.reduce( (groups, item) => {
            const group = (groups[item.question] || []);
            group.push(item);
            groups[item.question] = group;
            return groups;
        }, {});

        // On boucle pour chaque type de question et retourne le type de question + pourcentage de bonnes réponses
        const percentValues = Object.entries(percentResult).map( ([key, value]) => {
            return {
                question: key,
                value: Math.round( ( value.filter(e => e.isCorrect).length / value.length ) * 100 )
            };
        });

        return {
            nbCorrectAnswer: nbCorrectAnswer,
            percentValues: percentValues
        }
    }

    // Mise à jour du state finalResponse
    if (Storage.initDailyExists() && (finalResponse.length > 0 && gameMode === QUOTIDIEN)) {
        Storage.setFinalResponse(finalResponse);
    }

    //On gère le style des balise <hr>
    const hrColor = {
        "display": "block", "height": "1px",
        "border": 0,
        "borderTop": state.themes[state.theme].borderTop,
        "margin": "1em 0",
    }

    //Pour la couleur des bouttons (thème)
    let styleBackground;
    if (state.theme === "kcorp") {
        styleBackground = {
            "backgroundImage": state.themes[state.theme].backgroundButton,
            "background-size": "contain"
        }
    } else{
        styleBackground = {
            "backgroundImage": "none"
        }
    }

    return (
        <div className='py-4'>
            {
                isFetching ?
                    <span className="loader">{Constante.Loading}</span>
                    :
                    isGameStarted ?
                        isGameEnd ?
                            <RecapPartie finalResponse={finalResponse}
                                         nbCorrectAnswer={getStatistiques().nbCorrectAnswer}
                                         getStatistiques={getStatistiques()}/>
                        :
                            <Question handleCount={() => handleCount()} count={counter}
                                      setFinalResponse={setFinalResponse} question={questionArray[counter-1]} championName={championName}/>
                    :
                    <div>
                        <p className={`flex justify-center mb-2 text-xl font-title ${state.themes[state.theme].textColorTitle} uppercase tracking-wider md:text-4xl`}>Le QUIZZ</p>
                        <div className='px-16'>
                            <hr className='mb-2' style={hrColor}></hr>
                        </div>  
                    <div className='grid grid-rows-2 grid-cols-1 gap-8 pb-16'>
                        <div className='grid place-self-center w-2/4 h-full transform transition duration-500 hover:scale-105'>
                            <div className="flex flex-col justify-around justify-items-center items-center rounded-xl bg-black absolute inset-0 opacity-0 hover:opacity-100 bg-opacity-90 duration-300">
                                <div className='flex flex-col items-center w-full px-4'>
                                    <LazyLoadImage className='w-1/5' src={bookRules}></LazyLoadImage>
                                    <div className='flex w-full rounded bg-red-400' style={styleBackground}>
                                        <button
                                            className={`button button--nina animate__animated animate__fadeIn
                                            py-0 px-2 w-full relative block rounded text-center text-sm uppercase focus:outline-none
                                            tracking-widest overflow-hidden ${state.themes[state.theme].hover__return__backgroundButton} text-white font-bold font-spegiel-bold md:text-sm lg:text-base xl:text-xl`}
                                            data-text="Règles du jeu"
                                            onClick={togglePopupRulesDaily}>

                                            <span className="align-middle">R</span>
                                            <span className="align-middle">E</span>
                                            <span className="align-middle">G</span>
                                            <span className="align-middle">L</span>
                                            <span className="align-middle">E</span>
                                            <span className="align-middle">S</span>
                                            <span className="align-middle">&nbsp;</span>
                                            <span className="align-middle">D</span>
                                            <span className="align-middle">U</span>
                                            <span className="align-middle">&nbsp;</span>
                                            <span className="align-middle">J</span>
                                            <span className="align-middle">E</span>
                                            <span className="align-middle">U</span>
                                        </button>
                                    </div>
                                </div>
                                { isDisabled ?
                                                <p className='place-self-center font-spegiel pb-2 text-white'>
                                                    Déjà réalisé pour aujourd'hui
                                                </p>
                                                :
                                                null
                                            }
                                { !isDisabled &&
                                    <div className='px-2'>

                                            <div className='flex w-full rounded bg-red-400' style={styleBackground}>
                                                    <button
                                                        className={`button px-5 py-2 w-full text-xxs text-center text-white font-spegiel-bold ${isDisabled ? '' : 'hover:text-white'} uppercase rounded relative block ${state.themes[state.theme].hover__return__backgroundButton} ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''} md:text-sm lg:text-base xl:text-xl`}
                                                        data-text="Commencer"
                                                        value={QUOTIDIEN} disabled={isDisabled} onClick={(e) => launchGame(e.target.value)}
                                                        >Commencer
                                                    </button>
                                            </div>
                                    </div>
                                }
                            </div>
                            <div className='grid-rows-3 w-full'>
                                    <div className='flex flex-col w-full'>
                                        <h2 className={`flex justify-center text-lg font-card-title ${state.themes[state.theme].textColor} uppercase tracking-wider md:text-sm lg:text-base xl:text-xl`}>Quizz du Jour</h2>
                                        <div className='w-full flex justify-center'>
                                            <img alt="settQ" className='place-self-center rounded-lg' src={settSpellQ}></img>
                                        </div>
                                    </div>
                                    <div className='px-6'>
                                        <hr className='' style={hrColor}></hr>
                                    </div>
                                </div>
                                <LazyLoadImage className={`place-self-center h-full w-full rounded-2xl ${state.themes[state.theme].shadowColor}`} src={state.theme === "kcorp" ? jhinLoadingKc : jhinLoading }></LazyLoadImage>
                        </div>
                        <div className='grid place-self-center w-2/4 h-full transform transition duration-500 hover:scale-105'>
                            <div className="flex flex-col justify-around justify-items-center items-center rounded-xl bg-black absolute inset-0 opacity-0 hover:opacity-100 bg-opacity-90 duration-300">
                                <div className='flex flex-col items-center w-full px-4'>
                                    <LazyLoadImage className='w-1/5' src={bookRules}></LazyLoadImage>
                                    <div className='flex w-full rounded bg-red-400' style={styleBackground}>
                                        <button
                                            className={`button button--nina animate__animated animate__fadeIn
                                            py-0 px-2 w-full relative block rounded text-center text-sm uppercase focus:outline-none
                                            tracking-widest overflow-hidden ${state.themes[state.theme].hover__return__backgroundButton} text-white font-bold font-spegiel-bold md:text-sm lg:text-base xl:text-xl`}
                                            data-text="Règles du jeu"
                                            onClick={togglePopupRulesMode}>
                                                
                                            <span className="align-middle">R</span>
                                            <span className="align-middle">E</span>
                                            <span className="align-middle">G</span>
                                            <span className="align-middle">L</span>
                                            <span className="align-middle">E</span>
                                            <span className="align-middle">S</span>
                                            <span className="align-middle">&nbsp;</span>
                                            <span className="align-middle">D</span>
                                            <span className="align-middle">U</span>
                                            <span className="align-middle">&nbsp;</span>
                                            <span className="align-middle">J</span>
                                            <span className="align-middle">E</span>
                                            <span className="align-middle">U</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='p-2'>
                                    <div className='flex gap-3 md:gap-6 w-full'>
                                        <div className='rounded bg-red-400' style={styleBackground}>
                                            <button className={`button px-5 py-2 w-full text-xxs text-center text-white font-spegiel-bold uppercase rounded relative block ${state.themes[state.theme].hover__return__backgroundButton} md:text-sm lg:text-base xl:text-xl`} onClick={(e) => launchGame(e.target.value)} value='facile'>Facile</button>
                                        </div>
                                        <div className='rounded bg-red-400' style={styleBackground}>
                                            <button className={`button px-5 py-2 w-full text-xxs  text-center text-white font-spegiel-bold uppercase rounded relative block ${state.themes[state.theme].hover__return__backgroundButton} md:text-sm lg:text-base xl:text-xl`} onClick={(e) => launchGame(e.target.value)} value='moyen'>Moyen</button>
                                        </div>
                                        <div className='rounded bg-red-400' style={styleBackground}>
                                            <button className={`button px-5 py-2 w-full text-xxs  text-center text-white font-spegiel-bold uppercase rounded relative block ${state.themes[state.theme].hover__return__backgroundButton} md:text-sm lg:text-base xl:text-xl`} onClick={(e) => launchGame(e.target.value)} value='difficile'>Difficile</button>
                                        </div>
                                    </div>
                                    </div>
                            </div>
                            <div className='grid-rows-3 w-full'>
                                    <div className='flex flex-col w-full'>
                                        <h2 className={`flex justify-center text-lg font-card-title ${state.themes[state.theme].textColor} uppercase tracking-wider md:text-sm lg:text-base xl:text-xl`}>Quizz</h2>
                                        <div className='w-full flex justify-center'>
                                            <img alt="tfZ" className='place-self-center rounded-lg' src={tfSpellZ}></img>
                                        </div>
                                    </div>
                                    <div className='px-6'>
                                        <hr className='' style={hrColor}></hr>
                                    </div>
                                </div>
                                <LazyLoadImage className={`place-self-center h-full w-full rounded-2xl ${state.themes[state.theme].shadowColor}`} src={state.theme === "kcorp" ? aatroxLoadingKc : aatroxLoading }></LazyLoadImage>
                        </div>
                        {showPopupRulesDaily && <Popup
                                        content={<>
                                                <p className={`flex justify-center text-xl font-title text-white uppercase md:text-xl lg:text-2xl xl:text-4xl`}>Règles du jeu</p>
                                                <dl className={`w-full divide-y divide-gray-200 dark:text-white dark:divide-gray-400`}>
                                                    <div className="flex flex-col pb-3">
                                                        <dt className={`mb-1 text-base font-card-title text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl t`}>Les questions</dt>
                                                        <div className='flex items-center'>
                                                            <dd className={`jusitfy-center mr-6 text-white font-spegiel-bold text-xl font-semibold md:text-base lg:text-xl xl:text-2xl`}>Tu vas devoir répondre à une série de questions</dd>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col py-3">
                                                        <dt className={`mb-1 text-base font-card-title md:text-lg text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl`}>Comment gagner</dt>
                                                        <div className='flex items-center'>
                                                            <dd className={`jusitfy-center mr-6 font-spegiel-bold text-white text-xl font-semibold md:text-base lg:text-xl xl:text-2xl`}>Ton but est de répondre correctement à un maximum de questions</dd>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col pt-3 pb-5">
                                                        <dt className={`mb-1 text-base font-card-title text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl`}>Partage</dt>
                                                        <div className='flex items-center'>
                                                            <dd className={`text-xl font-spegiel-bold text-white mr-2 md:text-base lg:text-xl xl:text-2xl`}>À la fin, tu pourras partager ton résultat avec tes amis !</dd>
                                                        </div>
                                                    </div>
                                                </dl> 
                                        </>}
                
                                     
                                        handleClose={togglePopupRulesDaily}
                                    />}
                                    {showPopupRulesMode && <Popup
                                        content={<>
                                                 <p className={`flex justify-center text-xl font-title text-white uppercase md:text-xl lg:text-2xl xl:text-4xl`}>Règles du jeu</p>
                                                <dl className={`w-full divide-y divide-gray-200 dark:text-white dark:divide-gray-400`}>
                                                    <div className="flex flex-col pb-3">
                                                        <dt className={`mb-1 text-base font-card-title text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl t`}>Les questions</dt>
                                                        <div className='flex items-center'>
                                                            <dd className={`jusitfy-center mr-6 text-white font-spegiel-bold text-xl font-semibold md:text-base lg:text-xl xl:text-2xl`}>Tu vas devoir répondre à une série de 10 questions</dd>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col py-3">
                                                        <dt className={`mb-1 text-base font-card-title md:text-lg text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl`}>Comment gagner</dt>
                                                        <div className='flex items-center'>
                                                            <dd className={`jusitfy-center mr-6 font-spegiel-bold text-white text-xl font-semibold md:text-base lg:text-xl xl:text-2xl`}>Ton but est de répondre correctement à un maximum de questions</dd>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col pt-3">
                                                        <dt className={`mb-1 text-base font-card-title md:text-lg text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl`}>Difficulté</dt>
                                                        <div className='flex items-center'>
                                                            <dd className={`jusitfy-center font-spegiel-bold text-white text-xl md:text-base lg:text-xl xl:text-2xl`}>C'est toi qui choisis la difficulté ! <FontAwesomeIcon className='cursor-pointer hover:animate-spin' icon={faQuestionCircle} onClick={helpDifficult}></FontAwesomeIcon></dd>
                                                        </div>        
                                                    </div>
                                                    <div className="flex flex-col pt-3 pb-5">
                                                        <dt className={`mb-1 text-base font-card-title text-gray-300 dark:text-gray-300 md:text-base lg:text-xl xl:text-2xl`}>Partage</dt>
                                                        <div className='flex items-center'>
                                                            <dd className={`text-xl font-spegiel-bold text-white mr-2 md:text-base lg:text-xl xl:text-2xl`}>À la fin, tu pourras partager ton résultat avec tes amis !</dd>
                                                        </div>
                                                    </div>
                                                </dl>
                                        </>}
                
                                     
                                        handleClose={togglePopupRulesMode}
                                    />}
                    </div>
                </div>                                
            }
        </div>
    );
}

