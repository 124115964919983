import React, { useContext } from 'react';
import { ThemeContext } from '../../index.js'

export default function HistoriqueChampion(props) {

    const [state, setState] = useContext(ThemeContext);

    let stylesName;
    if (props.histChamp.isFind) {
        if (state.theme === "kcorp") {
            stylesName = {
                backgroundColor: "#01D1FF",
                padding: "2px",
                "boxShadow": "3px 2px 2px #80ffdf",
                "color": "#1E293B",
                "textShadow": "2px 2px 3px #80ffdf",
            }
        }else{
            stylesName = {
                backgroundColor: "#66cc66",
                padding: "2px",
                "boxShadow": "3px 2px 2px #80ffdf",
                "color": "#1E293B",
                "textShadow": "2px 2px 3px #80ffdf",
            }
        
        };
    }else{
        stylesName = {
            backgroundColor: "#F87171",
            padding: "2px",
            "boxShadow": "3px 2px 2px #ff9999",
            "color": "#1E293B",
            "textShadow": "2px 1px 2px #ff9999",
        };
    }

    return(
        <div className='rounded-2xl grid grid-rows-1 grid-cols-3 border-white border mb-2 mt-2 w-6/12 md:w-4/12' style={stylesName}>
            <img alt="championImg" src={props.histChamp.imgLink} className="object-cover w-6/12 rounded-xl"/>
            <p className='font-extrabold oldstyle-nums italic tracking-wide font-sans text-base uppercase place-self-center col-span-2 sm:text-base md:text-xl lg:text-2xl xl:text-5xl'>{props.histChamp.answerPlayer}</p>
        </div>
    )
}