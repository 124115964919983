import React from 'react';
import {Link} from "react-router-dom";

export default function Gamemode() {
    return (
      <>
        <div className="col-span-12">
            <div className="py-3">
                <button className="border border-black w-full hover:bg-black hover:text-white font-bold py-2 px-4 rounded">
                    <Link to="/daily"> Trouve le lore </Link>
                </button>
            </div>
        </div>
      </>
    );
}