import React from 'react';


export default function HistoriquePlayer(props) {

    let stylesName;
    
    if (props.histPlayer.proPlayerModel.statusName) {
        stylesName = {
            background: "linear-gradient(90deg, rgba(82,233,70,1) 0%, rgba(69,252,148,0.8410714627647934) 50%, rgba(82,233,70,1) 100%)",
            padding: "2px",
        };
    }else{
        stylesName = {
            background: "linear-gradient(90deg, rgba(252,69,69,1) 0%, rgba(253,29,29,0.8662815467984069) 50%, rgba(252,69,69,1) 100%)",
            padding: "2px",
        };
    }

    let stylesRole;
    if (props.histPlayer.proPlayerModel.statusRole) {
        stylesRole = {
            background: "linear-gradient(90deg, rgba(82,233,70,1) 0%, rgba(69,252,148,0.8410714627647934) 50%, rgba(82,233,70,1) 100%)",
            padding: "2px",
        };
    }else{
        stylesRole = {
            background: "linear-gradient(90deg, rgba(252,69,69,1) 0%, rgba(253,29,29,0.8662815467984069) 50%, rgba(252,69,69,1) 100%)",
            padding: "2px",
        };
    }

    let stylesRegion;
    if (props.histPlayer.proPlayerModel.statusRegion) {
        stylesRegion = {
            background: "linear-gradient(90deg, rgba(82,233,70,1) 0%, rgba(69,252,148,0.8410714627647934) 50%, rgba(82,233,70,1) 100%)",
            padding: "2px",
        };
    }else{
        stylesRegion = {
            background: "linear-gradient(90deg, rgba(252,69,69,1) 0%, rgba(253,29,29,0.8662815467984069) 50%, rgba(252,69,69,1) 100%)",
            padding: "2px",
        };
    }

    let stylesNationality;
    if (props.histPlayer.proPlayerModel.statusNationality) {
        stylesNationality = {
            background: "linear-gradient(90deg, rgba(82,233,70,1) 0%, rgba(69,252,148,0.8410714627647934) 50%, rgba(82,233,70,1) 100%)",
            padding: "2px",
        };
    }else{
        stylesNationality = {
            background: "linear-gradient(90deg, rgba(252,69,69,1) 0%, rgba(253,29,29,0.8662815467984069) 50%, rgba(252,69,69,1) 100%)",
            padding: "2px",
        };
    }

    let stylesMainTrophy;
    if (props.histPlayer.proPlayerModel.statusMainTrophy) {
        stylesMainTrophy = {
            background: "linear-gradient(90deg, rgba(82,233,70,1) 0%, rgba(69,252,148,0.8410714627647934) 50%, rgba(82,233,70,1) 100%)",
            padding: "2px",
        };
    }else{
        stylesMainTrophy = {
            background: "linear-gradient(90deg, rgba(252,69,69,1) 0%, rgba(253,29,29,0.8662815467984069) 50%, rgba(252,69,69,1) 100%)",
            padding: "2px",
        };
    }

    let stylesMainChampion;
    if (props.histPlayer.proPlayerModel.statusMainChampion) {
        stylesMainChampion = {
            background: "linear-gradient(90deg, rgba(82,233,70,1) 0%, rgba(69,252,148,0.8410714627647934) 50%, rgba(82,233,70,1) 100%)",
            padding: "2px",
        };
    }else{
        stylesMainChampion = {
            background: "linear-gradient(90deg, rgba(252,69,69,1) 0%, rgba(253,29,29,0.8662815467984069) 50%, rgba(252,69,69,1) 100%)",
            padding: "2px",
        };
    }

    return(
        <div>
            {!props.isGameEnd ?
            <div className=''>
                {
                    props.histPlayer.isInsert ?
                <div className='grid grid-rows-1 grid-cols-6 content-center text-white font-semibold text-2xl place-self-center'>
                <div style={stylesName} className='border border-solid border-white grid'>
                    <p className='flex justify-center items-center place-self-center text-xs md:text-base lg:text-xl xl:text-2xl'><span className='items-center'>{props.histPlayer.proPlayerModel.name}</span><img alt="imageOfPlayer" src={props.histPlayer.proPlayerModel.img} className='w-2/12 h-full'></img></p>    
                </div>
                <div style={stylesRole} className='border border-solid border-white grid'>
                    <p className='place-self-center text-xs font-text md:text-base lg:text-xl xl:text-2xl'>{props.histPlayer.proPlayerModel.role}</p>
                </div>
                <div style={stylesRegion} className='border border-solid border-white grid'>
                    <p className='place-self-center text-xs font-text md:text-base lg:text-xl xl:text-2xl'>{props.histPlayer.proPlayerModel.region}</p>
                </div>
                <div style={stylesNationality} className='border border-solid border-white grid'>
                    <p className='place-self-center text-xs font-text md:text-base lg:text-xl xl:text-2xl'>{props.histPlayer.proPlayerModel.nationality}</p>
                </div>
                <div style={stylesMainTrophy} className='border border-solid border-white grid'>
                    <p className='place-self-center text-xs font-text md:text-base lg:text-xl xl:text-2xl'>{props.histPlayer.proPlayerModel.mainTrophy}</p>
                </div>
                <div style={stylesMainChampion} className='border border-solid border-white grid'>
                    <p className='place-self-center text-xs font-text md:text-base lg:text-xl xl:text-2xl'>{props.histPlayer.proPlayerModel.mainChampion}</p>
                </div>         
                </div>
                :
                <div >
                {props.histPlayer.html} 
                </div>      
            }
            </div>
        :
        <div>
            {
                props.histPlayer.isInsert ?
                <div className='grid grid-rows-1 grid-cols-6 content-center text-white font-semibold text-2xl place-self-center'>
                    <div style={stylesName} className='border border-solid border-black grid'>
                        <p className='place-self-center text-xs md:text-base lg:text-xl xl:text-2xl'></p>    
                    </div>
                    <div style={stylesRole} className='border border-solid border-black grid'>
                        <p className='place-self-center text-xs font-text md:text-base lg:text-xl xl:text-2xl'></p>
                    </div>
                    <div style={stylesRegion} className='border border-solid border-black grid'>
                        <p className='place-self-center text-xs font-text md:text-base lg:text-xl xl:text-2xl'></p>
                    </div>
                    <div style={stylesNationality} className='border border-solid border-black grid'>
                        <p className='place-self-center text-xs font-text md:text-base lg:text-xl xl:text-2xl'></p>
                    </div>
                    <div style={stylesMainTrophy} className='border border-solid border-black grid'>
                        <p className='place-self-center text-xs font-text md:text-base lg:text-xl xl:text-2xl'></p>
                    </div>
                    <div style={stylesMainChampion} className='border border-solid border-black grid'>
                        <p className='w-6 h-6 place-self-center text-xs font-text md:text-base lg:text-xl xl:text-2xl'></p>
                    </div>         
                </div>
                :
                <div>

                </div>
            }
            </div>

            }
        </div>
    )
}